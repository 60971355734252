import React from 'react'

import Input from './input'
import { ValidationError } from '../models'

interface PasswordInputProps {
  title: string
  name: string
  validationErrorName: string
  displayLabel: boolean
  value: string
  placeholder: string
  onChange: (value: string) => void
  numberic: boolean
  validationErrors: ValidationError[]
}

const PasswordInput = ({
  title, name, displayLabel, value, placeholder, validationErrors, onChange, numberic,
  validationErrorName
}: PasswordInputProps): JSX.Element => {
  const formatValue = (): string => {
    if (!numberic) {
      return value
    }

    const numericValue = parseInt(value)

    if (isNaN(numericValue)) {
      return value
    }

    return numericValue.toLocaleString('en')
  }

  const clearFormatting = (formatedValue: string): string => {
    if (!numberic) {
      return formatedValue
    }

    const value = parseInt(formatedValue.replaceAll(',', ''))

    if (isNaN(value)) {
      return ''
    } else {
      return value.toString()
    }
  }

  return (
    <Input
      type='password'
      title={title}
      validationErrorName={validationErrorName}
      name={name}
      displayLabel={displayLabel}
      value={formatValue()}
      placeholder={placeholder}
      validationErrors={validationErrors}
      onChange={(value) => onChange(clearFormatting(value))}
    />
  )
}
PasswordInput.defaultProps = {
  title: null,
  name: '',
  validationErrorName: null,
  displayLabel: true,
  placeholder: '',
  value: '',
  onChange: null,
  numberic: false,
  validationErrors: []
}

export default PasswordInput
