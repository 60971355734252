import React from 'react'

import { Input, RadioButton, ValidationMessages } from '../components'
import { PersonalPossessionAsset, ValidationError } from '../models'

interface PersonalPossessionProps {
  asset: PersonalPossessionAsset
  setPersonalPossessionAsset: (asset: PersonalPossessionAsset) => void
  validationErrors: ValidationError[]
}

const PersonalPossession = (
  { asset, setPersonalPossessionAsset, validationErrors }: PersonalPossessionProps
): JSX.Element => {
  return (
    <>
      <div className='sm:col-span-4'>
        <Input
          title='Description'
          name='personal-possession-description'
          validationErrorName='description'
          type='text'
          value={asset.description}
          onChange={(value) => setPersonalPossessionAsset({ ...asset, description: value })}
          validationErrors={validationErrors}
        />
      </div>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>Who is keeping this?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='personal-possession-kept-by-me'
            name='personal-possession-kept-by'
            title='Me'
            value='me'
            checked={asset.whoKeepingPersonalPossession === 'me'}
            onChange={(value) => setPersonalPossessionAsset(
              { ...asset, whoKeepingPersonalPossession: value }
            )}
          />
          <RadioButton
            id='personal-possession-kept-by-ex'
            name='personal-possession-kept-by'
            title='My Ex'
            value='ex'
            checked={asset.whoKeepingPersonalPossession === 'ex'}
            onChange={(value) => setPersonalPossessionAsset(
              { ...asset, whoKeepingPersonalPossession: value }
            )}
          />
        </div>
        <ValidationMessages
          name='whoKeepingPersonalPossession'
          validationErrors={validationErrors}
        />
      </fieldset>
    </>
  )
}

export default PersonalPossession
