import apolloClient from '../apollo-client'
import ValidationError from './validation-error'

import { consentOrderTemplateQuery, updateConsentOrderTemplateQuery } from '../queries'

interface ConsentOrderTemplate {
  id: string
  template: string
}

interface UpdateConsentOrderTemplateResult {
  consentOrder: ConsentOrderTemplate | null
  validationErrors: ValidationError[]
}

const updateConsentOrderTemplate = async (
  template: string
): Promise<UpdateConsentOrderTemplateResult> => {
  const { data } = await apolloClient.mutate({
    mutation: updateConsentOrderTemplateQuery,
    variables: {
      input: { template }
    }
  })

  return data.updateConsentOrderTemplate
}

const getConsentOrderTemplate = async (): Promise<ConsentOrderTemplate> => {
  const { data } = await apolloClient.query({
    query: consentOrderTemplateQuery
  })

  return data.consentOrderTemplate
}

export default ConsentOrderTemplate
export { updateConsentOrderTemplate, getConsentOrderTemplate }
