import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const addCommercialSuperannuationQuery = gql`
  mutation AddCommercialSuperannuationQuery($input: AddCommercialSuperannuationInput!) {
    addCommercialSuperannuation(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
      validationErrors {
        name
        messages
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default addCommercialSuperannuationQuery
