import { gql, MutationTuple, useMutation } from '@apollo/client'

import { ValidationError } from '../../models'

const REGISTER_USER_MUTATION = gql`
  mutation RegisterUserMutation($input: RegisterUserInput!) {
    registerUser(input:$input) {
      validationErrors {
        name
        messages
      }
    }
  }
`

interface RegisterUserData {
  registerUser: {
    validationErrors: ValidationError[]
  }
}

interface RegisterUserVariables {
  input: {
    email: string
    password: string
    passwordConfirmation: string
  }
}

const useRegisterUserMutation = (): MutationTuple<RegisterUserData, RegisterUserVariables> => {
  return useMutation(REGISTER_USER_MUTATION)
}

export default useRegisterUserMutation
