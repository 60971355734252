import React, { useState } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { useNavigate } from 'react-router-dom'

import { Button, RadioButton, SelectInput, TextInput } from '../components'
import { updatePersonalInformation } from '../models/consent-order'
import { useConsentOrder } from './consent-order-route'
import ValidationError from '../models/validation-error'
import extractAddress from '../extract-address'

interface PersonalInformation {
  email: string,

  firstName: string
  middleNames: string
  surname: string
  gender: string
  addressPlaceId: string
  addressStreet: string
  addressSuburb: string
  addressState: string
  addressCountry: string

  exFirstName: string
  exMiddleNames: string
  exSurname: string
  exGender: string
  exAddressPlaceId: string
  exAddressStreet: string
  exAddressSuburb: string
  exAddressState: string
  exAddressCountry: string

  relationshipType: string
}

const PersonalInformationRoute = (): JSX.Element => {
  const navigate = useNavigate()
  const { consentOrder, setConsentOrder } = useConsentOrder()
  const [saving, setSaving] = useState(false)
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const [personalInformation, setPersonalInformation] = useState<PersonalInformation>({
    email: consentOrder.email,
    firstName: consentOrder.firstName,
    middleNames: consentOrder.middleNames,
    surname: consentOrder.surname,
    gender: consentOrder.gender,
    addressPlaceId: consentOrder.addressPlaceId,
    addressStreet: consentOrder.addressStreet,
    addressSuburb: consentOrder.addressSuburb,
    addressState: consentOrder.addressState,
    addressCountry: consentOrder.addressCountry,

    exFirstName: consentOrder.exFirstName,
    exMiddleNames: consentOrder.exMiddleNames,
    exSurname: consentOrder.exSurname,
    exGender: consentOrder.exGender,
    exAddressPlaceId: consentOrder.exAddressPlaceId,
    exAddressStreet: consentOrder.exAddressStreet,
    exAddressSuburb: consentOrder.exAddressSuburb,
    exAddressState: consentOrder.exAddressState,
    exAddressCountry: consentOrder.exAddressCountry,

    relationshipType: consentOrder.relationshipType
  })

  const googlePlace = (): { label: string, value: { place_id: string } } => {
    if (personalInformation.addressPlaceId === '') {
      return {
        label: '',
        value: { place_id: '' }
      }
    }

    return {
      label: `${personalInformation.addressStreet}, ${personalInformation.addressSuburb} ` +
        `${personalInformation.addressState}, ${personalInformation.addressCountry}`,
      value: { place_id: personalInformation.addressPlaceId }
    }
  }

  const setGooglePlace = (
    place: {
      label: string
      value: { place_id: string, terms: Array<{ offset: number, value: string }>, types: Array<string> }
    }
  ): void => {
    setPersonalInformation({
      ...personalInformation,
      ...extractAddress(place)
    })
  }

  const exGooglePlace = (): { label: string, value: { place_id: string } } | null => {
    if (personalInformation.exAddressPlaceId === '') {
      return null
    }

    return {
      label: `${personalInformation.exAddressStreet}, ${personalInformation.exAddressSuburb} ` +
        `${personalInformation.exAddressState}, ${personalInformation.exAddressCountry}`,
      value: { place_id: personalInformation.exAddressPlaceId }
    }
  }

  const setExGooglePlace = (
    place: {
      label: string
      value: { place_id: string, terms: Array<{ offset: number, value: string }>, types: Array<string> }
    }
  ): void => {
    const address = extractAddress(place)

    setPersonalInformation({
      ...personalInformation,
      exAddressPlaceId: address.addressPlaceId,
      exAddressStreet: address.addressStreet,
      exAddressSuburb: address.addressSuburb,
      exAddressState: address.addressState,
      exAddressCountry: address.addressCountry
    })
  }

  const next = async (): Promise<void> => {
    setSaving(true)
    setValidationErrors([])

    const result = await updatePersonalInformation(
      consentOrder,
      personalInformation.email,
      personalInformation.firstName,
      personalInformation.middleNames,
      personalInformation.surname,
      personalInformation.gender,
      personalInformation.addressPlaceId,
      personalInformation.addressStreet,
      personalInformation.addressSuburb,
      personalInformation.addressState,
      personalInformation.addressCountry,
      personalInformation.exFirstName,
      personalInformation.exMiddleNames,
      personalInformation.exSurname,
      personalInformation.exGender,
      personalInformation.exAddressPlaceId,
      personalInformation.exAddressStreet,
      personalInformation.exAddressSuburb,
      personalInformation.exAddressState,
      personalInformation.exAddressCountry,
      personalInformation.relationshipType
    )

    setSaving(false)

    if (result.validationErrors.length === 0) {
      setConsentOrder(result.consentOrder)
      navigate(`/consent-orders/${consentOrder.slug}/assets`)
    } else {
      setValidationErrors(result.validationErrors)
    }
  }

  return (
    <div className='rounded-lg bg-white p-4'>
      <form className='space-y-8'>
        <div>
          <div>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              Personal Information
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Required personal information about you and your ex.
            </p>
          </div>
        </div>

        <hr />

        <div>
          <div>
            <p className='text-sm text-gray-900'>
              Email address where you want to receive your product:
            </p>
          </div>
          <div className='mt-4'>
            <TextInput
              title='Email'
              name='paymentEmail'
              displayLabel={false}
              value={personalInformation.email}
              validationErrors={validationErrors}
              onChange={(email) => setPersonalInformation(
                { ...personalInformation, email }
              )}
              disabled={consentOrder.purchased}
            />
          </div>
        </div>

        <fieldset className='mt-6'>
          <div>
            <p className='text-sm text-gray-900'>
              Is your relationship the end of a marriage or de facto relationship?
            </p>
          </div>
          <div className='mt-4 flex space-x-2'>
            <RadioButton
              id='marriage'
              name='relationship'
              title='Marriage'
              value='marriage'
              checked={personalInformation.relationshipType === 'marriage'}
              onChange={(value) => setPersonalInformation(
                { ...personalInformation, relationshipType: value }
              )}
              disabled={consentOrder.purchased}
            />
            <RadioButton
              id='marridefactoage'
              name='relationship'
              title='De facto'
              value='defacto'
              checked={personalInformation.relationshipType === 'defacto'}
              onChange={(value) => setPersonalInformation(
                { ...personalInformation, relationshipType: value }
              )}
              disabled={consentOrder.purchased}
            />
          </div>
          {validationErrors.some((error) => error.name.startsWith('relationshipType')) && (
            <p className='mt-2 text-sm text-red-600'>must be selected</p>
          )}
        </fieldset>

        <div>
          <h3 className='text-md leading-6 font-medium text-gray-900 mb-2'>Your Details</h3>
          <hr />
        </div>

        <div
          className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'
        >
          <div className='sm:col-span-6'>
            <TextInput
              title='First Name'
              name='firstName'
              value={personalInformation.firstName}
              validationErrors={validationErrors}
              onChange={(firstName) => setPersonalInformation(
                { ...personalInformation, firstName }
              )}
              disabled={consentOrder.purchased}
            />
          </div>

          <div className='sm:col-span-6'>
            <TextInput
              title='Middle Names'
              name='middleNames'
              validationErrors={validationErrors}
              value={personalInformation.middleNames}
              onChange={(middleNames) => setPersonalInformation(
                { ...personalInformation, middleNames }
              )}
              disabled={consentOrder.purchased}
            />
          </div>

          <div className='sm:col-span-6'>
            <TextInput
              title='Surname'
              name='surname'
              validationErrors={validationErrors}
              value={personalInformation.surname}
              onChange={(surname) => setPersonalInformation(
                { ...personalInformation, surname }
              )}
              disabled={consentOrder.purchased}
            />
          </div>

          <div className='sm:col-span-6'>
            <SelectInput
              title='Gender'
              name='gender'
              validationErrors={validationErrors}
              idProperty='name'
              displayProperty='name'
              options={[{ name: 'Female' }, { name: 'Male' }]}
              value={personalInformation.gender}
              onChange={(gender) => setPersonalInformation(
                { ...personalInformation, gender }
              )}
              disabled={consentOrder.purchased}
            />
          </div>

          <div className='sm:col-span-6'>
            <label className='block text-sm font-medium text-gray-700'>Address</label>
            {!consentOrder.purchased && (
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                apiOptions={{ language: 'en-AU', region: 'AU' }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['au'],
                  }
                }}
                selectProps={{
                  placeholder: 'Enter an address',
                  value: googlePlace(),
                  onChange: setGooglePlace,
                  classNamePrefix: 'react-select',
                  styles: {
                    control: (provided: any, state: any) => ({
                      ...provided,
                      '&:hover': {
                        borderColor: '#eaeaea'
                      },
                      borderColor: state.isFocused === true ? '#EA6C23' : '',
                      boxShadow: state.isFocused === true
                        ? '0 0 0 1px #EA6C23'
                        : '0 0 0 1px rgb(209, 213, 219)'
                    }),
                    input: (provided: any) => ({
                      ...provided,
                      '&:focus': {
                        boxShadow: '0 0 0 1px #FFFFFF'
                      }
                    })
                  }
                }}
              />
            )}
            {consentOrder.purchased && (
              <TextInput
                name='address'
                displayLabel={false}
                disabled
                value={googlePlace()?.label}
              />
            )}
            {validationErrors.some((error) => error.name.startsWith('address')) && (
              <p className='mt-2 text-sm text-red-600'>can't be blank</p>
            )}
          </div>
        </div>

        <div>
          <h3 className='text-md leading-6 font-medium text-gray-900 mb-2'>
            Your Ex's Details
          </h3>
          <hr />
        </div>

        <div
          className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'
        >
          <div className='sm:col-span-6'>
            <TextInput
              title='First Name'
              name='exFirstName'
              validationErrors={validationErrors}
              value={personalInformation.exFirstName}
              onChange={(exFirstName) => setPersonalInformation(
                { ...personalInformation, exFirstName }
              )}
              disabled={consentOrder.purchased}
            />
          </div>

          <div className='sm:col-span-6'>
            <TextInput
              title='Middle Name'
              name='exMiddleNames'
              validationErrors={validationErrors}
              value={personalInformation.exMiddleNames}
              onChange={(exMiddleNames) => setPersonalInformation(
                { ...personalInformation, exMiddleNames }
              )}
              disabled={consentOrder.purchased}
            />
          </div>

          <div className='sm:col-span-6'>
            <TextInput
              title='Surname'
              name='exSurname'
              validationErrors={validationErrors}
              value={personalInformation.exSurname}
              onChange={(exSurname) => setPersonalInformation(
                { ...personalInformation, exSurname }
              )}
              disabled={consentOrder.purchased}
            />
          </div>

          <div className='sm:col-span-6'>
            <SelectInput
              title='Gender'
              name='exGender'
              validationErrors={validationErrors}
              idProperty='name'
              displayProperty='name'
              options={[{ name: 'Female' }, { name: 'Male' }]}
              value={personalInformation.exGender}
              onChange={(exGender) => setPersonalInformation(
                { ...personalInformation, exGender }
              )}
              disabled={consentOrder.purchased}
            />
          </div>

          <div className='sm:col-span-6'>
            <label className='block text-sm font-medium text-gray-700'>Address</label>
            {!consentOrder.purchased && (
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                apiOptions={{ language: 'en-AU', region: 'AU' }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['au'],
                  }
                }}
                selectProps={{
                  placeholder: 'Enter an address',
                  value: exGooglePlace(),
                  onChange: setExGooglePlace,
                  classNamePrefix: 'react-select',
                  styles: {
                    control: (provided: any, state: any) => ({
                      ...provided,
                      '&:hover': {
                        borderColor: '#eaeaea'
                      },
                      borderColor: state.isFocused === true ? '#EA6C23' : '',
                      boxShadow: state.isFocused === true
                        ? '0 0 0 1px #EA6C23'
                        : '0 0 0 1px rgb(209, 213, 219)'
                    }),
                    input: (provided: any) => ({
                      ...provided,
                      '&:focus': {
                        boxShadow: '0 0 0 1px #FFFFFF'
                      }
                    })
                  }
                }}
              />
            )}
            {consentOrder.purchased && (
              <TextInput
                name='exAddress'
                displayLabel={false}
                disabled
                value={exGooglePlace()?.label}
              />
            )}
            {validationErrors.some((error) => error.name.startsWith('exAddress')) && (
              <p className='mt-2 text-sm text-red-600'>can't be blank</p>
            )}
          </div>
        </div>

        <div>
          <hr className='pt-5' />
          <div className='flex justify-end'>
            <Button
              loading={saving}
              onClick={next}
            >
              Next
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default PersonalInformationRoute
