import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const addCompanyAssetQuery = gql`
  mutation AddCompanyAssetQuery($input: AddCompanyAssetInput!) {
    addCompanyAsset(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
      validationErrors {
        name
        messages
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default addCompanyAssetQuery
