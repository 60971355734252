import React, { useState } from 'react'
import { useNavigate, Outlet } from 'react-router-dom'

import { Button, ConfirmationModal } from '../components'
import { useConsentOrder } from './consent-order-route'
import ConsentOrder, { deleteLiability, completedLiabilities } from '../models/consent-order'
import Liability from '../models/liability'

const LiabilitiesRoute = (): JSX.Element => {
  const navigate = useNavigate()
  const { consentOrder, setConsentOrder } = useConsentOrder()
  const [saving, setSaving] = useState(false)

  const next = async (): Promise<void> => {
    setSaving(true)

    const result = await completedLiabilities(consentOrder)
    setConsentOrder(result)

    setSaving(false)

    navigate(`/consent-orders/${consentOrder.slug}/payout`)
  }

  return (
    <>
      <div className='rounded-lg bg-white p-4'>
        <div className='space-y-8'>
          <div>
            <div>
              <h3 className='text-lg leading-6 font-medium text-gray-900'>Liabilities</h3>
              <p className='mt-1 text-sm text-gray-500'>
                Please add the details of all liabilities outstanding for both you and your ex.
              </p>
            </div>
          </div>
          <hr />

          <div className='space-y-2'>
            <div>
              <div className='flex justify-end'>
                {!consentOrder.purchased && (
                  <Button to='add'>
                    Add Liability
                  </Button>
                )}
              </div>
            </div>

            <div className='flex flex-col'>
              <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                  <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                    <table className='min-w-full divide-y divide-gray-200'>
                      <thead className='bg-gray-50'>
                        <tr>
                          <th
                            scope='col'
                            className='px-6 py-3 text-left text-xs font-medium text-gray-500 \
                                       uppercase tracking-wider'
                          >
                            Nickname
                          </th>
                          <th
                            scope='col'
                            className='px-6 py-3 text-left text-xs font-medium text-gray-500 \
                                       uppercase tracking-wider'
                          >
                            Type
                          </th>
                          <th scope='col' className='relative px-6 py-3'>
                            <span className='sr-only'>Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {consentOrder.liabilities.map((liability) => (
                          <LiabilityRow
                            key={liability.id}
                            liability={liability}
                            consentOrder={consentOrder}
                            liabilityDeleted={setConsentOrder}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <hr className='pt-5' />
            <div className='flex justify-between'>
              <Button to={`/consent-orders/${consentOrder.slug}/assets`}>
                Previous
              </Button>
              <Button loading={saving} onClick={next}>
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Outlet context={{ consentOrder, setConsentOrder }} />
    </>
  )
}

interface LiabilityRowProps {
  liability: Liability
  consentOrder: ConsentOrder
  liabilityDeleted: (consentOrder: ConsentOrder) => void
}

const LiabilityRow = (
  { liability, consentOrder, liabilityDeleted }: LiabilityRowProps
): JSX.Element => {
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const deleteAssetConfirmed = async (): Promise<void> => {
    setDeleting(true)
    const updatedConsentOrder = await deleteLiability(consentOrder, liability)

    setDeleting(false)
    setConfirmingDelete(false)

    liabilityDeleted(updatedConsentOrder)
  }

  return (
    <>
      <ConfirmationModal
        title='Confirm Delete'
        message={`Are you sure you want to delete the liability '${liability.name}?`}
        processing={deleting}
        open={confirmingDelete}
        onClose={() => setConfirmingDelete(false)}
        confirmAction={deleteAssetConfirmed}
        actionButtonTitle='Delete'
      />
      <tr className='bg-white'>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900'>
          {liability.name}
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900'>
          {liability.liabilityType}
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-4'>
          <Button
            type='link'
            to={`${liability.id}/edit`}
          >
            Edit
          </Button>
          <Button type='link' onClick={() => setConfirmingDelete(true)}>
            Remove
          </Button>
        </td>
      </tr>
    </>
  )
}

export default LiabilitiesRoute
