import React from 'react'

import { ValidationError } from '../models'

interface ValidationMessagesProps {
  name: string
  validationErrors: ValidationError[]
}

const ValidationMessages = ({ name, validationErrors }: ValidationMessagesProps): JSX.Element => {
  const validationError = validationErrors.find((v) => v.name === name)

  if (validationError == null) {
    return (<></>)
  }

  return (
    <p className='mt-2 text-sm text-red-600'>
      {validationError.messages.join(', ')}
    </p>
  )
}

export default ValidationMessages