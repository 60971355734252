import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const deletePayoutQuery = gql`
  mutation DeletePayoutQuery($input: DeletePayoutInput!) {
    deletePayout(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default deletePayoutQuery
