import React from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

import {
  RadioButton, Input, PercentageSplit, FundsDivided, CapitalGainsTax, TransferPeriod,
  ValidationMessages, TextInput
} from '../components'
import { RealEstateAsset, ValidationError } from '../models'
import extractAddress from '../extract-address'

interface RealEstateProps {
  asset: RealEstateAsset
  setRealEstateAsset: (asset: RealEstateAsset) => void
  validationErrors: ValidationError[]
}

const RealEstate = ({
  asset, setRealEstateAsset, validationErrors
}: RealEstateProps): JSX.Element => {
  const googlePlace = (): { label: string, value: { place_id: string } } => {
    if (asset.addressPlaceId === '') {
      return {
        label: '',
        value: { place_id: '' }
      }
    }

    return {
      label: `${asset.addressStreet}, ${asset.addressSuburb}
                          ${asset.addressState},
                          ${asset.addressCountry}`,
      value: { place_id: asset.addressPlaceId }
    }
  }

  const setGooglePlace = (
    place: {
      label: string
      value: { place_id: string, terms: Array<{ offset: number, value: string }>, types: Array<string> }
    }
  ): void => {
    setRealEstateAsset({
      ...asset,
      ...extractAddress(place)
    })
  }

  return (
    <>
      <div className='sm:col-span-4'>
        <label className='block text-sm font-medium text-gray-700'>Address</label>
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          apiOptions={{ language: 'en-AU', region: 'AU' }}
          autocompletionRequest={{
            componentRestrictions: {
              country: ['au'],
            }
          }}
          selectProps={{
            placeholder: 'Enter an address',
            value: googlePlace(),
            onChange: setGooglePlace,
            classNamePrefix: 'react-select',
            styles: {
              control: (provided: any, state: any) => ({
                ...provided,
                '&:hover': {
                  borderColor: '#eaeaea'
                },
                borderColor: state.isFocused === true ? '#EA6C23' : '',
                boxShadow: state.isFocused === true
                  ? '0 0 0 1px #EA6C23'
                  : '0 0 0 1px rgb(209, 213, 219)'
              }),
              input: (provided: any) => ({
                ...provided,
                '&:focus': {
                  boxShadow: '0 0 0 1px #FFFFFF'
                }
              })
            }
          }}
        />
        {validationErrors.some((error) => error.name.startsWith('address')) && (
          <p className='mt-2 text-sm text-red-600'>can't be blank</p>
        )}
      </div>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>
            Have you obtained a title search for this property?
          </p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='property-title-search-yes'
            name='property-title-search'
            title='Yes'
            value='yes'
            checked={asset.obtainedTitleSearch === 'yes'}
            onChange={(value) => setRealEstateAsset({ ...asset, obtainedTitleSearch: value })}
          />
          <RadioButton
            id='property-title-search-no'
            name='property-title-search'
            title='No'
            value='no'
            checked={asset.obtainedTitleSearch === 'no'}
            onChange={(value) => setRealEstateAsset({ ...asset, obtainedTitleSearch: value })}
          />
        </div>
        <ValidationMessages
          name='obtainedTitleSearch'
          validationErrors={validationErrors}
        />
      </fieldset>

      {asset.obtainedTitleSearch === 'no' && (
        <p className='font-medium text-sm'>
          You may continue completing this questionnaire however we recommend that
          you obtain a title search because you will need to provide a copy of the
          title search to the Family Court when submiting your application.
        </p>
      )}

      {asset.obtainedTitleSearch === 'yes' && (
        <>
          <div className='sm:col-span-4'>
            <Input
              title='What is the description of the land contained in the Certificate of Title?'
              name='property-land-description'
              type='text'
              placeholder='e.g. Lot 123 on Deposited Plan 456'
              value={asset.landDescriptionInCertificateOfTitle}
              onChange={(value) => setRealEstateAsset(
                { ...asset, landDescriptionInCertificateOfTitle: value }
              )}
              validationErrorName='landDescriptionInCertificateOfTitle'
              validationErrors={validationErrors}
            />
          </div>

          <div>
            <p className='text-sm text-gray-900'>
              What is the Volume number contained in the Certificate of Title?
            </p>
          </div>
          <div className='flex items-center grid grid-cols-2 sm:w-1/2'>
            <label className='pt-2 text-sm font-medium text-gray-700'>Volume</label>
            <div className='flex'>
              <TextInput
                displayLabel={false}
                name='property-volume-number'
                value={asset.certificateOfTitleVolumeNumber}
                placeholder='e.g. 456'
                onChange={(value) => setRealEstateAsset(
                  { ...asset, certificateOfTitleVolumeNumber: value }
                )}
                validationErrorName='certificateOfTitleVolumeNumber'
                validationErrors={validationErrors}
              />
            </div>
          </div>

          <div>
            <p className='text-sm text-gray-900'>
              What is the Folio number contained in the Certificate of Title?
            </p>
          </div>
          <div className='flex items-center grid grid-cols-2 sm:w-1/2'>
            <label className='pt-2 text-sm font-medium text-gray-700'>Folio</label>
            <div className='flex'>
              <TextInput
                displayLabel={false}
                name='property-folio-number'
                value={asset.certificateOfTitleFolioNumber}
                placeholder='e.g. 789'
                onChange={(value) => setRealEstateAsset(
                  { ...asset, certificateOfTitleFolioNumber: value }
                )}
                validationErrorName='certificateOfTitleFolioNumber'
                validationErrors={validationErrors}
              />
            </div>
          </div>
        </>
      )}

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>In whose name is this held?</p>
        </div>
        <div className='mt-2 md:mt-4 md:flex md:flex-wrap md:space-x-4'>
          <RadioButton
            id='property-held-me'
            name='property-held'
            title='Me'
            value='me'
            checked={asset.namePropertyHeldBy === 'me'}
            onChange={(value) => setRealEstateAsset({ ...asset, namePropertyHeldBy: value })}
          />
          <RadioButton
            id='property-held-ex'
            name='property-held'
            title='My Ex'
            value='ex'
            checked={asset.namePropertyHeldBy === 'ex'}
            onChange={(value) => setRealEstateAsset({ ...asset, namePropertyHeldBy: value })}
          />
          <RadioButton
            id='property-held-both'
            name='property-held'
            title='Both Me and My Ex'
            value='both'
            checked={asset.namePropertyHeldBy === 'both'}
            onChange={(value) => setRealEstateAsset({ ...asset, namePropertyHeldBy: value })}
          />
          <RadioButton
            id='property-held-company'
            name='property-held'
            title='Company'
            value='company'
            checked={asset.namePropertyHeldBy === 'company'}
            onChange={(value) => setRealEstateAsset({ ...asset, namePropertyHeldBy: value })}
          />
        </div>
        <ValidationMessages
          name='namePropertyHeldBy'
          validationErrors={validationErrors}
        />
      </fieldset>

      {asset.namePropertyHeldBy === 'company' && (
        <p className='font-medium text-sm'>
          Please go back and add this item as a company asset.
        </p>
      )}

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>Who is keeping it?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='keeping-property-me'
            name='keeping-property'
            title='Me'
            value='me'
            checked={asset.whoKeepingProperty === 'me'}
            onChange={(value) => setRealEstateAsset({ ...asset, whoKeepingProperty: value })}
          />
          <RadioButton
            id='keeping-property-ex'
            name='keeping-property'
            title='My Ex'
            value='ex'
            checked={asset.whoKeepingProperty === 'ex'}
            onChange={(value) => setRealEstateAsset({ ...asset, whoKeepingProperty: value })}
          />
          <RadioButton
            id='keeping-property-both'
            name='keeping-property'
            title='Sale'
            value='sale'
            checked={asset.whoKeepingProperty === 'sale'}
            onChange={(value) => setRealEstateAsset({ ...asset, whoKeepingProperty: value })}
          />
        </div>
        <ValidationMessages
          name='whoKeepingProperty'
          validationErrors={validationErrors}
        />
      </fieldset>

      {
        asset.namePropertyHeldBy !== asset.whoKeepingProperty &&
        asset.whoKeepingProperty !== 'sale' && asset.whoKeepingProperty !== '' &&
        (
          <TransferPeriod
            display
            name='property-transfer'
            title='What timeframe do you need to do the transfer?'
            transferPeriodQuantity={asset.transferPeriodQuantity}
            setTransferPeriodQuantity={(value) => setRealEstateAsset(
              { ...asset, transferPeriodQuantity: value }
            )}
            transferPeriod={asset.transferPeriod}
            setTransferPeriod={(value) => setRealEstateAsset({ ...asset, transferPeriod: value })}
            validationErrors={validationErrors}
          />
        )
      }

      {asset.namePropertyHeldBy !== asset.whoKeepingProperty && (
        <>
          <fieldset className='mt-6'>
            <div>
              <p className='text-sm text-gray-900'>What type of property?</p>
            </div>
            <div className='mt-4 flex flex-wrap space-x-4'>
              <RadioButton
                id='property-type-family-home'
                name='property-type'
                title='Family Home'
                value='family-home'
                checked={asset.propertyType === 'family-home'}
                onChange={(value) => setRealEstateAsset({ ...asset, propertyType: value })}
              />
              <RadioButton
                id='property-type-investment'
                name='property-type'
                title='Investment'
                value='investment'
                checked={asset.propertyType === 'investment'}
                onChange={(value) => setRealEstateAsset({ ...asset, propertyType: value })}
              />
            </div>
            <ValidationMessages
              name='propertyType'
              validationErrors={validationErrors}
            />
          </fieldset>

          {asset.propertyType === 'family-home' && (
            <fieldset className='mt-6'>
              <div>
                <p className='text-sm text-gray-900'>Who will live here pending settlement?</p>
              </div>
              <div className='mt-4 flex flex-wrap space-x-4'>
                <RadioButton
                  id='live-pending-settlement-me'
                  name='live-pending-settlement'
                  title='Me'
                  value='me'
                  checked={asset.residingPendingSettlement === 'me'}
                  onChange={(value) => setRealEstateAsset(
                    { ...asset, residingPendingSettlement: value }
                  )}
                />
                <RadioButton
                  id='live-pending-settlement-ex'
                  name='live-pending-settlement'
                  title='My Ex'
                  value='ex'
                  checked={asset.residingPendingSettlement === 'ex'}
                  onChange={(value) => setRealEstateAsset(
                    { ...asset, residingPendingSettlement: value }
                  )}
                />
              </div>
              <ValidationMessages
                name='residingPendingSettlement'
                validationErrors={validationErrors}
              />
            </fieldset>
          )}

          {asset.propertyType === 'investment' && (
            <>
              <fieldset className='mt-6'>
                <div>
                  <p className='text-sm text-gray-900'>
                    Who will receive the rental pending settlement?
                  </p>
                </div>
                <div className='mt-4 flex flex-wrap space-x-4'>
                  <RadioButton
                    id='receive-rental-me'
                    name='receive-rental'
                    title='Me'
                    value='me'
                    checked={asset.receivingRentalIncome === 'me'}
                    onChange={(value) => setRealEstateAsset(
                      { ...asset, receivingRentalIncome: value }
                    )}
                  />
                  <RadioButton
                    id='receive-rental-ex'
                    name='receive-rental'
                    title='My Ex'
                    value='ex'
                    checked={asset.receivingRentalIncome === 'ex'}
                    onChange={(value) => setRealEstateAsset(
                      { ...asset, receivingRentalIncome: value }
                    )}
                  />
                  <RadioButton
                    id='receive-rental-both'
                    name='receive-rental'
                    title='Both Me and My Ex'
                    value='both'
                    checked={asset.receivingRentalIncome === 'both'}
                    onChange={(value) => setRealEstateAsset(
                      { ...asset, receivingRentalIncome: value }
                    )}
                  />
                </div>
                <ValidationMessages
                  name='receivingRentalIncome'
                  validationErrors={validationErrors}
                />
              </fieldset>

              <PercentageSplit
                display={asset.receivingRentalIncome === 'both'}
                name='rental-income-split'
                value={asset.rentalIncomeSplit}
                setValue={(value) => setRealEstateAsset({ ...asset, rentalIncomeSplit: value })}
                validationName='rentalIncomeSplit'
                validationErrors={validationErrors}
              />
            </>
          )}
        </>
      )}

      {asset.whoKeepingProperty === 'sale' && (
        <>
          <FundsDivided
            display={asset.whoKeepingProperty === 'sale'}
            name='how-sale-proceeds-divided'
            title='How are the net sale proceeds being divided?'
            splitTypeValue={asset.saleProceeds}
            setSplitType={(value) => setRealEstateAsset({ ...asset, saleProceeds: value })}
            percentageSplitValue={asset.saleProceedsPercentageSplit}
            setPercentageSplitValue={(value) => setRealEstateAsset(
              { ...asset, saleProceedsPercentageSplit: value }
            )}
            fixedSumValue={asset.saleProceedsFixedSum}
            setFixedSumValue={(value) => setRealEstateAsset(
              { ...asset, saleProceedsFixedSum: value }
            )}
            splitTypeValidationName='saleProceeds'
            percentageSplitValidationName='saleProceedsPercentageSplit'
            fixedSumValidationName='saleProceedsFixedSum'
            validationErrors={validationErrors}
          />

          <CapitalGainsTax
            name='property-capital-gains-tax'
            title='Will there be any Capital Gains Tax?'
            willThereBeCapitalGains={asset.willThereBeCapitalGains}
            setWillThereBeCapitalGains={(value) => setRealEstateAsset(
              { ...asset, willThereBeCapitalGains: value }
            )}
            capitalGainsTaxResponsibility={asset.capitalGainsTaxResponsibility}
            setCapitalGainsTaxResponsibility={(value) => setRealEstateAsset(
              { ...asset, capitalGainsTaxResponsibility: value }
            )}
            percentageSplitValue={asset.capitalGainsTaxSplit}
            setPercentageSplitValue={(value) => setRealEstateAsset(
              { ...asset, capitalGainsTaxSplit: value }
            )}
            validationErrors={validationErrors}
          />

          <fieldset className='mt-6'>
            <div>
              <p className='text-sm text-gray-900'>
                Is there a possibility that the sale of this asset will result in a shortfall?
              </p>
              <p className='text-sm text-gray-900 italic mt-2'>
                A shortfall will occur if the sale price of your property and the selling costs
                are lower than the mortgage/liability secured against the property.
              </p>
            </div>
            <div className='mt-4 flex flex-wrap space-x-4'>
              <RadioButton
                id='sale-shortfall-yes'
                name='sale-shortfall'
                title='Yes'
                value='yes'
                checked={asset.saleShortFall === 'yes'}
                onChange={(value) => setRealEstateAsset({ ...asset, saleShortFall: value })}
              />
              <RadioButton
                id='sale-shortfall-no'
                name='sale-shortfall'
                title='No'
                value='no'
                checked={asset.saleShortFall === 'no'}
                onChange={(value) => setRealEstateAsset({ ...asset, saleShortFall: value })}
              />
            </div>
            <ValidationMessages
              name='saleShortFall'
              validationErrors={validationErrors}
            />
          </fieldset>

          {asset.saleShortFall === 'yes' && (
            <>
              <fieldset className='mt-6'>
                <div>
                  <p className='text-sm text-gray-900'>
                    Who will be responsible for the shortfall if there is any?
                  </p>
                </div>
                <div className='mt-4 flex flex-wrap space-x-4'>
                  <RadioButton
                    id='sale-shortfall-responsibility-me'
                    name='sale-shortfall-responsibility'
                    title='Me'
                    value='me'
                    checked={asset.saleShortFallResponsibility === 'me'}
                    onChange={(value) => setRealEstateAsset(
                      { ...asset, saleShortFallResponsibility: value }
                    )}
                  />
                  <RadioButton
                    id='sale-shortfall-responsibility-ex'
                    name='sale-shortfall-responsibility'
                    title='My Ex'
                    value='ex'
                    checked={asset.saleShortFallResponsibility === 'ex'}
                    onChange={(value) => setRealEstateAsset(
                      { ...asset, saleShortFallResponsibility: value }
                    )}
                  />
                  <RadioButton
                    id='sale-shortfall-responsibility-both'
                    name='sale-shortfall-responsibility'
                    title='Both Me and My Ex'
                    value='both'
                    checked={asset.saleShortFallResponsibility === 'both'}
                    onChange={(value) => setRealEstateAsset(
                      { ...asset, saleShortFallResponsibility: value }
                    )}
                  />
                </div>
                <ValidationMessages
                  name='saleShortFallResponsibility'
                  validationErrors={validationErrors}
                />
              </fieldset>

              <PercentageSplit
                title='In what proportion will you and your ex be paying the shortfall?'
                display={asset.saleShortFallResponsibility === 'both'}
                name='sale-shortfall-split'
                value={asset.saleShortFallSplit}
                setValue={(value) => setRealEstateAsset({ ...asset, saleShortFallSplit: value })}
                validationName='saleShortFallSplit'
                validationErrors={validationErrors}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default RealEstate
