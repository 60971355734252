import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Button, TextInput, RadioButton, RealEstate, BankAccount, Shares, Trust,
  Business, Company, Boat, MotorVehicle, PersonalPossession
} from '../components'
import RealEstateAsset from '../models/real-estate-asset'
import BankAccountAsset from '../models/bank-account-asset'
import SharesAsset from '../models/shares-asset'
import TrustAsset from '../models/trust-asset'
import BusinessAsset from '../models/business-asset'
import CompanyAsset from '../models/company-asset'
import BoatAsset from '../models/boat-asset'
import MotorVehicleAsset from '../models/motor-vehicle-asset'
import PersonalPossessionAsset from '../models/personal-possession-asset'
import { addAsset } from '../models/consent-order'
import { useConsentOrder } from './consent-order-route'
import { ValidationError } from '../models'

const AddAssetRoute = (): JSX.Element => {
  const navigate = useNavigate()
  const { consentOrder, setConsentOrder } = useConsentOrder()
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])

  const [realEstateAsset, setRealEstateAsset] = useState<RealEstateAsset>({
    addressPlaceId: '',
    addressStreet: '',
    addressSuburb: '',
    addressState: '',
    addressCountry: '',
    obtainedTitleSearch: '',
    landDescriptionInCertificateOfTitle: '',
    certificateOfTitleVolumeNumber: '',
    certificateOfTitleFolioNumber: '',
    namePropertyHeldBy: '',
    whoKeepingProperty: '',
    propertyType: '',
    residingPendingSettlement: '',
    receivingRentalIncome: '',
    transferPeriodQuantity: '30',
    transferPeriod: 'days',
    rentalIncomeSplit: '',
    saleProceeds: '',
    saleProceedsPercentageSplit: '',
    saleProceedsFixedSum: '',
    willThereBeCapitalGains: '',
    capitalGainsTaxResponsibility: '',
    capitalGainsTaxSplit: '',
    saleShortFall: '',
    saleShortFallResponsibility: '',
    saleShortFallSplit: ''
  })
  const [bankAccountAsset, setBankAccountAsset] = useState<BankAccountAsset>({
    nameOfBankOrFinancialInstitution: '',
    bankAccountType: '',
    lastFourDigitsOfAccount: '',
    nameAccountHeldBy: '',
    howFundsDivided: '',
    howFundsDividedSplit: '',
    fundsDividedFixedSum: '',
    fundsDividedPercentageSplit: ''
  })
  const [sharesAsset, setSharesAsset] = useState<SharesAsset>({
    nameOfShareHolding: '',
    nameSharesHeldBy: '',
    whoKeepingShares: '',
    transferPeriodQuantity: '30',
    transferPeriod: 'days',
    saleProceeds: '',
    saleProceedsPercentageSplit: '',
    saleProceedsFixedSum: '',
    willThereBeCapitalGains: '',
    capitalGainsTaxResponsibility: '',
    capitalGainsTaxSplit: ''
  })
  const [trustAsset, setTrustAsset] = useState<TrustAsset>({
    nameOfTrust: '',
    whoAreTheBeneficiaries: '',
    whoWillRetainTheTrust: '',
    transferPeriodQuantity: '30',
    transferPeriod: 'days',
    woundUpAssetsSplit: ''
  })
  const [businessAsset, setBusinessAsset] = useState<BusinessAsset>({
    businessName: '',
    nameBusinessHeld: '',
    whoKeepingBusiness: '',
    transferPeriodQuantity: '30',
    transferPeriod: 'days',
    saleProceeds: '',
    saleProceedsPercentageSplit: '',
    saleProceedsFixedSum: '',
    willThereBeCapitalGains: '',
    capitalGainsTaxResponsibility: '',
    capitalGainsTaxSplit: ''
  })
  const [companyAsset, setCompanyAsset] = useState<CompanyAsset>({
    companyName: '',
    nameCompanyHeldBy: '',
    whoKeepingCompany: '',
    transferPeriodQuantity: '30',
    transferPeriod: 'days',
    woundUpAssetsSplit: ''
  })
  const [boatAsset, setBoatAsset] = useState<BoatAsset>({
    boatName: '',
    nameBoatHeldBy: '',
    whoKeepingBoat: '',
    transferPeriodQuantity: '30',
    transferPeriod: 'days',
    saleProceeds: '',
    saleProceedsPercentageSplit: '',
    saleProceedsFixedSum: ''
  })
  const [motorVehicleAsset, setMotorVehicleAsset] = useState<MotorVehicleAsset>({
    motorVehicleYear: '',
    motorVehicleMake: '',
    motorVehicleModel: '',
    nameMotorVehicleHeldBy: '',
    whoKeepingMotorVehicle: '',
    transferPeriodQuantity: '30',
    transferPeriod: 'days',
    saleProceeds: '',
    saleProceedsPercentageSplit: '',
    saleProceedsFixedSum: ''
  })
  const [personalPossesionAsset, setPersonalPossessionAsset] = useState<PersonalPossessionAsset>({
    description: '',
    whoKeepingPersonalPossession: ''
  })
  const [saving, setSaving] = useState(false)

  const save = async (): Promise<void> => {
    setSaving(true)
    setValidationErrors([])

    let result = null
    if (type === 'real-estate') {
      result = await addAsset(consentOrder, name, type, realEstateAsset)
    }

    if (type === 'bank-account') {
      result = await addAsset(consentOrder, name, type, bankAccountAsset)
    }

    if (type === 'shares') {
      result = await addAsset(consentOrder, name, type, sharesAsset)
    }

    if (type === 'trust') {
      result = await addAsset(consentOrder, name, type, trustAsset)
    }

    if (type === 'business') {
      result = await addAsset(consentOrder, name, type, businessAsset)
    }

    if (type === 'company') {
      result = await addAsset(consentOrder, name, type, companyAsset)
    }

    if (type === 'boat') {
      result = await addAsset(consentOrder, name, type, boatAsset)
    }

    if (type === 'motor-vehicle') {
      result = await addAsset(consentOrder, name, type, motorVehicleAsset)
    }

    if (type === 'personal-possession') {
      result = await addAsset(consentOrder, name, type, personalPossesionAsset)
    }

    setSaving(false)

    if (result == null) {
      return
    }

    if (result.validationErrors.length === 0) {
      setConsentOrder(result.consentOrder)
      navigate(`/consent-orders/${consentOrder.slug}/assets`)
    } else {
      setValidationErrors(result.validationErrors)
    }
  }

  return (
    <div
      className='fixed z-10 inset-0 overflow-y-auto'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div
        className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block \
                   sm:p-0'
      >
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
        />
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'
        />

        <div
          className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden \
                     shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl \
                     sm:w-full'
        >
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className='space-y-4'>
              <div className='mt-3 text-center sm:mt-0 sm:text-left'>
                <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-title'>
                  Add Asset
                </h3>
                <hr className='mt-3' />
              </div>

              <form className='space-y-6'>
                <TextInput
                  title='Nickname'
                  name='asset.name'
                  value={name}
                  onChange={setName}
                  validationErrors={validationErrors}
                />

                <fieldset className='mt-6'>
                  <div>
                    <p className='text-sm text-gray-900'>What type of asset is this?</p>
                  </div>
                  <div className='mt-4 grid gap-1 grid-cols-2 md:grid-cols-4'>
                    <RadioButton
                      id='real-estate'
                      name='type'
                      title='Real Estate'
                      value='real-estate'
                      checked={type === 'real-estate'}
                      onChange={setType}
                    />

                    <RadioButton
                      id='bank-account'
                      name='type'
                      title='Bank Account'
                      value='bank-account'
                      checked={type === 'bank-account'}
                      onChange={setType}
                    />

                    <RadioButton
                      id='shares'
                      name='type'
                      title='Shares'
                      value='shares'
                      checked={type === 'shares'}
                      onChange={setType}
                    />

                    <RadioButton
                      id='trust'
                      name='type'
                      title='Trust'
                      value='trust'
                      checked={type === 'trust'}
                      onChange={setType}
                    />

                    <RadioButton
                      id='business'
                      name='type'
                      title='Business'
                      value='business'
                      checked={type === 'business'}
                      onChange={setType}
                    />

                    <RadioButton
                      id='company'
                      name='type'
                      title='Company'
                      value='company'
                      checked={type === 'company'}
                      onChange={setType}
                    />

                    <RadioButton
                      id='boat'
                      name='type'
                      title='Boat'
                      value='boat'
                      checked={type === 'boat'}
                      onChange={setType}
                    />

                    <RadioButton
                      id='motor-vehicle'
                      name='type'
                      title='Motor Vehicle'
                      value='motor-vehicle'
                      checked={type === 'motor-vehicle'}
                      onChange={setType}
                    />

                    <RadioButton
                      id='personal-possession'
                      name='type'
                      title='Furniture or Personal Possession'
                      value='personal-possession'
                      checked={type === 'personal-possession'}
                      containerClassName='col-span-2'
                      onChange={setType}
                    />
                  </div>
                </fieldset>

                {type === 'real-estate' && (
                  <RealEstate
                    asset={realEstateAsset}
                    setRealEstateAsset={setRealEstateAsset}
                    validationErrors={validationErrors}
                  />
                )}
                {type === 'bank-account' && (
                  <BankAccount
                    asset={bankAccountAsset}
                    setBankAccountAsset={setBankAccountAsset}
                    validationErrors={validationErrors}
                  />
                )}
                {type === 'shares' && (
                  <Shares
                    asset={sharesAsset}
                    setSharesAsset={setSharesAsset}
                    validationErrors={validationErrors}
                  />
                )}
                {type === 'trust' && (
                  <Trust
                    asset={trustAsset}
                    setTrustAsset={setTrustAsset}
                    validationErrors={validationErrors}
                  />
                )}
                {type === 'business' && (
                  <Business
                    asset={businessAsset}
                    setBusinessAsset={setBusinessAsset}
                    validationErrors={validationErrors}
                  />
                )}
                {type === 'company' && (
                  <Company
                    asset={companyAsset}
                    setCompanyAsset={setCompanyAsset}
                    validationErrors={validationErrors}
                  />
                )}
                {type === 'boat' && (
                  <Boat
                    asset={boatAsset}
                    setBoatAsset={setBoatAsset}
                    validationErrors={validationErrors}
                  />
                )}
                {type === 'motor-vehicle' && (
                  <MotorVehicle
                    asset={motorVehicleAsset}
                    setMotorVehicleAsset={setMotorVehicleAsset}
                    validationErrors={validationErrors}
                  />
                )}
                {type === 'personal-possession' && (
                  <PersonalPossession
                    asset={personalPossesionAsset}
                    setPersonalPossessionAsset={setPersonalPossessionAsset}
                    validationErrors={validationErrors}
                  />
                )}
              </form>
            </div>
          </div>

          <div className='bg-gray-50 px-4 py-3 sm:px-6 flex justify-end space-x-2'>
            <Button
              type='white'
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              onClick={save}
              loading={saving}
              disabled={type === ''}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddAssetRoute
