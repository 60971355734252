import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const completedPayoutsQuery = gql`
  mutation CompletedPayouts($input: CompletedPayoutsInput!) {
    completedPayouts(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default completedPayoutsQuery
