import React from 'react'
import { useParams } from 'react-router-dom'
import {
  UserIcon, HomeIcon, EyeIcon, CreditCardIcon, CurrencyDollarIcon, CashIcon
} from '@heroicons/react/outline'

import { NavItem } from './'
import ConsentOrder from '../models/consent-order'
import { useAdminQuery } from '../graphql/queries'

interface NavItemsProps {
  consentOrder: ConsentOrder
}

const NavItems = ({ consentOrder }: NavItemsProps): JSX.Element => {
  const { slug } = useParams()
  const { data } = useAdminQuery()

  if (slug === undefined) {
    return <></>
  }

  return (
    <nav className='mt-5 flex-1 px-2 space-y-1'>
      <NavItem
        to={`/consent-orders/${slug}/personal-information`}
        title='Personal Information'
        icon={UserIcon}
        enabled
      />
      <NavItem
        to={`/consent-orders/${slug}/assets`}
        title='Assets'
        icon={HomeIcon}
        enabled={consentOrder.completedPersonalInformation}
      />
      <NavItem
        to={`/consent-orders/${slug}/liabilities`}
        title='Liabilities'
        icon={CreditCardIcon}
        enabled={consentOrder.completedAssets}
      />
      <NavItem
        to={`/consent-orders/${slug}/payout`}
        title='Payout'
        icon={CashIcon}
        enabled={consentOrder.completedLiabilities}
      />
      <NavItem
        to={`/consent-orders/${slug}/superannuation`}
        title='Superannuation'
        icon={CurrencyDollarIcon}
        enabled={consentOrder.completedPayouts}
      />
      {data?.admin === true && (
        <NavItem
          to={`/consent-orders/${slug}/preview`}
          title='Preview'
          icon={EyeIcon}
          enabled
        />
      )}
      {consentOrder.purchased && (
        <NavItem
          to={`/consent-orders/${slug}/view`}
          title='View'
          icon={EyeIcon}
          enabled
        />
      )}
    </nav>
  )
}

export default NavItems
