import { gql, MutationTuple, useMutation } from '@apollo/client'

const LOGIN_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      success
    }
  }
`

interface LoginData {
  login: {
    success: Boolean
  }
}

interface LoginVariables {
  input: {
    email: string
    password: string
  }
}

const useLoginMutation = (): MutationTuple<LoginData, LoginVariables> => {
  return useMutation(LOGIN_MUTATION)
}

export default useLoginMutation
