import React, { useEffect, useState } from 'react'

import {
  Input, RadioButton, SelectInput, TransferPeriod, PercentageSplit, ValidationMessages
} from '../components'
import { ConsentOrder, LoanLiability, ValidationError } from '../models'

interface LoanProps {
  consentOrder: ConsentOrder
  liability: LoanLiability
  setLoanLiability: (liability: LoanLiability) => void
  validationErrors: ValidationError[]
}

const Loan = ({
  consentOrder, liability, setLoanLiability, validationErrors
}: LoanProps): JSX.Element => {
  const [assets] = useState(
    consentOrder.assets.filter(
      (a) => a.assetType === 'boat' || a.assetType === 'motor-vehicle' || a.assetType === 'business'
    )
  )
  const selectedAsset = assets.find((a) => a.id === liability.assetId)

  useEffect(() => {
    if (assets.length === 0) {
      setLoanLiability({ ...liability, isAttachedToAnAsset: 'no' })
    }
  }, [consentOrder])

  return (
    <>
      <Input
        title='Name of Bank, Financial Institution or Lender'
        name='load-lender-name'
        type='text'
        value={liability.nameOfBankOrFinancialInstitution}
        onChange={(value) => setLoanLiability(
          { ...liability, nameOfBankOrFinancialInstitution: value }
        )}
        validationErrorName='nameOfBankOrFinancialInstitution'
        validationErrors={validationErrors}
      />

      <Input
        title='What are the last four digits of this account?'
        name='account-last-four-digits'
        type='text'
        value={liability.lastFourDigitsOfAccount}
        onChange={(value) => setLoanLiability({ ...liability, lastFourDigitsOfAccount: value })}
        validationErrorName='lastFourDigitsOfAccount'
        validationErrors={validationErrors}
      />

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>In whose name is this held?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='loan-held-me'
            name='loan-held'
            title='Me'
            value='me'
            checked={liability.nameAccountHeldBy === 'me'}
            onChange={(value) => setLoanLiability({ ...liability, nameAccountHeldBy: value })}
          />
          <RadioButton
            id='loan-held-ex'
            name='loan-held'
            title='My Ex'
            value='ex'
            checked={liability.nameAccountHeldBy === 'ex'}
            onChange={(value) => setLoanLiability({ ...liability, nameAccountHeldBy: value })}
          />
          <RadioButton
            id='loan-held-both'
            name='loan-held'
            title='Both Me and My Ex'
            value='both'
            checked={liability.nameAccountHeldBy === 'both'}
            onChange={(value) => setLoanLiability({ ...liability, nameAccountHeldBy: value })}
          />
        </div>
        <ValidationMessages
          name='nameAccountHeldBy'
          validationErrors={validationErrors}
        />
      </fieldset>

      {assets.length !== 0 && (
        <fieldset className='mt-6'>
          <div>
            <p className='text-sm text-gray-900'>Is this liability attached to any asset?</p>
          </div>
          <div className='mt-4 flex flex-wrap space-x-4'>
            <RadioButton
              id='liability-attached-to-asset-yes'
              name='liability-attached-to-asset'
              title='Yes'
              value='yes'
              checked={liability.isAttachedToAnAsset === 'yes'}
              onChange={(value) => setLoanLiability({ ...liability, isAttachedToAnAsset: value })}
            />
            <RadioButton
              id='liability-attached-to-asset-no'
              name='liability-attached-to-asset'
              title='No'
              value='no'
              checked={liability.isAttachedToAnAsset === 'no'}
              onChange={(value) => setLoanLiability({ ...liability, isAttachedToAnAsset: value })}
            />
          </div>
          <ValidationMessages
            name='isAttachedToAnAsset'
            validationErrors={validationErrors}
          />
        </fieldset>
      )}

      {liability.isAttachedToAnAsset === 'yes' && (
        <>
          <SelectInput
            title='What asset does this liability attach to?'
            name='real-estate-asset'
            displayProperty='name'
            idProperty='id'
            options={assets}
            value={liability.assetId?.toString()}
            onChange={(value) => setLoanLiability({ ...liability, assetId: value })}
            validationErrorName='asset'
            validationErrors={validationErrors}
          />

          {
            // eslint-disable-next-line  @typescript-eslint/strict-boolean-expressions
            selectedAsset?.changingOwnership && (
              <>
                <fieldset className='mt-6'>
                  <div>
                    <p className='text-sm text-gray-900'>
                      Who will be responsible for servicing the loan repayments pending settlement?
                    </p>
                  </div>
                  <div className='mt-4 flex flex-wrap space-x-4'>
                    <RadioButton
                      id='loan-repayments-responsibility-me'
                      name='loan-repayments-responsibility'
                      title='Me'
                      value='me'
                      checked={liability.paymentsPendingSettlementResponsibility === 'me'}
                      onChange={(value) => setLoanLiability(
                        { ...liability, paymentsPendingSettlementResponsibility: value }
                      )}
                    />
                    <RadioButton
                      id='loan-repayments-responsibility-ex'
                      name='loan-repayments-responsibility'
                      title='My Ex'
                      value='ex'
                      checked={liability.paymentsPendingSettlementResponsibility === 'ex'}
                      onChange={(value) => setLoanLiability(
                        { ...liability, paymentsPendingSettlementResponsibility: value }
                      )}
                    />
                    <RadioButton
                      id='loan-repayments-responsibility-both'
                      name='loan-repayments-responsibility'
                      title='Both Me and My Ex'
                      value='both'
                      checked={liability.paymentsPendingSettlementResponsibility === 'both'}
                      onChange={(value) => setLoanLiability(
                        { ...liability, paymentsPendingSettlementResponsibility: value }
                      )}
                    />
                  </div>
                  <ValidationMessages
                    name='paymentsPendingSettlementResponsibility'
                    validationErrors={validationErrors}
                  />
                </fieldset>

                <PercentageSplit
                  display={liability.paymentsPendingSettlementResponsibility === 'both'}
                  name='loan-percentage-split'
                  value={liability.paymentsPendingSettlementSplit}
                  setValue={(value) => setLoanLiability(
                    { ...liability, paymentsPendingSettlementSplit: value }
                  )}
                />
              </>
            )
          }
        </>
      )}

      {liability.isAttachedToAnAsset === 'no' && (
        <>
          <fieldset className='mt-6'>
            <div>
              <p className='text-sm text-gray-900'>Who is responsible for this liability?</p>
            </div>
            <div className='mt-4 flex flex-wrap space-x-4'>
              <RadioButton
                id='loan-responsibility-yes'
                name='loan-responsibility'
                title='Me'
                value='me'
                checked={liability.whoIsResponsibleForThisLoan === 'me'}
                onChange={(value) => setLoanLiability(
                  { ...liability, whoIsResponsibleForThisLoan: value }
                )}
              />
              <RadioButton
                id='loan-responsibility-no'
                name='loan-responsibility'
                title='My Ex'
                value='ex'
                checked={liability.whoIsResponsibleForThisLoan === 'ex'}
                onChange={(value) => setLoanLiability(
                  { ...liability, whoIsResponsibleForThisLoan: value }
                )}
              />
            </div>
            <ValidationMessages
              name='whoIsResponsibleForThisLoan'
              validationErrors={validationErrors}
            />
          </fieldset>

          <TransferPeriod
            display={
              liability.nameAccountHeldBy !== liability.whoIsResponsibleForThisLoan &&
              liability.whoIsResponsibleForThisLoan !== ''
            }
            name='loan-transfer'
            title='What timeframe do you need to do the refinance?'
            transferPeriodQuantity={liability.transferPeriodQuantity}
            setTransferPeriodQuantity={(value) => setLoanLiability(
              { ...liability, transferPeriodQuantity: value }
            )}
            transferPeriod={liability.transferPeriod}
            setTransferPeriod={(value) => setLoanLiability({ ...liability, transferPeriod: value })}
            validationErrors={validationErrors}
          />

          {liability.nameAccountHeldBy !== liability.whoIsResponsibleForThisLoan &&
            liability.whoIsResponsibleForThisLoan !== '' &&
            (
              <>
                <fieldset className='mt-6'>
                  <div>
                    <p className='text-sm text-gray-900'>
                      Who will be responsible for servicing the loan repayments pending settlement?
                    </p>
                  </div>
                  <div className='mt-4 flex flex-wrap space-x-4'>
                    <RadioButton
                      id='loan-repayments-responsible-me'
                      name='loan-repayments-responsible'
                      title='Me'
                      value='me'
                      checked={liability.paymentsPendingSettlementResponsibility === 'me'}
                      onChange={(value) => setLoanLiability(
                        { ...liability, paymentsPendingSettlementResponsibility: value }
                      )}
                    />
                    <RadioButton
                      id='loan-repayments-responsible-ex'
                      name='loan-repayments-responsible'
                      title='My Ex'
                      value='ex'
                      checked={liability.paymentsPendingSettlementResponsibility === 'ex'}
                      onChange={(value) => setLoanLiability(
                        { ...liability, paymentsPendingSettlementResponsibility: value }
                      )}
                    />
                    <RadioButton
                      id='loan-repayments-responsible-both'
                      name='loan-repayments-responsible'
                      title='Both Me and My Ex'
                      value='both'
                      checked={liability.paymentsPendingSettlementResponsibility === 'both'}
                      onChange={(value) => setLoanLiability(
                        { ...liability, paymentsPendingSettlementResponsibility: value }
                      )}
                    />
                  </div>
                  <ValidationMessages
                    name='paymentsPendingSettlementResponsibility'
                    validationErrors={validationErrors}
                  />
                </fieldset>

                <PercentageSplit
                  display={liability.paymentsPendingSettlementResponsibility === 'both'}
                  name='loan-percentage-split'
                  value={liability.paymentsPendingSettlementSplit}
                  setValue={(value) => setLoanLiability(
                    { ...liability, paymentsPendingSettlementSplit: value }
                  )}
                  validationName='paymentsPendingSettlementSplit'
                  validationErrors={validationErrors}
                />
              </>
            )}
        </>
      )}
    </>
  )
}

export default Loan
