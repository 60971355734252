import { ApolloClient, InMemoryCache, createHttpLink, DefaultOptions } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_HOST,
  credentials: 'include'
})

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers
  }
}))

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
}

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions
})

export default apolloClient
