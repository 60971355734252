import { gql } from '@apollo/client'

export const createPaymentIntentQuery = gql`
  mutation CreatePaymentIntent($input: CreatePaymentIntentInput!) {
    createPaymentIntent(input: $input) {
      clientSecret
    }
  }
`;

export default createPaymentIntentQuery