import { gql, MutationTuple, useMutation } from '@apollo/client'

import { consentOrderFieldsFragment } from '../../queries/fragments'
import { ConsentOrder, ValidationError } from '../../models'

const UPDATE_SELF_MANAGED_SUPERANNUATION_MUTATION = gql`
  mutation UpdateCommercialSuperannuationMutation($input: UpdateCommercialSuperannuationInput!) {
    updateCommercialSuperannuation(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
      validationErrors {
        name
        messages
      }
    }
  }

  ${consentOrderFieldsFragment}
`

interface UpdateCommercialSuperannuationData {
  updateCommercialSuperannuation: {
    consentOrder: ConsentOrder
    validationErrors: ValidationError[]
  }
}

interface UpdateCommercialSuperannuationVariables {
  input: {
    slug: string
    fundId: string
    name: string
    nameOfFund: string
    membershipNumber: string
    nameFundHeldBy: string
    willFundBeSplit: string
    fundSplit: string
    fundSplitPercentage: string
    fundSplitFixedSum: string
  }
}

const useUpdateCommercialSuperannuationMutation = (): MutationTuple<UpdateCommercialSuperannuationData, UpdateCommercialSuperannuationVariables> => {
  return useMutation(UPDATE_SELF_MANAGED_SUPERANNUATION_MUTATION)
}

export default useUpdateCommercialSuperannuationMutation
