import React from 'react'

import { RadioButton, FundsDivided, Input, ValidationMessages } from '../components'
import { ConsentOrder, CommercialSuperannuation, ValidationError } from '../models'

interface CommercialSuperProps {
  consentOrder: ConsentOrder
  fund: CommercialSuperannuation
  setFund: (fund: CommercialSuperannuation) => void
  validationErrors: ValidationError[]
}

const CommercialSuper = ({
  consentOrder, fund, setFund, validationErrors
}: CommercialSuperProps): JSX.Element => {
  return (
    <>
      <div className='sm:col-span-4'>
        <Input
          title='Name of Fund'
          name='name-of-fund'
          type='text'
          value={fund.nameOfFund}
          onChange={(value) => setFund({ ...fund, nameOfFund: value })}
          validationErrorName='nameOfFund'
          validationErrors={validationErrors}
        />
      </div>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>In whose name is this held?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='name-fund-held-me'
            name='name-fund-held'
            title='Me'
            value='me'
            checked={fund.nameFundHeldBy === 'me'}
            onChange={(value) => setFund({ ...fund, nameFundHeldBy: value })}
          />
          <RadioButton
            id='name-fund-held-ex'
            name='name-fund-held'
            title='My Ex'
            value='ex'
            checked={fund.nameFundHeldBy === 'ex'}
            onChange={(value) => setFund({ ...fund, nameFundHeldBy: value })}
          />
        </div>
        <ValidationMessages
          name='nameFundHeldBy'
          validationErrors={validationErrors}
        />
      </fieldset>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>
            Will there be a split of this super fund between you and your ex?
          </p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='split-fund-yes'
            name='split-fund'
            title='Yes'
            value='yes'
            checked={fund.willFundBeSplit === 'yes'}
            onChange={(value) => setFund({ ...fund, willFundBeSplit: value })}
          />
          <RadioButton
            id='split-fund-no'
            name='split-fund'
            title='No'
            value='no'
            checked={fund.willFundBeSplit === 'no'}
            onChange={(value) => setFund({ ...fund, willFundBeSplit: value })}
          />
        </div>
        <ValidationMessages
          name='willFundBeSplit'
          validationErrors={validationErrors}
        />
      </fieldset>

      {fund.willFundBeSplit === 'yes' && (
        <>
          <div className='sm:col-span-4'>
            <Input
              title='What is the membership number of this fund?'
              name='membership-number'
              type='text'
              value={fund.membershipNumber}
              onChange={(value) => setFund({ ...fund, membershipNumber: value })}
              validationErrorName='membershipNumber'
              validationErrors={validationErrors}
            />
          </div>

          <FundsDivided
            display
            name='fund-divided'
            title='Is the split being made as a percentage or fixed sum?'
            splitTypeValue={fund.fundSplit}
            setSplitType={(value) => setFund({ ...fund, fundSplit: value })}
            percentageSplitValue={fund.fundSplitPercentage}
            setPercentageSplitValue={(value) => setFund({ ...fund, fundSplitPercentage: value })}
            fixedSumValue={fund.fundSplitFixedSum}
            setFixedSumValue={(value) => setFund({ ...fund, fundSplitFixedSum: value })}
            splitTypeValidationName='fundSplit'
            percentageSplitValidationName='fundSplitPercentage'
            fixedSumValidationName='fundSplitFixedSum'
            validationErrors={validationErrors}
            fixedSumSplitAmountLabel={fund.nameFundHeldBy === 'me' ? 'My Ex' : 'Me'}
            fixedSumSplitBalanceLabel={fund.nameFundHeldBy === 'me' ? 'Me' : 'My Ex'}
          />
        </>
      )}
    </>
  )
}

export default CommercialSuper
