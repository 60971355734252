import { gql } from '@apollo/client'

const consentOrderTemplateQuery = gql`
  query ConsentOrderTemplateQuery {
    consentOrderTemplate {
      id
      template
    }
  }
`

export default consentOrderTemplateQuery
