import React, { useEffect, useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'

import { Button, Checkbox } from '../components'
import { useConsentOrder } from './consent-order-route'
import { acceptDisclaimers } from '../models/consent-order'

const DisclaimerRoute = (): JSX.Element => {
  const navigate = useNavigate()
  const { consentOrder } = useConsentOrder()
  const [agreeToDisclaimer, setAgreeToDisclaimer] = useState(false)
  const [displayConfirmation, setDisplayConfirmation] = useState(false)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (consentOrder.agreedToDisclaimer) {
      navigate(`/consent-orders/${consentOrder.slug}/purchase`)
    }
  }, [consentOrder])

  const save = async (): Promise<void> => {
    if (!agreeToDisclaimer) {
      setDisplayConfirmation(true)
      return
    }

    setSaving(true)

    await acceptDisclaimers(consentOrder)

    setSaving(false)

    navigate(`/consent-orders/${consentOrder.slug}/purchase`)
  }

  return (
    <>
      <Transition.Root show={displayConfirmation} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={setDisplayConfirmation}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed z-10 inset-0 overflow-y-auto'>
            <div
              className='flex items-end sm:items-center justify-center min-h-full p-4 text-center
                        sm:p-0'
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel
                  className='relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden
                             shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full
                             sm:p-6'
                >
                  <div>
                    <div className='mt-3 text-center sm:mt-5'>
                      <Dialog.Title
                        as='h3'
                        className='text-lg leading-6 font-medium text-gray-900'
                      >
                        Accept Disclaimer
                      </Dialog.Title>
                      <div className='mt-2'>
                        <p className='text-sm text-gray-500'>
                          You must accept the disclaimer before proceeding with your consent
                          order.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-5 sm:mt-6'>
                    <Button
                      className='w-full'
                      onClick={() => setDisplayConfirmation(false)}
                    >
                      Ok
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className='rounded-lg bg-white p-4 space-y-8'>
        <div>
          <div>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              Disclaimer
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Acknowledge the following to continue.
            </p>
          </div>
        </div>

        <hr />

        <div className='space-y-2'>
          <p className='text-sm text-gray-900'>
            This website is produced to provide the community with a basic understanding of the
            process to apply for family law property settlement consent orders. It is a guide and
            general information source only.
          </p>

          <p className='text-sm text-gray-900'>
            Our goods come with guarantees that cannot be excluded under the Australian Consumer
            Law. You are entitled to a replacement or refund for a major failure and compensation
            for any other reasonably foreseeable loss or damage. You are also entitled to have the
            goods repaired or replaced if the goods fail to be of acceptable quality and the
            failure does not amount to a major failure.
          </p>

          <p className='text-sm text-gray-900'>
            To the extent permitted by law, Legal Aspirations does not otherwise accept any
            liability for and will in no way be liable to you or anyone else for any loss or
            damage, however caused (including through negligence) which may be directly or
            indirectly suffered in connection with the use of material and/or documents purchased
            from this website.
          </p>

          <p className='text-sm text-gray-900'>
            Whilst every effort has been made to ensure the accuracy of the information and
            materials provided on this website, it does not and is not intended to constitute
            legal, financial or taxation advice. No guarantee is given nor responsibility taken
            by Legal Aspirations for errors or omissions on this website.
          </p>

          <p className='text-sm text-gray-900'>
            No person should rely on the material provided on this website without obtaining legal
            advice relevant to their own circumstance and without taking and considering such other
            professional advice as may be necessary including without limitation taxation advice.
            This is particularly so for circumstances involving trusts, companies and/or self-managed
            superannuation funds.
          </p>

          <Checkbox
            id='agree-to-disclaimer'
            name='agree-to-disclaimer'
            title='I agree to the above statements.'
            checked={agreeToDisclaimer}
            onChange={setAgreeToDisclaimer}
          />

          <hr className='pt-5' />
          <div className='flex justify-between'>
            <Button
              to={`/consent-orders/${consentOrder.slug}/superannuation`}
            >
              Previous
            </Button>
            <Button
              onClick={save}
              loading={saving}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default DisclaimerRoute
