import React from 'react'

import {
  Input, RadioButton, TransferPeriod, PercentageSplit, ValidationMessages
} from '../components'
import { CompanyAsset, ValidationError } from '../models'

interface CompanyProps {
  asset: CompanyAsset
  setCompanyAsset: (asset: CompanyAsset) => void
  validationErrors: ValidationError[]
}

const Company = ({ asset, setCompanyAsset, validationErrors }: CompanyProps): JSX.Element => {
  return (
    <>
      <div className='sm:col-span-4'>
        <Input
          title='Company Name'
          name='company-name'
          type='text'
          value={asset.companyName}
          onChange={(value) => setCompanyAsset({ ...asset, companyName: value })}
          validationErrorName='companyName'
          validationErrors={validationErrors}
        />
      </div>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>
            In whose name is the Company held?
          </p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='company-held-by-me'
            name='company-held-by'
            title='Me'
            value='me'
            checked={asset.nameCompanyHeldBy === 'me'}
            onChange={(value) => setCompanyAsset({ ...asset, nameCompanyHeldBy: value })}
          />
          <RadioButton
            id='company-held-by-ex'
            name='company-held-by'
            title='My Ex'
            value='ex'
            checked={asset.nameCompanyHeldBy === 'ex'}
            onChange={(value) => setCompanyAsset({ ...asset, nameCompanyHeldBy: value })}
          />
          <RadioButton
            id='company-held-by-both'
            name='company-held-by'
            title='Both Me and My Ex'
            value='both'
            checked={asset.nameCompanyHeldBy === 'both'}
            onChange={(value) => setCompanyAsset({ ...asset, nameCompanyHeldBy: value })}
          />
        </div>
        <ValidationMessages
          name='nameCompanyHeldBy'
          validationErrors={validationErrors}
        />
      </fieldset>
      <p className='font-medium text-sm'>
        This refers to the shareholders (and not the officeholders) of the company.
      </p>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>
            Who is keeping this?
          </p>
        </div>
        <div className='mt-2 md:mt-4 md:flex md:flex-wrap md:space-x-4'>
          <RadioButton
            id='company-keeping-me'
            name='company-keeping'
            title='Me'
            value='me'
            checked={asset.whoKeepingCompany === 'me'}
            onChange={(value) => setCompanyAsset({ ...asset, whoKeepingCompany: value })}
          />
          <RadioButton
            id='company-keeping-ex'
            name='company-keeping'
            title='My Ex'
            value='ex'
            checked={asset.whoKeepingCompany === 'ex'}
            onChange={(value) => setCompanyAsset({ ...asset, whoKeepingCompany: value })}
          />
          <RadioButton
            id='company-keeping-both'
            name='company-keeping'
            title='Wound Up (i.e. Close and Shut Down the Company)'
            value='wound-up'
            checked={asset.whoKeepingCompany === 'wound-up'}
            onChange={(value) => setCompanyAsset({ ...asset, whoKeepingCompany: value })}
          />
        </div>
        <ValidationMessages
          name='whoKeepingCompany'
          validationErrors={validationErrors}
        />
      </fieldset>

      <TransferPeriod
        display={
          asset.nameCompanyHeldBy !== asset.whoKeepingCompany &&
          asset.whoKeepingCompany !== 'wound-up' && asset.whoKeepingCompany !== ''
        }
        name='company-transfer'
        title='What timeframe do you need to change the Company ownership?'
        transferPeriodQuantity={asset.transferPeriodQuantity}
        setTransferPeriodQuantity={(value) => setCompanyAsset(
          { ...asset, transferPeriodQuantity: value }
        )}
        transferPeriod={asset.transferPeriod}
        setTransferPeriod={(value) => setCompanyAsset({ ...asset, transferPeriod: value })}
        validationErrors={validationErrors}
      />

      <PercentageSplit
        display={asset.whoKeepingCompany === 'wound-up'}
        name='how-company-assets-divided'
        title='In what proportion are the assets of the Company (if any) being divided?'
        value={asset.woundUpAssetsSplit}
        setValue={(value) => setCompanyAsset({ ...asset, woundUpAssetsSplit: value })}
        validationName='woundUpAssetsSplit'
        validationErrors={validationErrors}
      />
    </>
  )
}

export default Company
