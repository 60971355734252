interface extractedAddress {
  addressPlaceId: string
  addressStreet: string
  addressSuburb: string
  addressState: string
  addressCountry: string
}

const extractAddress = (place: {
  label: string
  value: { place_id: string, terms: Array<{ offset: number, value: string }>, types: Array<string> }
}): extractedAddress => {
  if (place.value.types.includes('subpremise')) {
    return {
      addressPlaceId: place.value.place_id,
      addressStreet: `${place.value.terms[0].value}/${place.value.terms[1].value} ${place.value.terms[2].value}`,
      addressSuburb: place.value.terms[3].value,
      addressState: place.value.terms[4].value,
      addressCountry: place.value.terms.length > 5 ? place.value.terms[5].value : 'Australia'
    }
  }

  if (place.value.types.includes('street_address')) {
    if (place.value.terms.length === 3) {
      return {
        addressPlaceId: place.value.place_id,
        addressStreet: place.value.terms[0].value,
        addressSuburb: place.value.terms[1].value,
        addressState: place.value.terms[2].value,
        addressCountry: place.value.terms.length > 3 ? place.value.terms[3].value : 'Australia'
      }
    } else {
      return {
        addressPlaceId: place.value.place_id,
        addressStreet: `${place.value.terms[0].value} ${place.value.terms[1].value}`,
        addressSuburb: place.value.terms[2].value,
        addressState: place.value.terms[3].value,
        addressCountry: place.value.terms.length > 4 ? place.value.terms[4].value : 'Australia'
      }
    }
  }

  // Place type is 'premise'
  return {
    addressPlaceId: place.value.place_id,
    addressStreet: `${place.value.terms[0].value} ${place.value.terms[1].value}`,
    addressSuburb: place.value.terms[2].value,
    addressState: place.value.terms[3].value,
    addressCountry: place.value.terms.length > 4 ? place.value.terms[4].value : 'Australia'
  }
}

export default extractAddress
