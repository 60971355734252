import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const acceptDisclaimersQuery = gql`
  mutation AcceptDisclaimers($input: AcceptDisclaimersInput!) {
    acceptDisclaimers(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default acceptDisclaimersQuery
