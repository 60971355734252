import { gql } from '@apollo/client'

const consentOrderFieldsFragment = gql`
  fragment consentOrderFields on ConsentOrder {
    id
    slug
    email
    firstName
    middleNames
    surname
    gender
    addressPlaceId
    addressStreet
    addressSuburb
    addressState
    addressCountry
    exFirstName
    exMiddleNames
    exSurname
    exGender
    exAddressPlaceId
    exAddressStreet
    exAddressSuburb
    exAddressState
    exAddressCountry
    relationshipType
    agreedToDisclaimer
    completedPersonalInformation
    completedAssets
    completedLiabilities
    completedPayouts
    price
    purchased
    purchaseConfirmedAt
    createdAt
    assets {
      id
      name
      assetType
      changingOwnership
      toBeTransfered
      bankAccountAsset {
        bankAccountType
        fundsDividedFixedSum
        fundsDividedPercentageSplit
        howFundsDivided
        howFundsDividedSplit
        lastFourDigitsOfAccount
        nameAccountHeldBy
        nameOfBankOrFinancialInstitution
      }
      boatAsset {
        boatName
        nameBoatHeldBy
        saleProceeds
        saleProceedsFixedSum
        saleProceedsPercentageSplit
        transferPeriod
        transferPeriodQuantity
        whoKeepingBoat
      }
      businessAsset {
        businessName
        capitalGainsTaxResponsibility
        capitalGainsTaxSplit
        nameBusinessHeld
        saleProceeds
        saleProceedsFixedSum
        saleProceedsPercentageSplit
        transferPeriod
        transferPeriodQuantity
        whoKeepingBusiness
        willThereBeCapitalGains
      }
      companyAsset {
        companyName
        nameCompanyHeldBy
        transferPeriod
        transferPeriodQuantity
        whoKeepingCompany
        woundUpAssetsSplit
      }
      motorVehicleAsset {
        motorVehicleMake
        motorVehicleModel
        motorVehicleYear
        nameMotorVehicleHeldBy
        saleProceeds
        saleProceedsFixedSum
        saleProceedsPercentageSplit
        transferPeriod
        transferPeriodQuantity
        whoKeepingMotorVehicle
      }
      personalPossessionAsset {
        description
        whoKeepingPersonalPossession
      }
      realEstateAsset {
        addressCountry
        addressState
        addressStreet
        addressSuburb
        capitalGainsTaxResponsibility
        capitalGainsTaxSplit
        certificateOfTitleFolioNumber
        certificateOfTitleVolumeNumber
        landDescriptionInCertificateOfTitle
        namePropertyHeldBy
        obtainedTitleSearch
        propertyType
        receivingRentalIncome
        rentalIncomeSplit
        residingPendingSettlement
        saleProceeds
        saleProceedsFixedSum
        saleProceedsPercentageSplit
        saleShortFall
        saleShortFallResponsibility
        saleShortFallSplit
        transferPeriod
        transferPeriodQuantity
        whoKeepingProperty
        willThereBeCapitalGains
        addressPlaceId
      }
      sharesAsset {
        capitalGainsTaxResponsibility
        capitalGainsTaxSplit
        nameOfShareHolding
        nameSharesHeldBy
        saleProceeds
        saleProceedsFixedSum
        saleProceedsPercentageSplit
        transferPeriod
        transferPeriodQuantity
        whoKeepingShares
        willThereBeCapitalGains
      }
      trustAsset {
        nameOfTrust
        transferPeriod
        transferPeriodQuantity
        whoAreTheBeneficiaries
        whoWillRetainTheTrust
        woundUpAssetsSplit
      }
    }
    liabilities {
      id
      name
      liabilityType,
      mortgageLiability {
        lastFourDigitsOfAccount
        nameAccountHeldBy
        nameOfBankOrFinancialInstitution
        paymentsPendingSettlementResponsibility
        paymentsPendingSettlementSplit
        assetId
      }
      loanLiability {
        isAttachedToAnAsset
        lastFourDigitsOfAccount
        nameAccountHeldBy
        nameOfBankOrFinancialInstitution
        paymentsPendingSettlementResponsibility
        paymentsPendingSettlementSplit
        transferPeriod
        transferPeriodQuantity
        whoIsResponsibleForThisLoan
        assetId
      }
      creditCardLiability {
        lastFourDigitsOfAccount
        nameAccountHeldBy
        nameOfBankOrFinancialInstitution
        transferPeriod
        transferPeriodQuantity
        whoIsKeepingCreditCard
      }
      otherLiability {
        description
        estimatedAmount
        isAttachedToAnAsset
        nameLiabilityHeld
        nameOfLenderOrCreditor
        whoIsResponsibleForThisLiability
        assetId
      }
    }
    superannuations {
      id
      name
      fundType
      commercialSuperannuation {
        fundSplit
        fundSplitFixedSum
        fundSplitPercentage
        membershipNumber
        nameFundHeldBy
        nameOfFund
        willFundBeSplit
      }
      selfManagedSuperannuation {
        exitingMemberAdditionalPortion
        exitingMemberBalance
        exitingMemberKeeping
        fundTrustees
        keepingMemberBalance
        members
        nameOfCorporateTrust
        nameOfSelfManagedFund
        paymentToExitingMember
        transferPeriod
        transferPeriodQuantity
        whoKeepingFund
      }
    }
    payout {
      whoReceivingPayout
      payoutAmount
      payoutTimeframe
      transferPeriodQuantity
      transferPeriod
      assetId
    }
  }
`

export default consentOrderFieldsFragment
