import React from 'react'

import { Input, RadioButton, FundsDivided, ValidationMessages } from '../components'
import { BankAccountAsset, ValidationError } from '../models'

interface BankAccountProps {
  asset: BankAccountAsset
  setBankAccountAsset: (asset: BankAccountAsset) => void
  validationErrors: ValidationError[]
}

const BankAccount = (
  { asset, setBankAccountAsset, validationErrors }: BankAccountProps
): JSX.Element => {
  return (
    <>
      <div className='sm:col-span-4'>
        <Input
          title='Name of Bank or Financial Institution'
          name='bank-name'
          type='text'
          value={asset.nameOfBankOrFinancialInstitution}
          onChange={(value) => setBankAccountAsset(
            { ...asset, nameOfBankOrFinancialInstitution: value }
          )}
          validationErrorName='nameOfBankOrFinancialInstitution'
          validationErrors={validationErrors}
        />
      </div>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>Type of Account</p>
        </div>
        <div className='mt-2 md:mt-4 md:flex md:flex-wrap md:space-x-4'>
          <RadioButton
            id='bank-account-type-savings'
            name='bank-account-type'
            title='Savings'
            value='savings'
            checked={asset.bankAccountType === 'savings'}
            onChange={(value) => setBankAccountAsset({ ...asset, bankAccountType: value })}
          />
          <RadioButton
            id='bank-account-type-cheque'
            name='bank-account-type'
            title='Cheque'
            value='cheque'
            checked={asset.bankAccountType === 'cheque'}
            onChange={(value) => setBankAccountAsset({ ...asset, bankAccountType: value })}
          />
          <RadioButton
            id='bank-account-type-offset'
            name='bank-account-type'
            title='Offset'
            value='offset'
            checked={asset.bankAccountType === 'offset'}
            onChange={(value) => setBankAccountAsset({ ...asset, bankAccountType: value })}
          />
          <RadioButton
            id='bank-account-type-term-deposit'
            name='bank-account-type'
            title='Term Deposit'
            value='term-deposit'
            checked={asset.bankAccountType === 'term-deposit'}
            onChange={(value) => setBankAccountAsset({ ...asset, bankAccountType: value })}
          />
          <RadioButton
            id='bank-account-type-trading'
            name='bank-account-type'
            title='Trading'
            value='trading'
            checked={asset.bankAccountType === 'trading'}
            onChange={(value) => setBankAccountAsset({ ...asset, bankAccountType: value })}
          />
        </div>
        <ValidationMessages
          name='bankAccountType'
          validationErrors={validationErrors}
        />
      </fieldset>

      <div className='sm:col-span-4'>
        <Input
          title='What are the last four digits of this account?'
          name='account-last-four-digits'
          type='text'
          value={asset.lastFourDigitsOfAccount}
          onChange={(value) => setBankAccountAsset({ ...asset, lastFourDigitsOfAccount: value })}
          validationErrorName='lastFourDigitsOfAccount'
          validationErrors={validationErrors}
        />
      </div>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>In whose name is this held?</p>
        </div>
        <div className='mt-2 md:mt-4 md:flex md:flex-wrap md:space-x-4'>
          <RadioButton
            id='bank-account-held-me'
            name='bank-account-held'
            title='Me'
            value='me'
            checked={asset.nameAccountHeldBy === 'me'}
            onChange={(value) => setBankAccountAsset({ ...asset, nameAccountHeldBy: value })}
          />
          <RadioButton
            id='bank-account-held-ex'
            name='bank-account-held'
            title='My Ex'
            value='ex'
            checked={asset.nameAccountHeldBy === 'ex'}
            onChange={(value) => setBankAccountAsset({ ...asset, nameAccountHeldBy: value })}
          />
          <RadioButton
            id='bank-account-held-both'
            name='bank-account-held'
            title='Both Me and My Ex'
            value='both'
            checked={asset.nameAccountHeldBy === 'both'}
            onChange={(value) => setBankAccountAsset({ ...asset, nameAccountHeldBy: value })}
          />
          <RadioButton
            id='bank-account-held-company'
            name='bank-account-held'
            title='Company'
            value='company'
            checked={asset.nameAccountHeldBy === 'company'}
            onChange={(value) => setBankAccountAsset({ ...asset, nameAccountHeldBy: value })}
          />
        </div>
        <ValidationMessages
          name='nameAccountHeldBy'
          validationErrors={validationErrors}
        />
      </fieldset>

      {asset.nameAccountHeldBy === 'company' && (
        <p className='font-medium text-sm'>
          Please go back and add this item as a company asset.
        </p>
      )}

      {asset.nameAccountHeldBy === 'both' && (
        <>
          <fieldset className='mt-6'>
            <div>
              <p className='text-sm text-gray-900'>
                How are the funds in this account being divided?
              </p>
            </div>
            <div className='mt-2 md:mt-4 md:flex md:flex-wrap md:space-x-4'>
              <RadioButton
                id='funds-divided-me'
                name='funds-divided'
                title='All to Me'
                value='me'
                checked={asset.howFundsDivided === 'me'}
                onChange={(value) => setBankAccountAsset({ ...asset, howFundsDivided: value })}
              />
              <RadioButton
                id='funds-divided-ex'
                name='funds-divided'
                title='All to My Ex'
                value='ex'
                checked={asset.howFundsDivided === 'ex'}
                onChange={(value) => setBankAccountAsset({ ...asset, howFundsDivided: value })}
              />
              <RadioButton
                id='funds-divided-closed'
                name='funds-divided'
                title='Closed and Divided Between Me and My Ex'
                value='closed'
                checked={asset.howFundsDivided === 'closed'}
                onChange={(value) => setBankAccountAsset({ ...asset, howFundsDivided: value })}
              />
            </div>
            <ValidationMessages
              name='howFundsDivided'
              validationErrors={validationErrors}
            />
          </fieldset>

          <FundsDivided
            display={asset.howFundsDivided === 'closed'}
            name='how-funds-divided'
            title='In what proportion are you dividing the funds?'
            splitTypeValue={asset.howFundsDividedSplit}
            setSplitType={(value) => setBankAccountAsset(
              { ...asset, howFundsDividedSplit: value }
            )}
            percentageSplitValue={asset.fundsDividedPercentageSplit}
            setPercentageSplitValue={(value) => setBankAccountAsset(
              { ...asset, fundsDividedPercentageSplit: value }
            )}
            fixedSumValue={asset.fundsDividedFixedSum}
            setFixedSumValue={(value) => setBankAccountAsset(
              { ...asset, fundsDividedFixedSum: value }
            )}
            splitTypeValidationName='howFundsDividedSplit'
            percentageSplitValidationName='fundsDividedPercentageSplit'
            fixedSumValidationName='fundsDividedFixedSum'
            validationErrors={validationErrors}
          />
        </>
      )}
    </>
  )
}

export default BankAccount
