import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import logo from '../images/logo.png'
import { XCircleIcon } from '@heroicons/react/solid'

import { Button, PasswordInput } from '../components'
import { useCurrentUserLazyQuery } from '../graphql/queries'
import { useUpdatePasswordMutation } from '../graphql/mutations'
import { ValidationError } from '../models'

const ResetPasswordRoute = (): JSX.Element => {
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [invalidToken, setInvalidToken] = useState(false)
  const [success, setSuccess] = useState(false)
  const [getCurrentUser] = useCurrentUserLazyQuery()
  const [updatePassword, { loading }] = useUpdatePasswordMutation()
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const [searchParams] = useSearchParams();

  useEffect(() => {
    checkIfLoggedIn().catch((e) => console.error(e))
  }, [])

  const checkIfLoggedIn = async (): Promise<void> => {
    const result = await getCurrentUser()

    if (result.data == null) return

    if (result.data.currentUser != null) {
      navigate('/login')
    }
  }

  const login = async (): Promise<void> => {
    setInvalidToken(false)
    setSuccess(false)
    setValidationErrors([])

    const result = await updatePassword({
      variables: {
        input: {
          token: searchParams.get('token') || '',
          password,
          passwordConfirmation
        }
      }
    })

    if (result.data == null) return

    if (result.data.updatePassword.success === true) {
      setSuccess(true)
      setPassword('')
      setPasswordConfirmation('')
    } else if (result.data.updatePassword.tokenInvalid === true) {
      setInvalidToken(true)
    } else {
      setValidationErrors(result.data.updatePassword.validationErrors)
    }
  }

  return (
    <div className='h-screen flex items-center'>
      <div className='flex flex-col items-center max-w-2xl mx-auto'>
        <img
          className='w-3/4'
          src={logo}
          alt='Legal Aspirations'
        />
        <div className='w-full'>
          <div className='py-12 px-4 sm:px-6 lg:py-16 lg:px-8 bg-white rounded space-y-8'>
            <h2 className='text-2xl font-extrabold tracking-tight text-gray-900 mb-3 text-center'>
              Reset Password
            </h2>
            <PasswordInput
              title='Password'
              name='password'
              value={password}
              onChange={setPassword}
              validationErrors={validationErrors}
            />
            <PasswordInput
              title='Password Confirmation'
              name='passwordConfirmation'
              value={passwordConfirmation}
              onChange={setPasswordConfirmation}
              validationErrors={validationErrors}
            />
            {invalidToken && (
              <div className='rounded-md bg-red-50 p-4'>
                <div className='flex'>
                  <div className='flex-shrink-0'>
                    <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
                  </div>
                  <div className='ml-3'>
                    <h3 className='text-sm font-medium text-red-800'>
                      Your password reset request has expired. Please request again by
                      clicking&nbsp;
                      <Link
                        to='/forgot-password'
                        className='font-bold'
                      >
                        here
                      </Link>
                      .
                    </h3>
                  </div>
                </div>
              </div>
            )}
            {success && (
              <div className='rounded-md bg-green-50 p-4'>
                <div className='flex'>
                  <div className='flex-shrink-0'>
                    <XCircleIcon className='h-5 w-5 text-green-400' aria-hidden='true' />
                  </div>
                  <div className='ml-3'>
                    <h3 className='text-sm font-medium text-green-800'>
                      Your password has been reset. Please login with your new password&nbsp;
                      <Link
                        to='/login'
                        className='font-bold'
                      >
                        here
                      </Link>
                      .
                    </h3>
                  </div>
                </div>
              </div>
            )}
            <Button
              className='text-lg w-full'
              onClick={login}
              loading={loading}
            >
              Reset Password
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordRoute
