import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const savePayoutQuery = gql`
  mutation SavePayoutQuery($input: SavePayoutInput!) {
    savePayout(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
      validationErrors {
        name
        messages
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default savePayoutQuery
