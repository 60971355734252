import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const deleteSuperannuationQuery = gql`
  mutation DeleteSuperannuationQuery($input: DeleteSuperannuationInput!) {
    deleteSuperannuation(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default deleteSuperannuationQuery
