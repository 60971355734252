import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { getPreview } from '../models/consent-order'
import { useConsentOrder } from './consent-order-route'
import { useAdminQuery } from '../graphql/queries'

const PreviewRoute = (): JSX.Element => {
  const [preview, setPreview] = useState('')
  const { consentOrder } = useConsentOrder()
  const navigate = useNavigate()
  const { data } = useAdminQuery()

  useEffect(() => {
    (async () => {
      const preview = await getPreview(consentOrder.slug)
      setPreview(preview)
    })()
      .catch(() => console.error('Error loading Consent Order Preview Route'))
  }, [])

  useEffect(() => {
    if (data?.admin === false) {
      navigate(`/consent-orders/${consentOrder.slug}/personal-information`)
    }
  }, [data])

  return (
    <div className='rounded-lg bg-white p-4 space-y-8'>
      <div>
        <div>
          <h3 className='text-lg leading-6 font-medium text-gray-900'>
            Preview
          </h3>
          <p className='mt-1 text-sm text-gray-500'>
            Preview of your consent order.
          </p>
        </div>
      </div>

      <hr />
      <div
        className='generated-consent-order'
        dangerouslySetInnerHTML={{ __html: preview }}
      />
    </div>
  )
}

export default PreviewRoute
