import React from 'react'

import { TextInput } from '.'
import { ValidationError } from '../models'

interface PercentageSplitProps {
  display: boolean
  title: string
  name: string
  value: string
  setValue: (value: string) => void
  validationName: string
  validationErrors: ValidationError[]
}

const PercentageSplit = ({
  title, name, value, display, setValue, validationErrors, validationName
}: PercentageSplitProps): JSX.Element => {
  const exPercentageSplit = (): string => {
    if (value === '') {
      return ''
    }

    const exValue = parseInt(value)

    if (isNaN(exValue) || exValue < 1 || exValue > 100) {
      return ''
    }

    return `${(100 - exValue).toString()}%`
  }

  if (!display) {
    return (<></>)
  }

  return (
    <>
      {title !== '' && (
        <div>
          <p className='text-sm text-gray-900'>{title}</p>
        </div>
      )}
      <div className='flex space-x-8 items-center'>
        <label className='pt-2 text-sm font-medium text-gray-700'>Me</label>
        <div className='flex'>
          <TextInput
            displayLabel={false}
            name={name}
            value={value}
            onChange={setValue}
            validationErrorName={validationName}
            validationErrors={validationErrors}
          />
          <label className='pt-2 ml-1 text-sm font-medium text-gray-700'>%</label>
        </div>
      </div>
      <div className='space-x-4'>
        <label className='text-sm font-medium text-gray-700'>My Ex</label>
        <label className='text-sm font-medium text-gray-700'>{exPercentageSplit()}</label>
      </div>
    </>
  )
}

PercentageSplit.defaultProps = {
  title: '',
  validationName: 'saleProceedsPercentageSplit',
  validationErrors: []
}

export default PercentageSplit
