import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { Editor } from '@tinymce/tinymce-react'

import { Button, Loading } from '../components'
import {
  getConsentOrderTemplate, updateConsentOrderTemplate
} from '../models/consent-order-template'
import { useAdminQuery } from '../graphql/queries'

const EditConsentOrderTemplateRoute = (): JSX.Element => {
  const [saving, setSaving] = useState(false)
  const [template, setTemplate] = useState('')
  const { data: { admin } = { admin: false }, loading } = useAdminQuery()

  useEffect(() => {
    getConsentOrderTemplate().then((template) => {
      setTemplate(template.template)
    }).catch(console.error)
  }, [])

  const templateChanged = (content: string): void => {
    setTemplate(content)
  }

  const save = async (): Promise<void> => {
    setSaving(true)
    await updateConsentOrderTemplate(template)
    setSaving(false)
  }

  if (loading) {
    return <Loading />
  }

  if (!admin) {
    return <Navigate to='/' />
  }

  return (
    <div className='rounded-lg bg-white p-4'>
      <form className='space-y-8'>
        <div>
          <div>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              Edit Consent Order Template
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Make changes to the Consent Order Template which is used to generate Consent Orders
            </p>
          </div>
        </div>

        <hr />

        <fieldset className='mt-6'>
          <Editor
            apiKey='wpks94pqtwwnnb4ymkdpata3z6xi9znf07835hwhcvwxsx7x'
            init={{
              height: '100%',
              toolbar: 'undo redo | bold italic underline strikethrough | fontselect ' +
                'fontsizeselect formatselect | alignleft aligncenter alignright ' +
                'alignjustify | outdent indent | numlist bullist | ' +
                'forecolor backcolor casechange permanentpen formatpainter ' +
                'removeformat | pagebreak | charmap emoticons | code',
              plugins: 'table charmap hr pagebreak lists help code',
              menubar: 'edit insert format help'
            }}
            onEditorChange={templateChanged}
            value={template}
          />
        </fieldset>

        <div>
          <hr className='pt-5' />
          <div className='flex justify-end'>
            <Button
              loading={saving}
              onClick={save}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditConsentOrderTemplateRoute
