import React from 'react'

import { Input, RadioButton, TextInput, TransferPeriod, ValidationMessages } from '../components'
import { SelfManagedSuperannuation, ValidationError } from '../models'

interface SelfManagedSuperProps {
  fund: SelfManagedSuperannuation
  setFund: (fund: SelfManagedSuperannuation) => void
  validationErrors: ValidationError[]
}

const SelfManagedSuper = ({
  fund, setFund, validationErrors
}: SelfManagedSuperProps): JSX.Element => {
  return (
    <>
      <Input
        title='Name of Self-Managed Superannuation Fund'
        name='self-managed-fund-name'
        type='text'
        value={fund.nameOfSelfManagedFund}
        onChange={(value) => setFund({ ...fund, nameOfSelfManagedFund: value })}
        validationErrorName='nameOfSelfManagedFund'
        validationErrors={validationErrors}
      />

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>
            Who are the members of the Self Managed Superannuation Fund?
          </p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='fund-members-me'
            name='fund-members'
            title='Me'
            value='me'
            checked={fund.members === 'me'}
            onChange={(value) => setFund({ ...fund, members: value })}
          />
          <RadioButton
            id='fund-members-ex'
            name='fund-members'
            title='My Ex'
            value='ex'
            checked={fund.members === 'ex'}
            onChange={(value) => setFund({ ...fund, members: value })}
          />
          <RadioButton
            id='fund-members-both'
            name='fund-members'
            title='Both Me and My Ex'
            value='both'
            checked={fund.members === 'both'}
            onChange={(value) => setFund({ ...fund, members: value })}
          />
        </div>
        <ValidationMessages
          name='members'
          validationErrors={validationErrors}
        />
      </fieldset>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>
            Who is keeping the Self-Managed Superannuation Fund?
          </p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='keeping-fund-me'
            name='keeping-fund'
            title='Me'
            value='me'
            checked={fund.whoKeepingFund === 'me'}
            onChange={(value) => setFund({ ...fund, whoKeepingFund: value })}
          />
          <RadioButton
            id='keeping-fund-ex'
            name='keeping-fund'
            title='My Ex'
            value='ex'
            checked={fund.whoKeepingFund === 'ex'}
            onChange={(value) => setFund({ ...fund, whoKeepingFund: value })}
          />
        </div>
        <ValidationMessages
          name='whoKeepingFund'
          validationErrors={validationErrors}
        />
      </fieldset>

      {fund.members !== fund.whoKeepingFund && fund.whoKeepingFund !== '' && (
        <>
          <fieldset className='mt-6'>
            <div>
              <p className='text-sm text-gray-900'>
                Will there be a payment to the exiting member?
              </p>
            </div>
            <div className='mt-4 flex flex-wrap space-x-4'>
              <RadioButton
                id='payment-to-member-yes'
                name='payment-to-member'
                title='Yes'
                value='yes'
                checked={fund.paymentToExitingMember === 'yes'}
                onChange={(value) => setFund({ ...fund, paymentToExitingMember: value })}
              />
              <RadioButton
                id='payment-to-member-no'
                name='payment-to-member'
                title='No'
                value='no'
                checked={fund.paymentToExitingMember === 'no'}
                onChange={(value) => setFund({ ...fund, paymentToExitingMember: value })}
              />
            </div>
            <ValidationMessages
              name='paymentToExitingMember'
              validationErrors={validationErrors}
            />
          </fieldset>

          <TransferPeriod
            display
            name='transfer-period'
            title='What timeframe do you need to change the SMSF?'
            transferPeriodQuantity={fund.transferPeriodQuantity}
            setTransferPeriodQuantity={(value) => setFund(
              { ...fund, transferPeriodQuantity: value }
            )}
            transferPeriod={fund.transferPeriod}
            setTransferPeriod={(value) => setFund({ ...fund, transferPeriod: value })}
            validationErrors={validationErrors}
          />

          {fund.paymentToExitingMember === 'yes' && (
            <>
              <div>
                <label className='pt-2 text-sm'>
                  What is the current member balance of the exiting member?
                </label>
                <div className='flex'>
                  <label className='pt-2 mr-1'>$</label>
                  <TextInput
                    displayLabel={false}
                    name='amount-paid-to-member'
                    value={fund.exitingMemberBalance}
                    onChange={(value) => setFund({ ...fund, exitingMemberBalance: value })}
                    numberic
                    validationErrorName='exitingMemberBalance'
                    validationErrors={validationErrors}
                  />
                </div>
              </div>

              <div>
                <label className='pt-2 text-sm'>
                  What is the current member balance of the member keeping the SMSF?
                </label>
                <div className='flex'>
                  <label className='pt-2 mr-1'>$</label>
                  <TextInput
                    displayLabel={false}
                    name='amount-paid-to-member'
                    value={fund.keepingMemberBalance}
                    onChange={(value) => setFund({ ...fund, keepingMemberBalance: value })}
                    numberic
                    validationErrorName='keepingMemberBalance'
                    validationErrors={validationErrors}
                  />
                </div>
              </div>

              <fieldset className='mt-6'>
                <div>
                  <p className='text-sm text-gray-900'>
                    What is the exiting member taking from the self-managed super fund?
                  </p>
                </div>
                <div className='mt-4'>
                  <RadioButton
                    id='exiting-member-keeping-balance-only'
                    name='exiting-member-keeping'
                    title="Only the exiting member's current membership balance in the SMSF"
                    value='balance-only'
                    checked={fund.exitingMemberKeeping === 'balance-only'}
                    onChange={(value) => setFund({ ...fund, exitingMemberKeeping: value })}
                  />
                </div>
                <div className='mt-2'>
                  <RadioButton
                    id='exiting-member-keeping-balance-plus-portion'
                    name='exiting-member-keeping'
                    title="A combination of the exiting member's current membership balance in the
                           SMSF plus a portion of the other member's balance"
                    value='balance-plus-portion'
                    checked={fund.exitingMemberKeeping === 'balance-plus-portion'}
                    onChange={(value) => setFund({ ...fund, exitingMemberKeeping: value })}
                  />
                </div>
                <ValidationMessages
                  name='exitingMemberKeeping'
                  validationErrors={validationErrors}
                />
              </fieldset>

              {fund.exitingMemberKeeping === 'balance-plus-portion' && (
                <div>
                  <label className='pt-2 text-sm'>
                    How much of the other member's balance (over and above the exiting member's
                    current member balance) is being transfered?
                  </label>
                  <div className='flex'>
                    <label className='pt-2 mr-1'>$</label>
                    <TextInput
                      displayLabel={false}
                      name='exiting-member-additional-portion'
                      value={fund.exitingMemberAdditionalPortion}
                      onChange={(value) => setFund(
                        { ...fund, exitingMemberAdditionalPortion: value }
                      )}
                      numberic
                      validationErrorName='exitingMemberAdditionalPortion'
                      validationErrors={validationErrors}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>
            Who are the trustees of the Self-Managed Superannuation Fund?
          </p>
        </div>
        <div className='mt-4 md:flex md:flex-wrap md:space-x-4'>
          <RadioButton
            id='fund-trustees-both'
            name='fund-trustees'
            title='Both Me and My Ex'
            value='both'
            checked={fund.fundTrustees === 'both'}
            onChange={(value) => setFund({ ...fund, fundTrustees: value })}
          />
          <RadioButton
            id='fund-trustees-corporate-trust'
            name='fund-trustees'
            title='Corporate Trustee (i.e. a company)'
            value='corporate-trust'
            checked={fund.fundTrustees === 'corporate-trust'}
            onChange={(value) => setFund({ ...fund, fundTrustees: value })}
          />
        </div>
        <ValidationMessages
          name='fundTrustees'
          validationErrors={validationErrors}
        />
      </fieldset>

      {fund.fundTrustees === 'corporate-trust' && (
        <Input
          title='What is the name of the Corporate Trustee?'
          name='corporate-trustee-name'
          type='text'
          value={fund.nameOfCorporateTrust}
          onChange={(value) => setFund({ ...fund, nameOfCorporateTrust: value })}
          validationErrorName='nameOfCorporateTrust'
          validationErrors={validationErrors}
        />
      )}
    </>
  )
}

export default SelfManagedSuper
