import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'

import apolloClient from './apollo-client'
import {
  ConsentOrderRoute, AddAssetRoute, AddLiabilityRoute, AssetsRoute,
  LiabilitiesRoute, PersonalInformationRoute, PreviewRoute,
  EditConsentOrderTemplateRoute, StartConsentOrderRoute, PayoutRoute,
  SuperannuationsRoute, AddSuperannuationRoute, DisclaimerRoute, PurchaseRoute,
  ViewRoute, EditAssetRoute, EditLiabilityRoute, EditSuperannuationRoute,
  LoginRoute, ResetPasswordRoute, ForgotPasswordRoute, ConsentOrdersListRoute
} from './routes'

const AppRoot = (): JSX.Element => {
  const gtmParams = { id: 'GTM-PKS6K8X' }

  return (
    <GTMProvider state={gtmParams}>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<StartConsentOrderRoute />} />
            <Route path='/login' element={<LoginRoute />} />
            <Route path='/reset-password' element={<ResetPasswordRoute />} />
            <Route path='/forgot-password' element={<ForgotPasswordRoute />} />
            <Route path='/consent-orders/:slug' element={<ConsentOrderRoute />}>
              <Route
                path='personal-information'
                element={<PersonalInformationRoute />}
              />
              <Route
                path='assets'
                element={<AssetsRoute />}
              >
                <Route
                  path='add'
                  element={<AddAssetRoute />}
                />
                <Route
                  path=':assetId/edit'
                  element={<EditAssetRoute />}
                />
              </Route>
              <Route
                path='liabilities'
                element={<LiabilitiesRoute />}
              >
                <Route
                  path='add'
                  element={<AddLiabilityRoute />}
                />
                <Route
                  path=':liabilityId/edit'
                  element={<EditLiabilityRoute />}
                />
              </Route>
              <Route
                path='payout'
                element={<PayoutRoute />}
              />
              <Route
                path='superannuation'
                element={<SuperannuationsRoute />}
              >
                <Route
                  path='add'
                  element={<AddSuperannuationRoute />}
                />
                <Route
                  path=':fundId/edit'
                  element={<EditSuperannuationRoute />}
                />
              </Route>
              <Route
                path='disclaimer'
                element={<DisclaimerRoute />}
              />
              <Route
                path='purchase'
                element={<PurchaseRoute />}
              />
              <Route
                path='preview'
                element={<PreviewRoute />}
              />
              <Route
                path='view'
                element={<ViewRoute />}
              />
            </Route>

            <Route
              path='/consent-orders-list'
              element={<ConsentOrdersListRoute />}
            />

            <Route
              path='/edit-consent-order-template'
              element={<EditConsentOrderTemplateRoute />}
            />
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    </GTMProvider>
  )
}

export default AppRoot
