import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { DateTime } from 'luxon'

import { Loading, Button, FormatDate } from '../components'
import { useAdminQuery, useConsentOrdersListQuery } from '../graphql/queries'

const ConsentOrdersListRoute = (): JSX.Element => {
  const params = new URLSearchParams(useLocation().search)
  const page = params.get('page')
  const currentPage = page != null ? parseInt(page) : 0
  const { data: { admin } = { admin: false }, loading: adminCheckLoading } = useAdminQuery()
  const {
    data: { consentOrders } = { consentOrders: [] }, loading: consentOrdersLoading
  } = useConsentOrdersListQuery({ variables: { limit: 50, page: currentPage } })

  if (adminCheckLoading) {
    return <Loading />
  }

  if (!admin) {
    return <Navigate to='/' />
  }

  return (
    <main className='py-10'>
      <div className='px-4 sm:px-6 lg:px-8'>
        <div className='flex justify-between'>
          <h1 className='text-2xl font-semibold text-gray-900'>
            Consent Orders
          </h1>
        </div>
        <hr className='my-4 border-gray-300' />
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className='bg-gray-50'>
            <tr>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Name
              </th>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Email Address
              </th>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Address
              </th>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                When Created
              </th>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Purchased At
              </th>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Agreed to Disclaimer
              </th>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Completed Personal Information
              </th>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Completed Assets
              </th>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Completed Liabilities
              </th>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Completed Payouts
              </th>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Consent Order Link
              </th>
            </tr>
          </thead>
          <tbody>
            {consentOrders.map((consentOrder, index) => (
              <tr
                key={consentOrder.id}
                className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
              >
                <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                  {consentOrder.firstName} {consentOrder.surname}
                </td>
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                  <a
                    href={`mailto:${consentOrder.email}`}
                    className='text-brand hover:text-brand-secondary-lite font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-secondary'
                  >
                    {consentOrder.email}
                  </a>
                </td>
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                  {
                    consentOrder.addressPlaceId
                      ? `${consentOrder.addressStreet}, ${consentOrder.addressSuburb} ${consentOrder.addressState}, ${consentOrder.addressCountry}`
                      : ''
                  }
                </td>
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                  <FormatDate datetime={DateTime.fromISO(consentOrder.createdAt)} />
                </td>
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                  <FormatDate
                    datetime={consentOrder.purchaseConfirmedAt != null ? DateTime.fromISO(consentOrder.purchaseConfirmedAt) : null}
                  />
                </td>
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                  {consentOrder.completedPersonalInformation ? 'Yes' : 'No'}
                </td>
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                  {consentOrder.completedAssets ? 'Yes' : 'No'}
                </td>
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                  {consentOrder.completedLiabilities ? 'Yes' : 'No'}
                </td>
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                  {consentOrder.completedPayouts ? 'Yes' : 'No'}
                </td>
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                  {consentOrder.agreedToDisclaimer ? 'Yes' : 'No'}
                </td>
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                  <Button
                    to={`/consent-orders/${consentOrder.slug}/personal-information`}
                    type='link'
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {consentOrdersLoading &&
          <div className='flex justify-center my-6'>
            <Loading className='h-14 w-14 text-brand-secondary' />
          </div>
        }
        <div className='flex justify-between mt-4'>
          {currentPage !== 0 && (
            <Button to={`/consent-orders-list?page=${currentPage - 1}`}>
              Previous
            </Button>
          )}
          {currentPage === 0 && (
            <div />
          )}
          <Button to={`/consent-orders-list?page=${currentPage + 1}`}>
            Next
          </Button>
        </div>
      </div>
    </main >
  )
}

export default ConsentOrdersListRoute
