import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const addLoanLiabilityQuery = gql`
  mutation AddLoanLiabilityQuery($input: AddLoanLiabilityInput!) {
    addLoanLiability(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
      validationErrors {
        name
        messages
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default addLoanLiabilityQuery
