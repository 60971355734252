import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const deleteAssetQuery = gql`
  mutation DeleteAssetQuery($input: DeleteAssetInput!) {
    deleteAsset(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default deleteAssetQuery
