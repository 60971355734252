import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const completedAssetsQuery = gql`
  mutation CompletedAssets($input: CompletedAssetsInput!) {
    completedAssets(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default completedAssetsQuery
