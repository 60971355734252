import React, { useState } from 'react'

import { useConsentOrder } from './consent-order-route'

const ViewRoute = (): JSX.Element => {
  const { consentOrder } = useConsentOrder()
  const [loading, setLoading] = useState(true)

  const pdfPath = `${process.env.REACT_APP_BACKEND_HOST}/consent_orders/${consentOrder.slug}.pdf`

  return (
    <>
      {loading && (
        <p>Loading...</p>
      )}
      <iframe
        src={pdfPath}
        className='h-screen w-full'
        onLoad={() => setLoading(false)}
      />
    </>
  )
}

export default ViewRoute