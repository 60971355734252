import { gql, MutationTuple, useMutation } from '@apollo/client'

import { ValidationError } from '../../models'

const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      success
      tokenInvalid
      validationErrors {
        name
        messages
      }
    }
  }
`

interface UpdatePasswordData {
  updatePassword: {
    success: Boolean
    tokenInvalid: Boolean
    validationErrors: ValidationError[]
  }
}

interface UpdatePasswordVariables {
  input: {
    token: string
    password: string
    passwordConfirmation: string
  }
}

const useUpdatePasswordMutation = (): MutationTuple<UpdatePasswordData, UpdatePasswordVariables> => {
  return useMutation(UPDATE_PASSWORD_MUTATION)
}

export default useUpdatePasswordMutation
