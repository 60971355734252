import React from 'react'

import { Input, RadioButton, TransferPeriod, ValidationMessages } from '../components'
import { CreditCardLiability, ValidationError } from '../models'

interface CreditCardProps {
  liability: CreditCardLiability
  setCreditCardLiability: (liability: CreditCardLiability) => void
  validationErrors: ValidationError[]
}

const CreditCard = ({
  liability, setCreditCardLiability, validationErrors
}: CreditCardProps): JSX.Element => {
  return (
    <>
      <Input
        title='Name of Bank, Financial Institution or Lender'
        name='credit-card-lender-name'
        type='text'
        value={liability.nameOfBankOrFinancialInstitution}
        onChange={(value) => setCreditCardLiability(
          { ...liability, nameOfBankOrFinancialInstitution: value }
        )}
        validationErrorName='nameOfBankOrFinancialInstitution'
        validationErrors={validationErrors}
      />

      <Input
        title='What are the last four digits of this account?'
        name='credit-card-last-four-digits'
        type='text'
        value={liability.lastFourDigitsOfAccount}
        onChange={(value) => setCreditCardLiability(
          { ...liability, lastFourDigitsOfAccount: value }
        )}
        validationErrorName='lastFourDigitsOfAccount'
        validationErrors={validationErrors}
      />

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>In whose name is this held?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='credit-card-held-me'
            name='credit-card-held'
            title='Me'
            value='me'
            checked={liability.nameAccountHeldBy === 'me'}
            onChange={(value) => setCreditCardLiability({ ...liability, nameAccountHeldBy: value })}
          />
          <RadioButton
            id='credit-card-held-ex'
            name='credit-card-held'
            title='My Ex'
            value='ex'
            checked={liability.nameAccountHeldBy === 'ex'}
            onChange={(value) => setCreditCardLiability({ ...liability, nameAccountHeldBy: value })}
          />
        </div>
        <ValidationMessages
          name='nameAccountHeldBy'
          validationErrors={validationErrors}
        />
      </fieldset>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>Who will be responsible for this liability?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='credit-card-responsible-me'
            name='credit-card-responsible'
            title='Me'
            value='me'
            checked={liability.whoIsKeepingCreditCard === 'me'}
            onChange={(value) => setCreditCardLiability(
              { ...liability, whoIsKeepingCreditCard: value }
            )}
          />
          <RadioButton
            id='credit-card-responsible-ex'
            name='credit-card-responsible'
            title='My Ex'
            value='ex'
            checked={liability.whoIsKeepingCreditCard === 'ex'}
            onChange={(value) => setCreditCardLiability(
              { ...liability, whoIsKeepingCreditCard: value }
            )}
          />
        </div>
        <ValidationMessages
          name='whoIsKeepingCreditCard'
          validationErrors={validationErrors}
        />
      </fieldset>

      <TransferPeriod
        display={
          liability.nameAccountHeldBy !== liability.whoIsKeepingCreditCard &&
          liability.whoIsKeepingCreditCard !== ''
        }
        name='credit-card-transfer'
        title='What timeframe do you need to do the refinance?'
        transferPeriodQuantity={liability.transferPeriodQuantity}
        setTransferPeriodQuantity={(value) => setCreditCardLiability(
          { ...liability, transferPeriodQuantity: value }
        )}
        transferPeriod={liability.transferPeriod}
        setTransferPeriod={(value) => setCreditCardLiability(
          { ...liability, transferPeriod: value }
        )}
        validationErrors={validationErrors}
      />
    </>
  )
}

export default CreditCard
