import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, TextInput, RadioButton, CreditCard, Other, Mortgage, Loan } from '../components'
import CreditCardLiability from '../models/credit-card-liability'
import OtherLiability from '../models/other-liability'
import MortgageLiability from '../models/mortgage-liability'
import LoanLiability from '../models/loan-liability'
import { addLiability } from '../models/consent-order'
import { useConsentOrder } from './consent-order-route'
import { ValidationError } from '../models'

const AddLiabilityRoute = (): JSX.Element => {
  const navigate = useNavigate()
  const { consentOrder, setConsentOrder } = useConsentOrder()
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [saving, setSaving] = useState(false)
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])

  const [mortgageLiability, setMortgageLiability] = useState<MortgageLiability>({
    nameOfBankOrFinancialInstitution: '',
    lastFourDigitsOfAccount: '',
    nameAccountHeldBy: '',
    assetId: null,
    paymentsPendingSettlementResponsibility: '',
    paymentsPendingSettlementSplit: ''
  })
  const [loanLiability, setLoanLiability] = useState<LoanLiability>({
    nameOfBankOrFinancialInstitution: '',
    lastFourDigitsOfAccount: '',
    nameAccountHeldBy: '',
    isAttachedToAnAsset: '',
    whoIsResponsibleForThisLoan: '',
    transferPeriodQuantity: '30',
    transferPeriod: 'days',
    paymentsPendingSettlementResponsibility: '',
    paymentsPendingSettlementSplit: '',
    assetId: ''
  })
  const [creditCardLiability, setCreditCardLiability] = useState<CreditCardLiability>({
    nameOfBankOrFinancialInstitution: '',
    lastFourDigitsOfAccount: '',
    nameAccountHeldBy: '',
    whoIsKeepingCreditCard: '',
    transferPeriodQuantity: '30',
    transferPeriod: 'days'
  })
  const [otherLiability, setOtherLiability] = useState<OtherLiability>({
    nameOfLenderOrCreditor: '',
    description: '',
    nameLiabilityHeld: '',
    whoIsResponsibleForThisLiability: '',
    isAttachedToAnAsset: '',
    estimatedAmount: '',
    assetId: ''
  })

  const save = async (): Promise<void> => {
    setSaving(true)
    setValidationErrors([])

    let result = null
    if (type === 'mortgage') {
      result = await addLiability(consentOrder, name, type, mortgageLiability)
    }

    if (type === 'credit-card') {
      result = await addLiability(consentOrder, name, type, creditCardLiability)
    }

    if (type === 'loan') {
      result = await addLiability(consentOrder, name, type, loanLiability)
    }

    if (type === 'other') {
      result = await addLiability(consentOrder, name, type, otherLiability)
    }

    setSaving(false)

    if (result == null) {
      return
    }

    if (result.validationErrors.length === 0) {
      setConsentOrder(result.consentOrder)
      navigate(`/consent-orders/${consentOrder.slug}/liabilities`)
    } else {
      setValidationErrors(result.validationErrors)
    }
  }

  return (
    <div
      className='fixed z-10 inset-0 overflow-y-auto'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div
        className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block \
                   sm:p-0'
      >
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
        />
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'
        />

        <div
          className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden \
                     shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl \
                     sm:w-full'
        >
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className='space-y-4'>
              <div className='mt-3 text-center sm:mt-0 sm:text-left'>
                <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-title'>
                  Add Liability
                </h3>
                <hr className='mt-3' />
              </div>

              <form className='space-y-6'>
                <TextInput
                  title='Nickname'
                  name='name'
                  value={name}
                  onChange={setName}
                  validationErrorName='liability.name'
                  validationErrors={validationErrors}
                />

                <fieldset className='mt-6'>
                  <div>
                    <p className='text-sm text-gray-900'>What type of liability is this?</p>
                  </div>
                  <div className='mt-2 md:mt-4 md:flex md:flex-wrap md:space-x-4'>
                    <RadioButton
                      id='mortgage'
                      name='type'
                      title='Mortgage'
                      value='mortgage'
                      checked={type === 'mortgage'}
                      onChange={setType}
                    />

                    <RadioButton
                      id='loan'
                      name='type'
                      title='Loan'
                      value='loan'
                      checked={type === 'loan'}
                      onChange={setType}
                    />

                    <RadioButton
                      id='credit-card'
                      name='type'
                      title='Credit Card'
                      value='credit-card'
                      checked={type === 'credit-card'}
                      onChange={setType}
                    />

                    <RadioButton
                      id='other'
                      name='type'
                      title='Other Liability'
                      value='other'
                      checked={type === 'other'}
                      onChange={setType}
                    />
                  </div>
                </fieldset>

                {type === 'mortgage' && (
                  <Mortgage
                    consentOrder={consentOrder}
                    liability={mortgageLiability}
                    setMortgageLiability={setMortgageLiability}
                    validationErrors={validationErrors}
                  />
                )}
                {type === 'loan' && (
                  <Loan
                    consentOrder={consentOrder}
                    liability={loanLiability}
                    setLoanLiability={setLoanLiability}
                    validationErrors={validationErrors}
                  />
                )}
                {type === 'credit-card' && (
                  <CreditCard
                    liability={creditCardLiability}
                    setCreditCardLiability={setCreditCardLiability}
                    validationErrors={validationErrors}
                  />
                )}
                {type === 'other' && (
                  <Other
                    consentOrder={consentOrder}
                    liability={otherLiability}
                    setOtherLiability={setOtherLiability}
                    validationErrors={validationErrors}
                  />
                )}
              </form>
            </div>
          </div>

          <div className='bg-gray-50 px-4 py-3 sm:px-6 flex justify-end space-x-2'>
            <Button
              type='white'
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              onClick={save}
              loading={saving}
              disabled={type === ''}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddLiabilityRoute
