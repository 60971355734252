import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import logo from '../images/logo.png'
import { XCircleIcon } from '@heroicons/react/solid'
import { DateTime } from 'luxon'

import { Button, TextInput, PasswordInput } from '../components'
import ConsentOrder, { createConsentOrder } from '../models/consent-order'
import { useLoginMutation } from '../graphql/mutations'
import { useCurrentUserLazyQuery, useConsentOrdersLazyQuery } from '../graphql/queries'

const LoginRoute = (): JSX.Element => {
  const navigate = useNavigate()
  const [starting, setStarting] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [invalidLoginDetails, setInvalidLoginDetails] = useState(false)
  const [loginUser, { loading: loggingIn }] = useLoginMutation()
  const [loggedIn, setLoggedIn] = useState(false)
  const [consentOrders, setConsentOrders] = useState<ConsentOrder[]>([])
  const [getCurrentUser] = useCurrentUserLazyQuery()
  const [getConsentOrders] = useConsentOrdersLazyQuery()

  useEffect(() => {
    checkIfLoggedIn().catch((e) => console.error(e))
  }, [])

  useEffect(() => {
    loadConsentOrders().catch((e) => console.error(e))
  }, [loggedIn])

  const checkIfLoggedIn = async (): Promise<void> => {
    const result = await getCurrentUser()

    if (result.data == null) return

    setLoggedIn(result.data.currentUser != null)
  }

  const loadConsentOrders = async (): Promise<void> => {
    const result = await getConsentOrders()

    if (result.data == null) return

    const userConsentOrders = [...result.data.consentOrders].sort(
      (a, b) => DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis()
    )

    if (userConsentOrders.length === 1) {
      const consentOrder = userConsentOrders[0]
      navigate(`/consent-orders/${consentOrder.slug}/personal-information`)
    }

    setConsentOrders(userConsentOrders)
  }

  const startConsentOrder = async (): Promise<void> => {
    setStarting(true)
    const consentOrder = await createConsentOrder()
    setStarting(false)

    navigate(`/consent-orders/${consentOrder.slug}/personal-information`)
  }

  const login = async (): Promise<void> => {
    setInvalidLoginDetails(false)

    const result = await loginUser({
      variables: {
        input: {
          email,
          password
        }
      }
    })

    if (result.data == null) return

    if (result.data.login.success === true) {
      await loadConsentOrders()
      setLoggedIn(true)
    } else {
      setInvalidLoginDetails(true)
    }
  }

  return (
    <div className='h-screen flex items-center'>
      <div className='flex flex-col items-center max-w-2xl mx-auto'>
        <img
          className='w-3/4'
          src={logo}
          alt='Legal Aspirations'
        />
        <div className='w-full'>
          <div className='py-12 px-4 sm:px-6 lg:py-16 lg:px-8 bg-white rounded space-y-8'>
            {!loggedIn && (
              <>
                <h2 className='text-2xl font-extrabold tracking-tight text-gray-900 mb-3 text-center'>
                  Login
                </h2>
                <p className='text-center'>
                  Login and continue a saved consent order
                </p>
                <TextInput
                  title='Email Address'
                  name='email'
                  value={email}
                  onChange={setEmail}
                />
                <PasswordInput
                  title='Password'
                  name='password'
                  value={password}
                  onChange={setPassword}
                />
                <Button type='link' to='/forgot-password' className='text-sm'>
                  Forgot Password?
                </Button>
                {invalidLoginDetails && (
                  <div className='rounded-md bg-red-50 p-4'>
                    <div className='flex'>
                      <div className='flex-shrink-0'>
                        <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
                      </div>
                      <div className='ml-3'>
                        <h3 className='text-sm font-medium text-red-800'>
                          Invalid email address or password, please try again.
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
                <Button
                  className='text-lg w-full'
                  onClick={login}
                  loading={loggingIn}
                >
                  Login
                </Button>
              </>
            )}
            {loggedIn && (
              <div className='max-h-96 overflow-auto'>
                <h2 className='text-2xl font-extrabold tracking-tight text-gray-900 mb-3 text-center'>
                  Continue saved consent order
                </h2>
                <p className='text-center'>
                  Select a saved consent order to continue
                </p>
                <hr className='mt-5' />
                <ul role='list' className='divide-y divide-gray-200'>
                  {consentOrders.map((consentOrder) => {
                    const date = DateTime.fromISO(consentOrder.createdAt)
                    return (
                      <li key={consentOrder.id} className='px-6 py-4'>
                        <div className='flex justify-between'>
                          <div>
                            <label className='font-bold mr-1'>Created:</label>
                            <label>{date.toLocaleString(DateTime.DATETIME_MED)}</label>
                          </div>
                          <a
                            href={`/consent-orders/${consentOrder.slug}/personal-information`}
                            className='font-medium text-brand hover:text-brand-secondary'
                          >
                            Continue
                          </a>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}

            <div className='flex items-center justify-center mb-8'>
              <hr className='border-t border-gray-400 w-1/4 mr-3' />
              <span className='text-center text-xl font-medium'>OR</span>
              <hr className='border-t border-gray-400 w-1/4 ml-3' />
            </div>

            <Button
              loading={starting}
              className='text-lg w-full'
              onClick={startConsentOrder}
            >
              Create a new Consent Order
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginRoute
