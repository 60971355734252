import { gql, MutationTuple, useMutation } from '@apollo/client'

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordQuery($input: ResetPasswordInput!) {
    resetPassword (input: $input) {
      success
    }
  }
`

interface ResetPasswordData {
  resetPassword: {
    success: Boolean
  }
}

interface ResetPasswordVariables {
  input: {
    email: string
  }
}

const useResetPasswordMutation = (): MutationTuple<ResetPasswordData, ResetPasswordVariables> => {
  return useMutation(RESET_PASSWORD_MUTATION)
}

export default useResetPasswordMutation
