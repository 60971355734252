import { gql, QueryResult, QueryTuple, useQuery, useLazyQuery } from '@apollo/client'

const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser
  }
`

interface CurrentUserData {
  currentUser: string | null
}

const useCurrentUserQuery = (): QueryResult<CurrentUserData> => {
  return useQuery(CURRENT_USER_QUERY)
}

const useCurrentUserLazyQuery = (): QueryTuple<CurrentUserData, {}> => {
  return useLazyQuery(CURRENT_USER_QUERY)
}

export default useCurrentUserQuery
export { useCurrentUserLazyQuery }
