import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const deleteLiabilityQuery = gql`
  mutation DeleteLiabilityQuery($input: DeleteLiabilityInput!) {
    deleteLiability(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default deleteLiabilityQuery
