import React from 'react'

import { RadioButton, PercentageSplit, FixedSumSplit, ValidationMessages } from '.'
import { ValidationError } from '../models'

interface FundsDividedProps {
  display: boolean
  name: string
  title: string

  fixedSumSplitAmountLabel: string
  fixedSumSplitBalanceLabel: string

  splitTypeValidationName: string
  percentageSplitValidationName: string
  fixedSumValidationName: string
  validationErrors: ValidationError[]

  splitTypeValue: string
  setSplitType: (value: string) => void

  percentageSplitValue: string
  setPercentageSplitValue: (value: string) => void

  fixedSumValue: string
  setFixedSumValue: (value: string) => void
}

const FundsDivided = ({
  display, splitTypeValue, setSplitType, percentageSplitValue, setPercentageSplitValue,
  fixedSumValue, setFixedSumValue, title, name, fixedSumSplitAmountLabel,
  fixedSumSplitBalanceLabel, validationErrors, splitTypeValidationName,
  percentageSplitValidationName, fixedSumValidationName
}: FundsDividedProps): JSX.Element => {
  if (!display) {
    return (<></>)
  }

  return (
    <>
      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>{title}</p>
        </div>
        <div className='mt-4 md:flex md:flex-wrap md:space-x-4'>
          <RadioButton
            id={`${name}-percentage-split`}
            name={name}
            title='Percentage Split'
            value='percentage'
            checked={splitTypeValue === 'percentage'}
            onChange={setSplitType}
          />
          <RadioButton
            id={`${name}-fixed-sum`}
            name={name}
            title='Fixed Sum To One Party and Balance to the Other'
            value='fixed-sum'
            checked={splitTypeValue === 'fixed-sum'}
            onChange={setSplitType}
          />
        </div>
        <ValidationMessages
          name={splitTypeValidationName}
          validationErrors={validationErrors}
        />
      </fieldset>

      <PercentageSplit
        display={splitTypeValue === 'percentage'}
        name={`${name}-percentage-split`}
        value={percentageSplitValue}
        setValue={setPercentageSplitValue}
        validationName={percentageSplitValidationName}
        validationErrors={validationErrors}
      />

      <FixedSumSplit
        display={splitTypeValue === 'fixed-sum'}
        name={`${name}-fixed-sum-split`}
        value={fixedSumValue}
        setValue={setFixedSumValue}
        fixedSumSplitAmountLabel={fixedSumSplitAmountLabel}
        fixedSumSplitBalanceLabel={fixedSumSplitBalanceLabel}
        validationName={fixedSumValidationName}
        validationErrors={validationErrors}
      />
    </>
  )
}

FundsDivided.defaultProps = {
  splitTypeValidationName: 'saleProceeds',
  percentageSplitValidationName: 'saleProceedsPercentageSplit',
  fixedSumValidationName: 'saleProceedsFixedSum',
  fixedSumSplitAmountLabel: 'Me',
  fixedSumSplitBalanceLabel: 'My Ex',
  validationErrors: []
}

export default FundsDivided
