import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const updateRealEstateAssetQuery = gql`
  mutation UpdateRealEstateAssetQuery($input: UpdateRealEstateAssetInput!) {
    updateRealEstateAsset(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
      validationErrors {
        name
        messages
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default updateRealEstateAssetQuery
