import React from 'react'

import {
  Input, RadioButton, TransferPeriod, FundsDivided, ValidationMessages
} from '../components'
import { MotorVehicleAsset, ValidationError } from '../models'

interface MotorVehicleProps {
  asset: MotorVehicleAsset
  setMotorVehicleAsset: (asset: MotorVehicleAsset) => void
  validationErrors: ValidationError[]
}

const MotorVehicle = ({
  asset, setMotorVehicleAsset, validationErrors
}: MotorVehicleProps): JSX.Element => {
  return (
    <>
      <div className='sm:col-span-4'>
        <Input
          title='Year'
          name='motor-vehicle-year'
          type='text'
          value={asset.motorVehicleYear}
          onChange={(value) => setMotorVehicleAsset({ ...asset, motorVehicleYear: value })}
          validationErrorName='motorVehicleYear'
          validationErrors={validationErrors}
        />
      </div>

      <div className='sm:col-span-4'>
        <Input
          title='Make'
          name='motor-vehicle-make'
          type='text'
          value={asset.motorVehicleMake}
          onChange={(value) => setMotorVehicleAsset({ ...asset, motorVehicleMake: value })}
          validationErrorName='motorVehicleMake'
          validationErrors={validationErrors}
        />
      </div>

      <div className='sm:col-span-4'>
        <Input
          title='Model'
          name='motor-vehicle-model'
          type='text'
          value={asset.motorVehicleModel}
          onChange={(value) => setMotorVehicleAsset({ ...asset, motorVehicleModel: value })}
          validationErrorName='motorVehicleModel'
          validationErrors={validationErrors}
        />
      </div>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>In whose name is this held?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='motor-vehicle-held-me'
            name='motor-vehicle-held'
            title='Me'
            value='me'
            checked={asset.nameMotorVehicleHeldBy === 'me'}
            onChange={(value) => setMotorVehicleAsset({ ...asset, nameMotorVehicleHeldBy: value })}
          />
          <RadioButton
            id='motor-vehicle-held-ex'
            name='motor-vehicle-held'
            title='My Ex'
            value='ex'
            checked={asset.nameMotorVehicleHeldBy === 'ex'}
            onChange={(value) => setMotorVehicleAsset({ ...asset, nameMotorVehicleHeldBy: value })}
          />
        </div>
        <ValidationMessages
          name='nameMotorVehicleHeldBy'
          validationErrors={validationErrors}
        />
      </fieldset>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>Who is keeping this?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='motor-vehicle-kept-by-me'
            name='motor-vehicle-kept-by'
            title='Me'
            value='me'
            checked={asset.whoKeepingMotorVehicle === 'me'}
            onChange={(value) => setMotorVehicleAsset({ ...asset, whoKeepingMotorVehicle: value })}
          />
          <RadioButton
            id='motor-vehicle-kept-by-ex'
            name='motor-vehicle-kept-by'
            title='My Ex'
            value='ex'
            checked={asset.whoKeepingMotorVehicle === 'ex'}
            onChange={(value) => setMotorVehicleAsset({ ...asset, whoKeepingMotorVehicle: value })}
          />
          <RadioButton
            id='motor-vehicle-kept-by-both'
            name='motor-vehicle-kept-by'
            title='Sale'
            value='sale'
            checked={asset.whoKeepingMotorVehicle === 'sale'}
            onChange={(value) => setMotorVehicleAsset({ ...asset, whoKeepingMotorVehicle: value })}
          />
        </div>
        <ValidationMessages
          name='whoKeepingMotorVehicle'
          validationErrors={validationErrors}
        />
      </fieldset>

      <TransferPeriod
        display={
          asset.nameMotorVehicleHeldBy !== asset.whoKeepingMotorVehicle &&
          asset.whoKeepingMotorVehicle !== 'sale' && asset.whoKeepingMotorVehicle !== ''
        }
        name='motor-vehicle-transfer'
        title='What timeframe do you need to do the transfer?'
        transferPeriodQuantity={asset.transferPeriodQuantity}
        setTransferPeriodQuantity={(value) => setMotorVehicleAsset(
          { ...asset, transferPeriodQuantity: value }
        )}
        transferPeriod={asset.transferPeriod}
        setTransferPeriod={(value) => setMotorVehicleAsset({ ...asset, transferPeriod: value })}
        validationErrors={validationErrors}
      />

      <FundsDivided
        display={asset.whoKeepingMotorVehicle === 'sale'}
        name='how-sale-proceeds-divided'
        title='How are the net sale proceeds being divided?'
        splitTypeValue={asset.saleProceeds}
        setSplitType={(value) => setMotorVehicleAsset({ ...asset, saleProceeds: value })}
        percentageSplitValue={asset.saleProceedsPercentageSplit}
        setPercentageSplitValue={(value) => setMotorVehicleAsset(
          { ...asset, saleProceedsPercentageSplit: value }
        )}
        fixedSumValue={asset.saleProceedsFixedSum}
        setFixedSumValue={(value) => setMotorVehicleAsset(
          { ...asset, saleProceedsFixedSum: value }
        )}
        validationErrors={validationErrors}
      />
    </>
  )
}

export default MotorVehicle
