import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const addBusinessAssetQuery = gql`
  mutation AddBusinessAssetQuery($input: AddBusinessAssetInput!) {
    addBusinessAsset(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
      validationErrors {
        name
        messages
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default addBusinessAssetQuery
