import React from 'react'

import { TextInput, SelectInput } from '.'
import { ValidationError } from '../models'

interface TransferPeriodProps {
  display: boolean
  name: string
  title: string
  transferPeriodQuantity: string
  setTransferPeriodQuantity: (value: string) => void
  transferPeriod: string
  setTransferPeriod: (value: string) => void
  validationErrors: ValidationError[]
  disabled: boolean
}

const TransferPeriod = ({
  display, title, transferPeriodQuantity, setTransferPeriodQuantity,
  transferPeriod, setTransferPeriod, validationErrors, disabled
}: TransferPeriodProps): JSX.Element => {
  if (!display) {
    return (<></>)
  }

  return (
    <>
      <div>
        <p className='text-sm text-gray-900'>
          {title}
        </p>
      </div>
      <div className='flex space-x-2'>
        <TextInput
          displayLabel={false}
          name='transferPeriodQuantity'
          value={transferPeriodQuantity}
          onChange={setTransferPeriodQuantity}
          validationErrors={validationErrors}
          disabled={disabled}
        />
        <SelectInput
          displayLabel={false}
          title='Transfer Period'
          name='transferPeriod'
          displayProperty='name'
          idProperty='id'
          value={transferPeriod}
          onChange={setTransferPeriod}
          validationErrors={validationErrors}
          options={[
            { id: 'days', name: 'Days' },
            { id: 'weeks', name: 'Weeks' },
            { id: 'months', name: 'Months' }
          ]}
          disabled={disabled}
        />
      </div>
      <p className='font-medium text-sm'>
        We recommend you select not more than 60 days. A typical timeframe is 30 days.
      </p>
    </>
  )
}

TransferPeriod.defaultProps = {
  validationErrors: [],
  disabled: false
}

export default TransferPeriod
