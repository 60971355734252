import { gql, QueryResult, QueryTuple, useQuery, useLazyQuery } from '@apollo/client'

import { consentOrderFieldsFragment } from '../../queries/fragments'
import { ConsentOrder } from '../../models'

const CONSENT_ORDERS_QUERY = gql`
  query ConsentOrders {
    consentOrders {
      ...consentOrderFields
    }
  }

  ${consentOrderFieldsFragment}
`

interface ConsentOrdersData {
  consentOrders: ConsentOrder[]
}

const useConsentOrdersQuery = (): QueryResult<ConsentOrdersData> => {
  return useQuery(CONSENT_ORDERS_QUERY)
}

const useConsentOrdersLazyQuery = (): QueryTuple<ConsentOrdersData, {}> => {
  return useLazyQuery(CONSENT_ORDERS_QUERY)
}

export default useConsentOrdersQuery
export { useConsentOrdersLazyQuery }
