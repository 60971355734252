import React from 'react'

import {
  Input, RadioButton, TransferPeriod, FundsDivided, ValidationMessages
} from '../components'
import { BoatAsset, ValidationError } from '../models'

interface BoatProps {
  asset: BoatAsset
  setBoatAsset: (asset: BoatAsset) => void
  validationErrors: ValidationError[]
}

const Boat = ({ asset, setBoatAsset, validationErrors }: BoatProps): JSX.Element => {
  return (
    <>
      <div className='sm:col-span-4'>
        <Input
          title='Name of Boat'
          name='boat-name'
          validationErrorName='boatName'
          type='text'
          value={asset.boatName}
          onChange={(value) => setBoatAsset({ ...asset, boatName: value })}
          validationErrors={validationErrors}
        />
      </div>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>In whose name is this held?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='boat-held-me'
            name='boat-held'
            title='Me'
            value='me'
            checked={asset.nameBoatHeldBy === 'me'}
            onChange={(value) => setBoatAsset({ ...asset, nameBoatHeldBy: value })}
          />
          <RadioButton
            id='boat-held-ex'
            name='boat-held'
            title='My Ex'
            value='ex'
            checked={asset.nameBoatHeldBy === 'ex'}
            onChange={(value) => setBoatAsset({ ...asset, nameBoatHeldBy: value })}
          />
          <RadioButton
            id='boat-held-both'
            name='boat-held'
            title='Both Me and My Ex'
            value='both'
            checked={asset.nameBoatHeldBy === 'both'}
            onChange={(value) => setBoatAsset({ ...asset, nameBoatHeldBy: value })}
          />
        </div>
        <ValidationMessages
          name='nameBoatHeldBy'
          validationErrors={validationErrors}
        />
      </fieldset>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>Who is keeping this?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='boat-kept-by-me'
            name='boat-kept-by'
            title='Me'
            value='me'
            checked={asset.whoKeepingBoat === 'me'}
            onChange={(value) => setBoatAsset({ ...asset, whoKeepingBoat: value })}
          />
          <RadioButton
            id='boat-kept-by-ex'
            name='boat-kept-by'
            title='My Ex'
            value='ex'
            checked={asset.whoKeepingBoat === 'ex'}
            onChange={(value) => setBoatAsset({ ...asset, whoKeepingBoat: value })}
          />
          <RadioButton
            id='boat-kept-by-both'
            name='boat-kept-by'
            title='Sale'
            value='sale'
            checked={asset.whoKeepingBoat === 'sale'}
            onChange={(value) => setBoatAsset({ ...asset, whoKeepingBoat: value })}
          />
        </div>
        <ValidationMessages
          name='whoKeepingBoat'
          validationErrors={validationErrors}
        />
      </fieldset>

      <TransferPeriod
        display={asset.nameBoatHeldBy !== asset.whoKeepingBoat &&
          asset.whoKeepingBoat !== 'sale' && asset.whoKeepingBoat !== ''}
        name='boat-transfer'
        title='What timeframe do you need to do the transfer?'
        transferPeriodQuantity={asset.transferPeriodQuantity}
        setTransferPeriodQuantity={(value) => setBoatAsset(
          { ...asset, transferPeriodQuantity: value }
        )}
        transferPeriod={asset.transferPeriod}
        setTransferPeriod={(value) => setBoatAsset({ ...asset, transferPeriod: value })}
        validationErrors={validationErrors}
      />

      <FundsDivided
        display={asset.whoKeepingBoat === 'sale'}
        name='how-sale-proceeds-divided'
        title='How are the net sale proceeds being divided?'
        splitTypeValue={asset.saleProceeds}
        setSplitType={(value) => setBoatAsset({ ...asset, saleProceeds: value })}
        percentageSplitValue={asset.saleProceedsPercentageSplit}
        setPercentageSplitValue={(value) => setBoatAsset(
          { ...asset, saleProceedsPercentageSplit: value }
        )}
        fixedSumValue={asset.saleProceedsFixedSum}
        setFixedSumValue={(value) => setBoatAsset({ ...asset, saleProceedsFixedSum: value })}
        validationErrors={validationErrors}
      />
    </>
  )
}

export default Boat
