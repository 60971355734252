import { gql, MutationTuple, useMutation } from '@apollo/client'

const SAVE_PROGRESS_MUTATION = gql`
  mutation SaveProgressMutation($input: SaveProgressInput!) {
    saveProgress(input: $input) {
      success
    }
  }
`

interface SaveProgressData {
  saveProgress: {
    success: Boolean
  }
}

interface SaveProgressVariables {
  input: {
    slug: string
  }
}

const useSaveProgressMutation = (): MutationTuple<SaveProgressData, SaveProgressVariables> => {
  return useMutation(SAVE_PROGRESS_MUTATION)
}

export default useSaveProgressMutation
