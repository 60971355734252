import React from 'react'

import {
  Input, RadioButton, FundsDivided, CapitalGainsTax, TransferPeriod, ValidationMessages
} from '.'
import { BusinessAsset, ValidationError } from '../models'

interface PartnershipProps {
  asset: BusinessAsset
  setBusinessAsset: (asset: BusinessAsset) => void
  validationErrors: ValidationError[]
}

const Partnership = ({ asset, setBusinessAsset, validationErrors }: PartnershipProps): JSX.Element => {
  return (
    <>
      <div className='sm:col-span-4'>
        <Input
          title='Name of Business'
          name='business-name'
          type='text'
          value={asset.businessName}
          onChange={(value) => setBusinessAsset({ ...asset, businessName: value })}
          validationErrorName='businessName'
          validationErrors={validationErrors}
        />
      </div>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>
            In whose name is this businesss held?
          </p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='business-held-by-me'
            name='business-held-by'
            title='Me'
            value='me'
            checked={asset.nameBusinessHeld === 'me'}
            onChange={(value) => setBusinessAsset({ ...asset, nameBusinessHeld: value })}
          />
          <RadioButton
            id='business-held-by-ex'
            name='business-held-by'
            title='My Ex'
            value='ex'
            checked={asset.nameBusinessHeld === 'ex'}
            onChange={(value) => setBusinessAsset({ ...asset, nameBusinessHeld: value })}
          />
          <RadioButton
            id='business-held-by-both'
            name='business-held-by'
            title='Both Me and My Ex'
            value='both'
            checked={asset.nameBusinessHeld === 'both'}
            onChange={(value) => setBusinessAsset({ ...asset, nameBusinessHeld: value })}
          />
        </div>
        <ValidationMessages
          name='nameBusinessHeld'
          validationErrors={validationErrors}
        />
      </fieldset>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>
            Who is keeping this?
          </p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='business-keeping-me'
            name='business-keeping'
            title='Me'
            value='me'
            checked={asset.whoKeepingBusiness === 'me'}
            onChange={(value) => setBusinessAsset({ ...asset, whoKeepingBusiness: value })}
          />
          <RadioButton
            id='business-keeping-ex'
            name='business-keeping'
            title='My Ex'
            value='ex'
            checked={asset.whoKeepingBusiness === 'ex'}
            onChange={(value) => setBusinessAsset({ ...asset, whoKeepingBusiness: value })}
          />
          <RadioButton
            id='business-keeping-sale'
            name='business-keeping'
            title='Sale'
            value='sale'
            checked={asset.whoKeepingBusiness === 'sale'}
            onChange={(value) => setBusinessAsset({ ...asset, whoKeepingBusiness: value })}
          />
        </div>
        <ValidationMessages
          name='nameBusinessHeld'
          validationErrors={validationErrors}
        />
      </fieldset>

      <TransferPeriod
        display={asset.nameBusinessHeld !== asset.whoKeepingBusiness &&
          asset.whoKeepingBusiness !== 'sale' && asset.whoKeepingBusiness !== ''}
        name='shares-transfer'
        title='What timeframe do you need to do the transfer?'
        transferPeriodQuantity={asset.transferPeriodQuantity}
        setTransferPeriodQuantity={(value) => setBusinessAsset(
          { ...asset, transferPeriodQuantity: value }
        )}
        transferPeriod={asset.transferPeriod}
        setTransferPeriod={(value) => setBusinessAsset({ ...asset, transferPeriod: value })}
        validationErrors={validationErrors}
      />

      {asset.whoKeepingBusiness === 'sale' && (
        <>
          <FundsDivided
            display={asset.whoKeepingBusiness === 'sale'}
            name='how-sale-proceeds-divided'
            title='How are the net sale proceeds being divided?'
            splitTypeValue={asset.saleProceeds}
            setSplitType={(value) => setBusinessAsset({ ...asset, saleProceeds: value })}
            percentageSplitValue={asset.saleProceedsPercentageSplit}
            setPercentageSplitValue={(value) => setBusinessAsset(
              { ...asset, saleProceedsPercentageSplit: value }
            )}
            fixedSumValue={asset.saleProceedsFixedSum}
            setFixedSumValue={(value) => setBusinessAsset(
              { ...asset, saleProceedsFixedSum: value }
            )}
            validationErrors={validationErrors}
          />

          <CapitalGainsTax
            name='business-capital-gains-tax'
            title='Will there be any Capital Gains Tax?'
            willThereBeCapitalGains={asset.willThereBeCapitalGains}
            setWillThereBeCapitalGains={(value) => setBusinessAsset(
              { ...asset, willThereBeCapitalGains: value }
            )}
            capitalGainsTaxResponsibility={asset.capitalGainsTaxResponsibility}
            setCapitalGainsTaxResponsibility={(value) => setBusinessAsset(
              { ...asset, capitalGainsTaxResponsibility: value }
            )}
            percentageSplitValue={asset.capitalGainsTaxSplit}
            setPercentageSplitValue={(value) => setBusinessAsset(
              { ...asset, capitalGainsTaxSplit: value }
            )}
            validationErrors={validationErrors}
          />
        </>
      )}
    </>
  )
}

export default Partnership
