import React from 'react'
import { Link } from 'react-router-dom'

import Loading from './loading'

interface ButtonProps {
  children: JSX.Element[] | JSX.Element | string
  loading: boolean
  disabled?: boolean
  buttonType: 'button' | 'submit' | 'reset'
  type: 'primary' | 'danger' | 'secondary' | 'white' | 'link'
  className: string
  onClick: () => void
  to: string
}

const Button = ({
  children, loading, disabled = false, type, buttonType, className, onClick, to
}: ButtonProps): JSX.Element => {
  let buttonContent
  let additionalCss = ''
  let colorCss = ''
  const buttonStyleClass = 'flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm ' +
    'font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 ' +
    `focus:ring-brand-secondary disabled:opacity-75 ${loading ? 'cursor-progress' : 'disabled:cursor-not-allowed'}`

  if (loading) {
    buttonContent = <Loading />
    additionalCss = 'cursor-not-allowed'
  } else {
    buttonContent = children
  }

  if (type === 'primary') {
    colorCss = `${buttonStyleClass} text-white bg-brand border-brand` +
      'hover:bg-brand-secondary-lite hover:border-brand-secondary-lite'
  } else if (type === 'secondary') {
    colorCss = `${buttonStyleClass} text-indigo-700 bg-indigo-100 hover:bg-indigo-200` +
      'border-indigo-300'
  } else if (type === 'white') {
    colorCss = `${buttonStyleClass} text-gray-700 bg-white hover:bg-gray-50 border-gray-300`
  } else if (type === 'danger') {
    colorCss = `${buttonStyleClass} text-red-700 bg-red-100 hover:bg-red-200 border-red-300`
  } else if (type === 'link') {
    colorCss = 'text-brand hover:text-brand-secondary-lite font-medium focus:outline-none ' +
      'focus:ring-2 focus:ring-offset-2 focus:ring-brand-secondary'
  }

  const buttonClass = `${colorCss} ${className} ${additionalCss}`.trim()

  if (to !== null) {
    return (
      <Link to={to} className={buttonClass}>
        {buttonContent}
      </Link>
    )
  }

  const click = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()

    if (onClick !== null) {
      onClick()
    }
  }

  return (
    <button
      type={buttonType}
      className={buttonClass}
      disabled={loading || disabled}
      onClick={click}
    >
      {buttonContent}
    </button>
  )
}
Button.defaultProps = {
  loading: false,
  buttonType: 'button',
  className: '',
  type: 'primary',
  onClick: null,
  to: null
}

export default Button
