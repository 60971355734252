import React, { useState, useEffect } from 'react'

import { TextInput, Input, RadioButton, SelectInput, ValidationMessages } from '../components'
import { ConsentOrder, OtherLiability, ValidationError } from '../models'

interface OtherLiabilityProps {
  consentOrder: ConsentOrder
  liability: OtherLiability
  setOtherLiability: (liability: OtherLiability) => void
  validationErrors: ValidationError[]
}

const Other = ({
  consentOrder, liability, setOtherLiability, validationErrors
}: OtherLiabilityProps): JSX.Element => {
  const [assets] = useState(
    consentOrder.assets.filter(
      (a) => a.assetType === 'boat' || a.assetType === 'motor-vehicle' || a.assetType === 'business'
    )
  )

  useEffect(() => {
    if (assets.length === 0) {
      setOtherLiability({ ...liability, isAttachedToAnAsset: 'no' })
    }
  }, [consentOrder])

  return (
    <>
      <Input
        title='Name of Lender or Creditor'
        name='other-liability-lender-name'
        type='text'
        value={liability.nameOfLenderOrCreditor}
        onChange={(value) => setOtherLiability({ ...liability, nameOfLenderOrCreditor: value })}
        validationErrorName='nameOfLenderOrCreditor'
        validationErrors={validationErrors}
      />

      <Input
        title='Description of Liability'
        name='other-liability-description'
        type='text'
        value={liability.description}
        onChange={(value) => setOtherLiability({ ...liability, description: value })}
        validationErrorName='description'
        validationErrors={validationErrors}
      />

      <div>
        <label className='pt-2 text-sm text-gray-700'>Estimated Amount</label>
        <div className='flex'>
          <label className='pt-2 mr-1 text-sm text-gray-700'>$</label>
          <TextInput
            displayLabel={false}
            name='other-liability-estimated-amount'
            value={liability.estimatedAmount}
            onChange={(value) => setOtherLiability({ ...liability, estimatedAmount: value })}
            numberic
            validationErrorName='estimatedAmount'
            validationErrors={validationErrors}
          />
        </div>
      </div>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>In whose name is this held?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='other-liability-held-me'
            name='other-liability-held'
            title='Me'
            value='me'
            checked={liability.nameLiabilityHeld === 'me'}
            onChange={(value) => setOtherLiability({ ...liability, nameLiabilityHeld: value })}
          />
          <RadioButton
            id='other-liability-held-ex'
            name='other-liability-held'
            title='My Ex'
            value='ex'
            checked={liability.nameLiabilityHeld === 'ex'}
            onChange={(value) => setOtherLiability({ ...liability, nameLiabilityHeld: value })}
          />
          <RadioButton
            id='other-liability-held-both'
            name='other-liability-held'
            title='Both Me and My Ex'
            value='both'
            checked={liability.nameLiabilityHeld === 'both'}
            onChange={(value) => setOtherLiability({ ...liability, nameLiabilityHeld: value })}
          />
        </div>
        <ValidationMessages
          name='nameLiabilityHeld'
          validationErrors={validationErrors}
        />
      </fieldset>

      {assets.length !== 0 && (
        <fieldset className='mt-6'>
          <div>
            <p className='text-sm text-gray-900'>Is this liability attached to any asset?</p>
          </div>
          <div className='mt-4 flex flex-wrap space-x-4'>
            <RadioButton
              id='other-liability-attached-to-asset-yes'
              name='liability-attached-to-asset'
              title='Yes'
              value='yes'
              checked={liability.isAttachedToAnAsset === 'yes'}
              onChange={(value) => setOtherLiability({ ...liability, isAttachedToAnAsset: value })}
            />
            <RadioButton
              id='other-liability-attached-to-asset-no'
              name='liability-attached-to-asset'
              title='No'
              value='no'
              checked={liability.isAttachedToAnAsset === 'no'}
              onChange={(value) => setOtherLiability({ ...liability, isAttachedToAnAsset: value })}
            />
          </div>
          <ValidationMessages
            name='isAttachedToAnAsset'
            validationErrors={validationErrors}
          />
        </fieldset>
      )}

      {liability.isAttachedToAnAsset === 'yes' && (
        <>
          <SelectInput
            title='What asset does this liability attach to?'
            name='assets'
            displayProperty='name'
            idProperty='id'
            options={assets}
            value={liability.assetId?.toString()}
            onChange={(value) => setOtherLiability({ ...liability, assetId: value })}
            validationErrorName='asset'
            validationErrors={validationErrors}
          />
        </>
      )}

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>Who will be responsible for this liability?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='other-liability-responsible-me'
            name='other-liability-responsible'
            title='Me'
            value='me'
            checked={liability.whoIsResponsibleForThisLiability === 'me'}
            onChange={(value) => setOtherLiability(
              { ...liability, whoIsResponsibleForThisLiability: value }
            )}
          />
          <RadioButton
            id='other-liability-responsible-ex'
            name='other-liability-responsible'
            title='My Ex'
            value='ex'
            checked={liability.whoIsResponsibleForThisLiability === 'ex'}
            onChange={(value) => setOtherLiability(
              { ...liability, whoIsResponsibleForThisLiability: value }
            )}
          />
        </div>
        <ValidationMessages
          name='whoIsResponsibleForThisLiability'
          validationErrors={validationErrors}
        />
      </fieldset>
    </>
  )
}

export default Other
