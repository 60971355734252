import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Button, TextInput, PersonalPossession, RealEstate, BankAccount, Shares, Trust,
  Business, Company, Boat, MotorVehicle
} from '../components'
import { ValidationError } from '../models'
import { useConsentOrder } from './consent-order-route'
import {
  updateBankAccountAsset, updatePersonalPossessionAsset, updateRealEstateAsset, updateSharesAsset,
  updateTrustAsset, updateBusinessAsset, updateCompanyAsset, updateBoatAsset, updateMotorVehicleAsset
} from '../models/consent-order'

const EditAssetRoute = (): JSX.Element => {
  const { assetId } = useParams()
  const navigate = useNavigate()
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const [saving, setSaving] = useState(false)
  const { consentOrder, setConsentOrder } = useConsentOrder()
  const [asset] = useState(consentOrder.assets.find((a) => a.id == assetId))
  const [name, setName] = useState(asset != null ? asset.name : '')

  const [bankAccountAsset, setBankAccountAsset] = useState(asset?.bankAccountAsset)
  const [boatAsset, setBoatAsset] = useState(asset?.boatAsset)
  const [businessAsset, setBusinessAsset] = useState(asset?.businessAsset)
  const [companyAsset, setCompanyAsset] = useState(asset?.companyAsset)
  const [motorVehicleAsset, setMotorVehicleAsset] = useState(asset?.motorVehicleAsset)
  const [personalPossessionAsset, setPersonalPossessionAsset] = useState(asset?.personalPossessionAsset)
  const [realEstateAsset, setRealEstateAsset] = useState(asset?.realEstateAsset)
  const [sharesAsset, setSharesAsset] = useState(asset?.sharesAsset)
  const [trustAsset, setTrustAsset] = useState(asset?.trustAsset)

  const save = async (): Promise<void> => {
    if (asset == null) return

    setSaving(true)
    setValidationErrors([])

    setSaving(false)

    let result = null

    if (bankAccountAsset != null) {
      result = await updateBankAccountAsset(
        consentOrder,
        asset,
        name,
        bankAccountAsset
      )
    }

    if (boatAsset != null) {
      result = await updateBoatAsset(
        consentOrder,
        asset,
        name,
        boatAsset
      )
    }

    if (businessAsset != null) {
      result = await updateBusinessAsset(
        consentOrder,
        asset,
        name,
        businessAsset
      )
    }

    if (companyAsset != null) {
      result = await updateCompanyAsset(
        consentOrder,
        asset,
        name,
        companyAsset
      )
    }

    if (motorVehicleAsset != null) {
      result = await updateMotorVehicleAsset(
        consentOrder,
        asset,
        name,
        motorVehicleAsset
      )
    }

    if (personalPossessionAsset != null) {
      result = await updatePersonalPossessionAsset(
        consentOrder,
        asset,
        name,
        personalPossessionAsset
      )
    }

    if (realEstateAsset != null) {
      result = await updateRealEstateAsset(
        consentOrder,
        asset,
        name,
        realEstateAsset
      )
    }

    if (sharesAsset != null) {
      result = await updateSharesAsset(
        consentOrder,
        asset,
        name,
        sharesAsset
      )
    }

    if (trustAsset != null) {
      result = await updateTrustAsset(
        consentOrder,
        asset,
        name,
        trustAsset
      )
    }

    if (result == null) {
      return
    }

    if (result.validationErrors.length === 0) {
      setConsentOrder(result.consentOrder)
      navigate(`/consent-orders/${consentOrder.slug}/assets`)
    } else {
      setValidationErrors(result.validationErrors)
    }
  }

  if (asset == null) {
    return <></>
  }

  return (
    <div
      className='fixed z-10 inset-0 overflow-y-auto'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div
        className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block \
                   sm:p-0'
      >
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
        />
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'
        />

        <div
          className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden \
                     shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl \
                     sm:w-full'
        >
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className='space-y-4'>
              <div className='mt-3 text-center sm:mt-0 sm:text-left'>
                <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-title'>
                  Add Asset
                </h3>
                <hr className='mt-3' />
              </div>

              <form className='space-y-6'>
                <TextInput
                  title='Nickname'
                  name='asset.name'
                  value={name}
                  onChange={setName}
                  validationErrors={validationErrors}
                />

                {bankAccountAsset != null && (
                  <BankAccount
                    asset={bankAccountAsset}
                    setBankAccountAsset={setBankAccountAsset}
                    validationErrors={validationErrors}
                  />
                )}

                {boatAsset != null && (
                  <Boat
                    asset={boatAsset}
                    setBoatAsset={setBoatAsset}
                    validationErrors={validationErrors}
                  />
                )}

                {businessAsset != null && (
                  <Business
                    asset={businessAsset}
                    setBusinessAsset={setBusinessAsset}
                    validationErrors={validationErrors}
                  />
                )}

                {companyAsset != null && (
                  <Company
                    asset={companyAsset}
                    setCompanyAsset={setCompanyAsset}
                    validationErrors={validationErrors}
                  />
                )}

                {motorVehicleAsset != null && (
                  <MotorVehicle
                    asset={motorVehicleAsset}
                    setMotorVehicleAsset={setMotorVehicleAsset}
                    validationErrors={validationErrors}
                  />
                )}

                {personalPossessionAsset != null && (
                  <PersonalPossession
                    asset={personalPossessionAsset}
                    setPersonalPossessionAsset={setPersonalPossessionAsset}
                    validationErrors={validationErrors}
                  />
                )}

                {realEstateAsset != null && (
                  <RealEstate
                    asset={realEstateAsset}
                    setRealEstateAsset={setRealEstateAsset}
                    validationErrors={validationErrors}
                  />
                )}

                {sharesAsset != null && (
                  <Shares
                    asset={sharesAsset}
                    setSharesAsset={setSharesAsset}
                    validationErrors={validationErrors}
                  />
                )}

                {trustAsset != null && (
                  <Trust
                    asset={trustAsset}
                    setTrustAsset={setTrustAsset}
                    validationErrors={validationErrors}
                  />
                )}

              </form>
            </div>
          </div>

          <div className='bg-gray-50 px-4 py-3 sm:px-6 flex justify-end space-x-2'>
            <Button
              type='white'
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              onClick={save}
              loading={saving}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditAssetRoute
