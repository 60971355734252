import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Button, TextInput, RadioButton, SelfManagedSuper, CommercialSuper
} from '../components'
import { useConsentOrder } from './consent-order-route'
import { addSuperannuation } from '../models/consent-order'
import { CommercialSuperannuation, SelfManagedSuperannuation, ValidationError } from '../models'

const AddSuperannuationRoute = (): JSX.Element => {
  const navigate = useNavigate()
  const { consentOrder, setConsentOrder } = useConsentOrder()
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [saving, setSaving] = useState(false)
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])

  const [commercialFund, setCommercialFund] = useState<CommercialSuperannuation>({
    nameOfFund: '',
    membershipNumber: '',
    nameFundHeldBy: '',
    willFundBeSplit: '',
    fundSplit: '',
    fundSplitPercentage: '',
    fundSplitFixedSum: ''
  })
  const [selfManagedFund, setSelfManagedFund] = useState<SelfManagedSuperannuation>({
    nameOfSelfManagedFund: '',
    members: '',
    whoKeepingFund: '',
    paymentToExitingMember: '',
    exitingMemberBalance: '',
    keepingMemberBalance: '',
    exitingMemberKeeping: '',
    exitingMemberAdditionalPortion: '',
    transferPeriodQuantity: '30',
    transferPeriod: 'days',
    fundTrustees: '',
    nameOfCorporateTrust: ''
  })

  const save = async (): Promise<void> => {
    setSaving(true)
    setValidationErrors([])

    let result = null
    if (type === 'commercial') {
      result = await addSuperannuation(consentOrder, name, type, commercialFund)
    }

    if (type === 'self-managed') {
      result = await addSuperannuation(consentOrder, name, type, selfManagedFund)
    }

    setSaving(false)

    if (result == null) {
      return
    }

    if (result.validationErrors.length === 0) {
      setConsentOrder(result.consentOrder)
      navigate(`/consent-orders/${consentOrder.slug}/superannuation`)
    } else {
      setValidationErrors(result?.validationErrors)
    }
  }

  return (
    <div
      className='fixed z-10 inset-0 overflow-y-auto'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div
        className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block \
                   sm:p-0'
      >
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
        />
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'
        />

        <div
          className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden \
                     shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl \
                     sm:w-full'
        >
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className='space-y-4'>
              <div className='mt-3 text-center sm:mt-0 sm:text-left'>
                <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-title'>
                  Add Superannuation
                </h3>
                <hr className='mt-3' />
              </div>

              <form className='space-y-6'>
                <TextInput
                  title='Nickname'
                  name='name'
                  value={name}
                  onChange={setName}
                  validationErrorName='superannuation.name'
                  validationErrors={validationErrors}
                />

                <fieldset className='mt-6'>
                  <div>
                    <p className='text-sm text-gray-900'>What type of fund is this?</p>
                  </div>
                  <div className='mt-4 md:flex md:flex-wrap md:space-x-4'>
                    <RadioButton
                      id='commercial'
                      name='type'
                      title='Commercial Superannuation Fund'
                      value='commercial'
                      checked={type === 'commercial'}
                      onChange={setType}
                    />

                    <RadioButton
                      id='self-managed'
                      name='type'
                      title='Self-Managed Superannuation Fund'
                      value='self-managed'
                      checked={type === 'self-managed'}
                      onChange={setType}
                    />
                  </div>
                </fieldset>

                {type === 'commercial' && (
                  <CommercialSuper
                    consentOrder={consentOrder}
                    fund={commercialFund}
                    setFund={setCommercialFund}
                    validationErrors={validationErrors}
                  />
                )}

                {type === 'self-managed' && (
                  <SelfManagedSuper
                    fund={selfManagedFund}
                    setFund={setSelfManagedFund}
                    validationErrors={validationErrors}
                  />
                )}
              </form>
            </div>
          </div>

          <div className='bg-gray-50 px-4 py-3 sm:px-6 flex justify-end space-x-2'>
            <Button
              type='white'
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              onClick={save}
              loading={saving}
              disabled={type === ''}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddSuperannuationRoute
