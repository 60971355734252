import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const addSharesAssetQuery = gql`
  mutation AddSharesAssetQuery($input: AddSharesAssetInput!) {
    addSharesAsset(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
      validationErrors {
        name
        messages
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default addSharesAssetQuery
