import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const updateBoatAssetQuery = gql`
  mutation UpdateBoatAssetQuery($input: UpdateBoatAssetInput!) {
    updateBoatAsset(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
      validationErrors {
        name
        messages
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default updateBoatAssetQuery
