import { gql } from '@apollo/client'

const updateConsentOrderTemplateQuery = gql`
  mutation UpdateConsentOrderTemplate($input:UpdateConsentOrderTemplateInput!) {
    updateConsentOrderTemplate(input:$input) {
      consentOrderTemplate {
        id
        template
      }
    }
  }
`

export default updateConsentOrderTemplateQuery
