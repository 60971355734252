import React from 'react'

import { RadioButton, PercentageSplit, ValidationMessages } from '.'
import { ValidationError } from '../models'

interface CapitalGainsTaxProps {
  name: string
  title: string
  willThereBeCapitalGains: string
  setWillThereBeCapitalGains: (value: string) => void
  capitalGainsTaxResponsibility: string
  setCapitalGainsTaxResponsibility: (value: string) => void
  percentageSplitValue: string
  setPercentageSplitValue: (value: string) => void
  validationErrors: ValidationError[]
}

const CapitalGainsTax = ({
  name, title, willThereBeCapitalGains, setWillThereBeCapitalGains,
  capitalGainsTaxResponsibility, setCapitalGainsTaxResponsibility,
  percentageSplitValue, setPercentageSplitValue, validationErrors
}: CapitalGainsTaxProps): JSX.Element => {
  return (
    <>
      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>
            {title}
          </p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id={`${name}-yes`}
            name={name}
            title='Yes'
            value='yes'
            checked={willThereBeCapitalGains === 'yes'}
            onChange={setWillThereBeCapitalGains}
          />
          <RadioButton
            id={`${name}-no'`}
            name={name}
            title='No'
            value='no'
            checked={willThereBeCapitalGains === 'no'}
            onChange={setWillThereBeCapitalGains}
          />
          <RadioButton
            id={`${name}-not-sure`}
            name={name}
            title='Not Sure'
            value='not-sure'
            checked={willThereBeCapitalGains === 'not-sure'}
            onChange={setWillThereBeCapitalGains}
          />
        </div>
        <ValidationMessages
          name='willThereBeCapitalGains'
          validationErrors={validationErrors}
        />
      </fieldset>

      {willThereBeCapitalGains !== '' && willThereBeCapitalGains !== 'no' && (
        <>
          <fieldset className='mt-6'>
            <div>
              <p className='text-sm text-gray-900'>
                Who will be responsible for the Capital Gains Tax
                {willThereBeCapitalGains === 'not-sure' ? ' if any?' : '?'}
              </p>
            </div>
            <div className='mt-4 flex flex-wrap space-x-4'>
              <RadioButton
                id={`${name}-esponsibility-me`}
                name={`${name}-responsibility`}
                title='Me'
                value='me'
                checked={capitalGainsTaxResponsibility === 'me'}
                onChange={setCapitalGainsTaxResponsibility}
              />
              <RadioButton
                id={`${name}-esponsibility-ex`}
                name={`${name}-responsibility`}
                title='My Ex'
                value='ex'
                checked={capitalGainsTaxResponsibility === 'ex'}
                onChange={setCapitalGainsTaxResponsibility}
              />
              <RadioButton
                id={`${name}-esponsibility-both`}
                name={`${name}-responsibility`}
                title='Both Me and My Ex'
                value='both'
                checked={capitalGainsTaxResponsibility === 'both'}
                onChange={setCapitalGainsTaxResponsibility}
              />
            </div>
            <ValidationMessages
              name='capitalGainsTaxResponsibility'
              validationErrors={validationErrors}
            />
          </fieldset>

          <PercentageSplit
            display={capitalGainsTaxResponsibility === 'both'}
            name={`${name}-percentage-split`}
            value={percentageSplitValue}
            setValue={setPercentageSplitValue}
            validationName='capitalGainsTaxSplit'
            validationErrors={validationErrors}
          />
        </>
      )}
    </>
  )
}

CapitalGainsTax.defaultProps = {
  validationErrors: []
}

export default CapitalGainsTax
