import apolloClient from '../apollo-client'

import {
  consentOrderPreviewQuery, createConsentOrderQuery, consentOrderQuery,
  updatePersonalInformationQuery, addRealEstateAssetQuery, addBankAccountAssetQuery,
  addSharesAssetQuery, addTrustAssetQuery, addBusinessAssetQuery, addBoatAssetQuery,
  addCompanyAssetQuery, addMotorVehicleAssetQuery, addPersonalPossessionAssetQuery,
  addMortgageLiabilityQuery, addCreditCardLiabilityQuery, addOtherLiabilityQuery,
  addLoanLiabilityQuery, savePayoutQuery, addCommercialSuperannuationQuery,
  addSelfManagedSuperannuationQuery, deleteAssetQuery, deleteLiabilityQuery,
  deletePayoutQuery, deleteSuperannuationQuery, acceptDisclaimersQuery, completedAssetsQuery,
  completedLiabilitiesQuery, completedPayoutsQuery, createPaymentIntentQuery,
  updatePersonalPossessionAssetQuery, updateRealEstateAssetQuery, updateBankAccountAssetQuery,
  updateTrustAssetQuery, updateCompanyAssetQuery, updateBoatAssetQuery,
  updateMotorVehicleAssetQuery, updateMortgageLiabilityQuery, updateLoanLiabilityQuery,
  updateCreditCardLiabilityQuery, updateOtherLiabilityQuery
} from '../queries'
import ValidationError from './validation-error'
import Asset from './asset'
import RealEstateAsset from './real-estate-asset'
import BankAccountAsset from './bank-account-asset'
import SharesAsset from './shares-asset'
import TrustAsset from './trust-asset'
import BusinessAsset from './business-asset'
import CompanyAsset from './company-asset'
import BoatAsset from './boat-asset'
import MotorVehicleAsset from './motor-vehicle-asset'
import PersonalPossessionAsset from './personal-possession-asset'
import Liability from './liability'
import MortgageLiability from './mortgage-liability'
import CreditCardLiability from './credit-card-liability'
import OtherLiability from './other-liability'
import LoanLiability from './loan-liability'
import Payout from './payout'
import Superannuation from './superannuation'
import CommercialSuperannuation from './commercial-superannuation'
import SelfManagedSuperannuation from './self-managed-superannuation'
import updateSharesAssetQuery from '../queries/update-shares-asset-query'
import updateBusinessAssetQuery from '../queries/update-business-asset-query'

interface ConsentOrder {
  id: string
  slug: string
  email: string
  firstName: string
  middleNames: string
  surname: string
  gender: string
  addressPlaceId: string
  addressStreet: string
  addressSuburb: string
  addressState: string
  addressCountry: string
  exFirstName: string
  exMiddleNames: string
  exSurname: string
  exGender: string
  exAddressPlaceId: string
  exAddressStreet: string
  exAddressSuburb: string
  exAddressState: string
  exAddressCountry: string
  relationshipType: string
  agreedToDisclaimer: boolean
  completedPersonalInformation: boolean
  completedAssets: boolean
  completedLiabilities: boolean
  completedPayouts: boolean
  price: number
  createdAt: string
  purchased: boolean
  purchaseConfirmedAt: string | null
  payout: Payout | null
  assets: Asset[]
  liabilities: Liability[]
  superannuations: Superannuation[]
}

const createConsentOrder = async (): Promise<ConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: createConsentOrderQuery,
    variables: {
      input: {}
    }
  })

  return data.createConsentOrder.consentOrder
}

const getConsentOrder = async (slug: string): Promise<ConsentOrder | null> => {
  const { data } = await apolloClient.query({
    query: consentOrderQuery,
    variables: { slug }
  })

  if (data !== null) {
    return data.consentOrder
  } else {
    return null
  }
}

const getPreview = async (slug: string): Promise<string> => {
  const { data } = await apolloClient.query({
    query: consentOrderPreviewQuery,
    variables: { slug }
  })

  return data.preview
}

interface UpdateConsentOrder {
  consentOrder: ConsentOrder
  validationErrors: ValidationError[]
}

const updatePersonalInformation = async (
  consentOrder: ConsentOrder, email: string, firstName: string,
  middleNames: string, surname: string, gender: string,
  addressPlaceId: string, addressStreet: string, addressSuburb: string,
  addressState: string, addressCountry: string, exFirstName: string,
  exMiddleNames: string, exSurname: string, exGender: string,
  exAddressPlaceId: string, exAddressStreet: string, exAddressSuburb: string,
  exAddressState: string, exAddressCountry: string, relationshipType: string
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: updatePersonalInformationQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        email,
        firstName,
        middleNames,
        surname,
        gender,
        addressPlaceId,
        addressStreet,
        addressSuburb,
        addressState,
        addressCountry,
        exFirstName,
        exMiddleNames,
        exSurname,
        exGender,
        exAddressPlaceId,
        exAddressStreet,
        exAddressSuburb,
        exAddressState,
        exAddressCountry,
        relationshipType
      }
    }
  })

  return data.updatePersonalInformation
}

const addAsset = async (
  consentOrder: ConsentOrder, name: string, type: string, asset: unknown
): Promise<UpdateConsentOrder> => {
  if (type === 'real-estate') {
    return await addRealEstateAsset(consentOrder, name, asset as RealEstateAsset)
  }

  if (type === 'bank-account') {
    return await addBankAccountAsset(consentOrder, name, asset as BankAccountAsset)
  }

  if (type === 'shares') {
    return await addSharesAsset(consentOrder, name, asset as SharesAsset)
  }

  if (type === 'trust') {
    return await addTrustAsset(consentOrder, name, asset as TrustAsset)
  }

  if (type === 'business') {
    return await addBusinessAsset(consentOrder, name, asset as BusinessAsset)
  }

  if (type === 'company') {
    return await addCompanyAsset(consentOrder, name, asset as CompanyAsset)
  }

  if (type === 'boat') {
    return await addBoatAsset(consentOrder, name, asset as BoatAsset)
  }

  if (type === 'motor-vehicle') {
    return await addMotorVehicleAsset(consentOrder, name, asset as MotorVehicleAsset)
  }

  if (type === 'personal-possession') {
    return await addPersonalPossessionAsset(consentOrder, name, asset as PersonalPossessionAsset)
  }

  return {
    consentOrder,
    validationErrors: []
  }
}

const addRealEstateAsset = async (
  consentOrder: ConsentOrder, name: string, realEstateAsset: RealEstateAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addRealEstateAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        addressPlaceId: realEstateAsset.addressPlaceId,
        addressStreet: realEstateAsset.addressStreet,
        addressSuburb: realEstateAsset.addressSuburb,
        addressState: realEstateAsset.addressState,
        addressCountry: realEstateAsset.addressCountry,
        obtainedTitleSearch: realEstateAsset.obtainedTitleSearch,
        landDescriptionInCertificateOfTitle: realEstateAsset.landDescriptionInCertificateOfTitle,
        certificateOfTitleVolumeNumber: realEstateAsset.certificateOfTitleVolumeNumber,
        certificateOfTitleFolioNumber: realEstateAsset.certificateOfTitleFolioNumber,
        namePropertyHeldBy: realEstateAsset.namePropertyHeldBy,
        whoKeepingProperty: realEstateAsset.whoKeepingProperty,
        propertyType: realEstateAsset.propertyType,
        residingPendingSettlement: realEstateAsset.residingPendingSettlement,
        receivingRentalIncome: realEstateAsset.receivingRentalIncome,
        transferPeriodQuantity: realEstateAsset.transferPeriodQuantity,
        transferPeriod: realEstateAsset.transferPeriod,
        rentalIncomeSplit: realEstateAsset.rentalIncomeSplit,
        saleProceeds: realEstateAsset.saleProceeds,
        saleProceedsPercentageSplit: realEstateAsset.saleProceedsPercentageSplit,
        saleProceedsFixedSum: realEstateAsset.saleProceedsFixedSum,
        willThereBeCapitalGains: realEstateAsset.willThereBeCapitalGains,
        capitalGainsTaxResponsibility: realEstateAsset.capitalGainsTaxResponsibility,
        capitalGainsTaxSplit: realEstateAsset.capitalGainsTaxSplit,
        saleShortFall: realEstateAsset.saleShortFall,
        saleShortFallResponsibility: realEstateAsset.saleShortFallResponsibility,
        saleShortFallSplit: realEstateAsset.saleShortFallSplit
      }
    }
  })

  return data.addRealEstateAsset
}

const addBankAccountAsset = async (
  consentOrder: ConsentOrder, name: string, bankAccountAsset: BankAccountAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addBankAccountAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        nameOfBankOrFinancialInstitution: bankAccountAsset.nameOfBankOrFinancialInstitution,
        bankAccountType: bankAccountAsset.bankAccountType,
        lastFourDigitsOfAccount: bankAccountAsset.lastFourDigitsOfAccount,
        nameAccountHeldBy: bankAccountAsset.nameAccountHeldBy,
        howFundsDivided: bankAccountAsset.howFundsDivided,
        howFundsDividedSplit: bankAccountAsset.howFundsDividedSplit,
        fundsDividedFixedSum: bankAccountAsset.fundsDividedFixedSum,
        fundsDividedPercentageSplit: bankAccountAsset.fundsDividedPercentageSplit
      }
    }
  })

  return data.addBankAccountAsset
}

const addSharesAsset = async (
  consentOrder: ConsentOrder, name: string, sharesAsset: SharesAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addSharesAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        nameOfShareHolding: sharesAsset.nameOfShareHolding,
        nameSharesHeldBy: sharesAsset.nameSharesHeldBy,
        whoKeepingShares: sharesAsset.whoKeepingShares,
        transferPeriodQuantity: sharesAsset.transferPeriodQuantity,
        transferPeriod: sharesAsset.transferPeriod,
        saleProceeds: sharesAsset.saleProceeds,
        saleProceedsPercentageSplit: sharesAsset.saleProceedsPercentageSplit,
        saleProceedsFixedSum: sharesAsset.saleProceedsFixedSum,
        willThereBeCapitalGains: sharesAsset.willThereBeCapitalGains,
        capitalGainsTaxResponsibility: sharesAsset.capitalGainsTaxResponsibility,
        capitalGainsTaxSplit: sharesAsset.capitalGainsTaxSplit
      }
    }
  })

  return data.addSharesAsset
}

const addTrustAsset = async (
  consentOrder: ConsentOrder, name: string, trustAsset: TrustAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addTrustAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        nameOfTrust: trustAsset.nameOfTrust,
        whoAreTheBeneficiaries: trustAsset.whoAreTheBeneficiaries,
        whoWillRetainTheTrust: trustAsset.whoWillRetainTheTrust,
        transferPeriodQuantity: trustAsset.transferPeriodQuantity,
        transferPeriod: trustAsset.transferPeriod,
        woundUpAssetsSplit: trustAsset.woundUpAssetsSplit
      }
    }
  })

  return data.addTrustAsset
}

const addBusinessAsset = async (
  consentOrder: ConsentOrder, name: string, businessAsset: BusinessAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addBusinessAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        businessName: businessAsset.businessName,
        nameBusinessHeld: businessAsset.nameBusinessHeld,
        whoKeepingBusiness: businessAsset.whoKeepingBusiness,
        transferPeriodQuantity: businessAsset.transferPeriodQuantity,
        transferPeriod: businessAsset.transferPeriod,
        saleProceeds: businessAsset.saleProceeds,
        saleProceedsPercentageSplit: businessAsset.saleProceedsPercentageSplit,
        saleProceedsFixedSum: businessAsset.saleProceedsFixedSum,
        willThereBeCapitalGains: businessAsset.willThereBeCapitalGains,
        capitalGainsTaxResponsibility: businessAsset.capitalGainsTaxResponsibility,
        capitalGainsTaxSplit: businessAsset.capitalGainsTaxSplit
      }
    }
  })

  return data.addBusinessAsset
}

const addCompanyAsset = async (
  consentOrder: ConsentOrder, name: string, companyAsset: CompanyAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addCompanyAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        companyName: companyAsset.companyName,
        nameCompanyHeldBy: companyAsset.nameCompanyHeldBy,
        whoKeepingCompany: companyAsset.whoKeepingCompany,
        transferPeriodQuantity: companyAsset.transferPeriodQuantity,
        transferPeriod: companyAsset.transferPeriod,
        woundUpAssetsSplit: companyAsset.woundUpAssetsSplit
      }
    }
  })

  return data.addCompanyAsset
}

const addBoatAsset = async (
  consentOrder: ConsentOrder, name: string, boatAsset: BoatAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addBoatAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        boatName: boatAsset.boatName,
        nameBoatHeldBy: boatAsset.nameBoatHeldBy,
        whoKeepingBoat: boatAsset.whoKeepingBoat,
        transferPeriodQuantity: boatAsset.transferPeriodQuantity,
        transferPeriod: boatAsset.transferPeriod,
        saleProceeds: boatAsset.saleProceeds,
        saleProceedsPercentageSplit: boatAsset.saleProceedsPercentageSplit,
        saleProceedsFixedSum: boatAsset.saleProceedsFixedSum
      }
    }
  })

  return data.addBoatAsset
}

const addMotorVehicleAsset = async (
  consentOrder: ConsentOrder, name: string, motorVehicleAsset: MotorVehicleAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addMotorVehicleAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        motorVehicleYear: motorVehicleAsset.motorVehicleYear,
        motorVehicleMake: motorVehicleAsset.motorVehicleMake,
        motorVehicleModel: motorVehicleAsset.motorVehicleModel,
        nameMotorVehicleHeldBy: motorVehicleAsset.nameMotorVehicleHeldBy,
        whoKeepingMotorVehicle: motorVehicleAsset.whoKeepingMotorVehicle,
        transferPeriodQuantity: motorVehicleAsset.transferPeriodQuantity,
        transferPeriod: motorVehicleAsset.transferPeriod,
        saleProceeds: motorVehicleAsset.saleProceeds,
        saleProceedsPercentageSplit: motorVehicleAsset.saleProceedsPercentageSplit,
        saleProceedsFixedSum: motorVehicleAsset.saleProceedsFixedSum
      }
    }
  })

  return data.addMotorVehicleAsset
}

const addPersonalPossessionAsset = async (
  consentOrder: ConsentOrder, name: string, personalPossessionAsset: PersonalPossessionAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addPersonalPossessionAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        description: personalPossessionAsset.description,
        whoKeepingPersonalPossession: personalPossessionAsset.whoKeepingPersonalPossession
      }
    }
  })

  return data.addPersonalPossessionAsset
}

const addLiability = async (
  consentOrder: ConsentOrder, name: string, type: string, liability: unknown
): Promise<UpdateConsentOrder> => {
  if (type === 'mortgage') {
    return await addMortgageLiability(consentOrder, name, liability as MortgageLiability)
  }

  if (type === 'credit-card') {
    return await addCreditCardLiability(consentOrder, name, liability as CreditCardLiability)
  }

  if (type === 'loan') {
    return await addLoanLiability(consentOrder, name, liability as LoanLiability)
  }

  if (type === 'other') {
    return await addOtherLiability(consentOrder, name, liability as OtherLiability)
  }

  return {
    consentOrder,
    validationErrors: []
  }
}

const addMortgageLiability = async (
  consentOrder: ConsentOrder, name: string, mortgageLiability: MortgageLiability
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addMortgageLiabilityQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        nameOfBankOrFinancialInstitution: mortgageLiability.nameOfBankOrFinancialInstitution,
        lastFourDigitsOfAccount: mortgageLiability.lastFourDigitsOfAccount,
        nameAccountHeldBy: mortgageLiability.nameAccountHeldBy,
        paymentsPendingSettlementResponsibility:
          mortgageLiability.paymentsPendingSettlementResponsibility,
        paymentsPendingSettlementSplit: mortgageLiability.paymentsPendingSettlementSplit,
        assetId: mortgageLiability.assetId
      }
    }
  })

  return data.addMortgageLiability
}

const addCreditCardLiability = async (
  consentOrder: ConsentOrder, name: string, creditCardLiability: CreditCardLiability
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addCreditCardLiabilityQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        nameOfBankOrFinancialInstitution: creditCardLiability.nameOfBankOrFinancialInstitution,
        lastFourDigitsOfAccount: creditCardLiability.lastFourDigitsOfAccount,
        nameAccountHeldBy: creditCardLiability.nameAccountHeldBy,
        whoIsKeepingCreditCard: creditCardLiability.whoIsKeepingCreditCard,
        transferPeriodQuantity: creditCardLiability.transferPeriodQuantity,
        transferPeriod: creditCardLiability.transferPeriod
      }
    }
  })

  return data.addCreditCardLiability
}

const addOtherLiability = async (
  consentOrder: ConsentOrder, name: string, otherLiability: OtherLiability
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addOtherLiabilityQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        nameOfLenderOrCreditor: otherLiability.nameOfLenderOrCreditor,
        description: otherLiability.description,
        nameLiabilityHeld: otherLiability.nameLiabilityHeld,
        whoIsResponsibleForThisLiability: otherLiability.whoIsResponsibleForThisLiability,
        isAttachedToAnAsset: otherLiability.isAttachedToAnAsset,
        estimatedAmount: otherLiability.estimatedAmount,
        assetId: otherLiability.assetId
      }
    }
  })

  return data.addOtherLiability
}

const addLoanLiability = async (
  consentOrder: ConsentOrder, name: string, loanLiability: LoanLiability
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addLoanLiabilityQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        nameOfBankOrFinancialInstitution: loanLiability.nameOfBankOrFinancialInstitution,
        lastFourDigitsOfAccount: loanLiability.lastFourDigitsOfAccount,
        nameAccountHeldBy: loanLiability.nameAccountHeldBy,
        isAttachedToAnAsset: loanLiability.isAttachedToAnAsset,
        whoIsResponsibleForThisLoan: loanLiability.whoIsResponsibleForThisLoan,
        transferPeriodQuantity: loanLiability.transferPeriodQuantity,
        transferPeriod: loanLiability.transferPeriod,
        paymentsPendingSettlementResponsibility:
          loanLiability.paymentsPendingSettlementResponsibility,
        paymentsPendingSettlementSplit: loanLiability.paymentsPendingSettlementSplit,
        assetId: loanLiability.assetId
      }
    }
  })

  return data.addLoanLiability
}

const savePayout = async (
  consentOrder: ConsentOrder, payout: Payout
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: savePayoutQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        whoReceivingPayout: payout.whoReceivingPayout,
        payoutAmount: payout.payoutAmount,
        payoutTimeframe: payout.payoutTimeframe,
        transferPeriodQuantity: payout.transferPeriodQuantity,
        transferPeriod: payout.transferPeriod,
        assetId: payout.assetId
      }
    }
  })

  return data.savePayout
}

const addSuperannuation = async (
  consentOrder: ConsentOrder, name: string, type: string, fund: unknown
): Promise<UpdateConsentOrder> => {
  if (type === 'commercial') {
    return await addCommercialFund(consentOrder, name, fund as CommercialSuperannuation)
  }

  if (type === 'self-managed') {
    return await addSelfManagedFund(consentOrder, name, fund as SelfManagedSuperannuation)
  }

  return {
    consentOrder,
    validationErrors: []
  }
}

const addCommercialFund = async (
  consentOrder: ConsentOrder, name: string, fund: CommercialSuperannuation
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addCommercialSuperannuationQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        nameOfFund: fund.nameOfFund,
        membershipNumber: fund.membershipNumber,
        nameFundHeldBy: fund.nameFundHeldBy,
        willFundBeSplit: fund.willFundBeSplit,
        fundSplit: fund.fundSplit,
        fundSplitPercentage: fund.fundSplitPercentage,
        fundSplitFixedSum: fund.fundSplitFixedSum
      }
    }
  })

  return data.addCommercialSuperannuation
}

const addSelfManagedFund = async (
  consentOrder: ConsentOrder, name: string, fund: SelfManagedSuperannuation
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: addSelfManagedSuperannuationQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        nameOfSelfManagedFund: fund.nameOfSelfManagedFund,
        members: fund.members,
        whoKeepingFund: fund.whoKeepingFund,
        paymentToExitingMember: fund.paymentToExitingMember,
        transferPeriodQuantity: fund.transferPeriodQuantity,
        transferPeriod: fund.transferPeriod,
        exitingMemberBalance: fund.exitingMemberBalance,
        keepingMemberBalance: fund.keepingMemberBalance,
        exitingMemberKeeping: fund.exitingMemberKeeping,
        exitingMemberAdditionalPortion: fund.exitingMemberAdditionalPortion,
        fundTrustees: fund.fundTrustees,
        nameOfCorporateTrust: fund.nameOfCorporateTrust
      }
    }
  })

  return data.addSelfManagedSuperannuation
}

const deleteAsset = async (
  consentOrder: ConsentOrder, asset: Asset
): Promise<ConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: deleteAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        assetId: asset.id
      }
    }
  })

  return data.deleteAsset.consentOrder
}

const deleteLiability = async (
  consentOrder: ConsentOrder, liability: Liability
): Promise<ConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: deleteLiabilityQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        liabilityId: liability.id
      }
    }
  })

  return data.deleteLiability.consentOrder
}

const deleteSuperannuation = async (
  consentOrder: ConsentOrder, superannuation: Superannuation
): Promise<ConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: deleteSuperannuationQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        superannuationId: superannuation.id
      }
    }
  })

  return data.deleteSuperannuation.consentOrder
}

const deletePayout = async (consentOrder: ConsentOrder): Promise<ConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: deletePayoutQuery,
    variables: {
      input: {
        slug: consentOrder.slug
      }
    }
  })

  return data.deletePayout.consentOrder
}

const acceptDisclaimers = async (consentOrder: ConsentOrder): Promise<ConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: acceptDisclaimersQuery,
    variables: {
      input: {
        slug: consentOrder.slug
      }
    }
  })

  return data.acceptDisclaimers.consentOrder
}

const completedAssets = async (consentOrder: ConsentOrder): Promise<ConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: completedAssetsQuery,
    variables: {
      input: {
        slug: consentOrder.slug
      }
    }
  })

  return data.completedAssets.consentOrder
}

const completedLiabilities = async (consentOrder: ConsentOrder): Promise<ConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: completedLiabilitiesQuery,
    variables: {
      input: {
        slug: consentOrder.slug
      }
    }
  })

  return data.completedLiabilities.consentOrder
}

const completedPayouts = async (consentOrder: ConsentOrder): Promise<ConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: completedPayoutsQuery,
    variables: {
      input: {
        slug: consentOrder.slug
      }
    }
  })

  return data.completedPayouts.consentOrder
}

const createPaymentIntent = async (consentOrder: ConsentOrder, name: string, email: string): Promise<string> => {
  const { data } = await apolloClient.mutate({
    mutation: createPaymentIntentQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        name,
        email
      }
    }
  })

  return data.createPaymentIntent.clientSecret
}

const updateBankAccountAsset = async (
  consentOrder: ConsentOrder, asset: Asset, name: string,
  bankAccountAsset: BankAccountAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: updateBankAccountAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        assetId: asset.id,
        name,
        nameOfBankOrFinancialInstitution: bankAccountAsset.nameOfBankOrFinancialInstitution,
        bankAccountType: bankAccountAsset.bankAccountType,
        lastFourDigitsOfAccount: bankAccountAsset.lastFourDigitsOfAccount,
        nameAccountHeldBy: bankAccountAsset.nameAccountHeldBy,
        howFundsDivided: bankAccountAsset.howFundsDivided,
        howFundsDividedSplit: bankAccountAsset.howFundsDividedSplit,
        fundsDividedFixedSum: bankAccountAsset.fundsDividedFixedSum,
        fundsDividedPercentageSplit: bankAccountAsset.fundsDividedPercentageSplit
      }
    }
  })

  return data.updateBankAccountAsset
}

const updateBoatAsset = async (
  consentOrder: ConsentOrder, asset: Asset, name: string,
  boatAsset: BoatAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: updateBoatAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        assetId: asset.id,
        name,
        boatName: boatAsset.boatName,
        nameBoatHeldBy: boatAsset.nameBoatHeldBy,
        whoKeepingBoat: boatAsset.whoKeepingBoat,
        transferPeriodQuantity: boatAsset.transferPeriodQuantity,
        transferPeriod: boatAsset.transferPeriod,
        saleProceeds: boatAsset.saleProceeds,
        saleProceedsPercentageSplit: boatAsset.saleProceedsPercentageSplit,
        saleProceedsFixedSum: boatAsset.saleProceedsFixedSum
      }
    }
  })

  return data.updateBoatAsset
}

const updateBusinessAsset = async (
  consentOrder: ConsentOrder, asset: Asset, name: string,
  businessAsset: BusinessAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: updateBusinessAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        assetId: asset.id,
        name,
        businessName: businessAsset.businessName,
        nameBusinessHeld: businessAsset.nameBusinessHeld,
        whoKeepingBusiness: businessAsset.whoKeepingBusiness,
        transferPeriodQuantity: businessAsset.transferPeriodQuantity,
        transferPeriod: businessAsset.transferPeriod,
        saleProceeds: businessAsset.saleProceeds,
        saleProceedsPercentageSplit: businessAsset.saleProceedsPercentageSplit,
        saleProceedsFixedSum: businessAsset.saleProceedsFixedSum,
        willThereBeCapitalGains: businessAsset.willThereBeCapitalGains,
        capitalGainsTaxResponsibility: businessAsset.capitalGainsTaxResponsibility,
        capitalGainsTaxSplit: businessAsset.capitalGainsTaxSplit
      }
    }
  })

  return data.updateBusinessAsset
}

const updateCompanyAsset = async (
  consentOrder: ConsentOrder, asset: Asset, name: string,
  companyAsset: CompanyAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: updateCompanyAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        assetId: asset.id,
        name,
        companyName: companyAsset.companyName,
        nameCompanyHeldBy: companyAsset.nameCompanyHeldBy,
        whoKeepingCompany: companyAsset.whoKeepingCompany,
        transferPeriodQuantity: companyAsset.transferPeriodQuantity,
        transferPeriod: companyAsset.transferPeriod,
        woundUpAssetsSplit: companyAsset.woundUpAssetsSplit
      }
    }
  })

  return data.updateCompanyAsset
}

const updateMotorVehicleAsset = async (
  consentOrder: ConsentOrder, asset: Asset, name: string,
  motorVehicleAsset: MotorVehicleAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: updateMotorVehicleAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        assetId: asset.id,
        name,
        motorVehicleYear: motorVehicleAsset.motorVehicleYear,
        motorVehicleMake: motorVehicleAsset.motorVehicleMake,
        motorVehicleModel: motorVehicleAsset.motorVehicleModel,
        nameMotorVehicleHeldBy: motorVehicleAsset.nameMotorVehicleHeldBy,
        whoKeepingMotorVehicle: motorVehicleAsset.whoKeepingMotorVehicle,
        transferPeriodQuantity: motorVehicleAsset.transferPeriodQuantity,
        transferPeriod: motorVehicleAsset.transferPeriod,
        saleProceeds: motorVehicleAsset.saleProceeds,
        saleProceedsPercentageSplit: motorVehicleAsset.saleProceedsPercentageSplit,
        saleProceedsFixedSum: motorVehicleAsset.saleProceedsFixedSum
      }
    }
  })

  return data.updateMotorVehicleAsset
}

const updatePersonalPossessionAsset = async (
  consentOrder: ConsentOrder, asset: Asset, name: string,
  personalPossessionAsset: PersonalPossessionAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: updatePersonalPossessionAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        assetId: asset.id,
        name,
        description: personalPossessionAsset.description,
        whoKeepingPersonalPossession: personalPossessionAsset.whoKeepingPersonalPossession
      }
    }
  })

  return data.updatePersonalPossessionAsset
}

const updateRealEstateAsset = async (
  consentOrder: ConsentOrder, asset: Asset, name: string,
  realEstateAsset: RealEstateAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: updateRealEstateAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        assetId: asset.id,
        name,
        addressPlaceId: realEstateAsset.addressPlaceId,
        addressStreet: realEstateAsset.addressStreet,
        addressSuburb: realEstateAsset.addressSuburb,
        addressState: realEstateAsset.addressState,
        addressCountry: realEstateAsset.addressCountry,
        obtainedTitleSearch: realEstateAsset.obtainedTitleSearch,
        landDescriptionInCertificateOfTitle: realEstateAsset.landDescriptionInCertificateOfTitle,
        certificateOfTitleVolumeNumber: realEstateAsset.certificateOfTitleVolumeNumber,
        certificateOfTitleFolioNumber: realEstateAsset.certificateOfTitleFolioNumber,
        namePropertyHeldBy: realEstateAsset.namePropertyHeldBy,
        whoKeepingProperty: realEstateAsset.whoKeepingProperty,
        propertyType: realEstateAsset.propertyType,
        residingPendingSettlement: realEstateAsset.residingPendingSettlement,
        receivingRentalIncome: realEstateAsset.receivingRentalIncome,
        transferPeriodQuantity: realEstateAsset.transferPeriodQuantity,
        transferPeriod: realEstateAsset.transferPeriod,
        rentalIncomeSplit: realEstateAsset.rentalIncomeSplit,
        saleProceeds: realEstateAsset.saleProceeds,
        saleProceedsPercentageSplit: realEstateAsset.saleProceedsPercentageSplit,
        saleProceedsFixedSum: realEstateAsset.saleProceedsFixedSum,
        willThereBeCapitalGains: realEstateAsset.willThereBeCapitalGains,
        capitalGainsTaxResponsibility: realEstateAsset.capitalGainsTaxResponsibility,
        capitalGainsTaxSplit: realEstateAsset.capitalGainsTaxSplit,
        saleShortFall: realEstateAsset.saleShortFall,
        saleShortFallResponsibility: realEstateAsset.saleShortFallResponsibility,
        saleShortFallSplit: realEstateAsset.saleShortFallSplit
      }
    }
  })

  return data.updateRealEstateAsset
}

const updateSharesAsset = async (
  consentOrder: ConsentOrder, asset: Asset, name: string,
  sharesAsset: SharesAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: updateSharesAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        assetId: asset.id,
        name,
        nameOfShareHolding: sharesAsset.nameOfShareHolding,
        nameSharesHeldBy: sharesAsset.nameSharesHeldBy,
        whoKeepingShares: sharesAsset.whoKeepingShares,
        transferPeriodQuantity: sharesAsset.transferPeriodQuantity,
        transferPeriod: sharesAsset.transferPeriod,
        saleProceeds: sharesAsset.saleProceeds,
        saleProceedsPercentageSplit: sharesAsset.saleProceedsPercentageSplit,
        saleProceedsFixedSum: sharesAsset.saleProceedsFixedSum,
        willThereBeCapitalGains: sharesAsset.willThereBeCapitalGains,
        capitalGainsTaxResponsibility: sharesAsset.capitalGainsTaxResponsibility,
        capitalGainsTaxSplit: sharesAsset.capitalGainsTaxSplit
      }
    }
  })

  return data.updateSharesAsset
}

const updateTrustAsset = async (
  consentOrder: ConsentOrder, asset: Asset, name: string,
  trustAsset: TrustAsset
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: updateTrustAssetQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        assetId: asset.id,
        name,
        nameOfTrust: trustAsset.nameOfTrust,
        whoAreTheBeneficiaries: trustAsset.whoAreTheBeneficiaries,
        whoWillRetainTheTrust: trustAsset.whoWillRetainTheTrust,
        transferPeriodQuantity: trustAsset.transferPeriodQuantity,
        transferPeriod: trustAsset.transferPeriod,
        woundUpAssetsSplit: trustAsset.woundUpAssetsSplit
      }
    }
  })

  return data.updateTrustAsset
}

const updateMortgageLiability = async (
  consentOrder: ConsentOrder, liability: Liability, name: string, mortgageLiability: MortgageLiability
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: updateMortgageLiabilityQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        liabilityId: liability.id,
        name,
        nameOfBankOrFinancialInstitution: mortgageLiability.nameOfBankOrFinancialInstitution,
        lastFourDigitsOfAccount: mortgageLiability.lastFourDigitsOfAccount,
        nameAccountHeldBy: mortgageLiability.nameAccountHeldBy,
        paymentsPendingSettlementResponsibility:
          mortgageLiability.paymentsPendingSettlementResponsibility,
        paymentsPendingSettlementSplit: mortgageLiability.paymentsPendingSettlementSplit,
        assetId: mortgageLiability.assetId
      }
    }
  })

  return data.updateMortgageLiability
}

const updateLoanLiability = async (
  consentOrder: ConsentOrder, liability: Liability, name: string, loanLiability: LoanLiability
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: updateLoanLiabilityQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        liabilityId: liability.id,
        name,
        nameOfBankOrFinancialInstitution: loanLiability.nameOfBankOrFinancialInstitution,
        lastFourDigitsOfAccount: loanLiability.lastFourDigitsOfAccount,
        nameAccountHeldBy: loanLiability.nameAccountHeldBy,
        isAttachedToAnAsset: loanLiability.isAttachedToAnAsset,
        whoIsResponsibleForThisLoan: loanLiability.whoIsResponsibleForThisLoan,
        transferPeriodQuantity: loanLiability.transferPeriodQuantity,
        transferPeriod: loanLiability.transferPeriod,
        paymentsPendingSettlementResponsibility:
          loanLiability.paymentsPendingSettlementResponsibility,
        paymentsPendingSettlementSplit: loanLiability.paymentsPendingSettlementSplit,
        assetId: loanLiability.assetId != null ? loanLiability.assetId : ''
      }
    }
  })

  return data.updateLoanLiability
}

const updateCreditCardLiability = async (
  consentOrder: ConsentOrder, liability: Liability, name: string, creditCardLiability: CreditCardLiability
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: updateCreditCardLiabilityQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        liabilityId: liability.id,
        name,
        nameOfBankOrFinancialInstitution: creditCardLiability.nameOfBankOrFinancialInstitution,
        lastFourDigitsOfAccount: creditCardLiability.lastFourDigitsOfAccount,
        nameAccountHeldBy: creditCardLiability.nameAccountHeldBy,
        whoIsKeepingCreditCard: creditCardLiability.whoIsKeepingCreditCard,
        transferPeriodQuantity: creditCardLiability.transferPeriodQuantity,
        transferPeriod: creditCardLiability.transferPeriod
      }
    }
  })

  return data.updateCreditCardLiability
}

const updateOtherLiability = async (
  consentOrder: ConsentOrder, liability: Liability, name: string, otherLiability: OtherLiability
): Promise<UpdateConsentOrder> => {
  const { data } = await apolloClient.mutate({
    mutation: updateOtherLiabilityQuery,
    variables: {
      input: {
        slug: consentOrder.slug,
        liabilityId: liability.id,
        name,
        nameOfLenderOrCreditor: otherLiability.nameOfLenderOrCreditor,
        description: otherLiability.description,
        nameLiabilityHeld: otherLiability.nameLiabilityHeld,
        whoIsResponsibleForThisLiability: otherLiability.whoIsResponsibleForThisLiability,
        isAttachedToAnAsset: otherLiability.isAttachedToAnAsset,
        estimatedAmount: otherLiability.estimatedAmount,
        assetId: otherLiability.assetId != null ? otherLiability.assetId : ''
      }
    }
  })

  return data.updateOtherLiability
}

export default ConsentOrder
export {
  addAsset, addLiability, addSuperannuation, createConsentOrder, getPreview,
  getConsentOrder, updatePersonalInformation, savePayout, deleteAsset,
  deleteLiability, deletePayout, deleteSuperannuation, acceptDisclaimers, completedAssets,
  completedLiabilities, completedPayouts, createPaymentIntent, updatePersonalPossessionAsset,
  updateRealEstateAsset, updateBankAccountAsset, updateSharesAsset, updateTrustAsset,
  updateBusinessAsset, updateCompanyAsset, updateBoatAsset, updateMotorVehicleAsset,
  updateMortgageLiability, updateLoanLiability, updateCreditCardLiability,
  updateOtherLiability
}
