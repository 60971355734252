import React from 'react'
import { Link, useLocation } from 'react-router-dom'

interface NavItemProps {
  to: string
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element
  title: string
  enabled: boolean
}

const NavItem = ({ to, icon, title, enabled }: NavItemProps): JSX.Element => {
  const location = useLocation()

  const active = location.pathname === to
  const linkClassNames = (active
    ? 'bg-brand-secondary text-white'
    : 'text-gray-300 hover:bg-brand-secondary-lite hover:text-white') +
    ' group flex items-center px-2 py-2 text-base md:text-sm font-medium rounded-md'
  const iconClassNames = (active
    ? 'text-white group-hover:white mr-4'
    : 'text-gray-400 group-hover:text-white mr-3') + ' flex-shrink-0 h-6 w-6'

  if (!enabled) {
    return (
      <span
        className={linkClassNames}
      >
        {icon({ className: iconClassNames })}
        {title}
      </span>
    )
  }

  return (
    <Link
      to={to}
      className={linkClassNames}
    >
      {icon({ className: iconClassNames })}
      {title}
    </Link>
  )
}

export default NavItem
