import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const addOtherLiabilityQuery = gql`
  mutation AddOtherLiabilityQuery($input: AddOtherLiabilityInput!) {
    addOtherLiability(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
      validationErrors {
        name
        messages
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default addOtherLiabilityQuery
