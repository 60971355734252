import { gql, MutationTuple, useMutation } from '@apollo/client'

import { consentOrderFieldsFragment } from '../../queries/fragments'
import { ConsentOrder, ValidationError } from '../../models'

const UPDATE_SELF_MANAGED_SUPERANNUATION_MUTATION = gql`
  mutation UpdateSelfManagedSuperannuationMutation($input: UpdateSelfManagedSuperannuationInput!) {
    updateSelfManagedSuperannuation(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
      validationErrors {
        name
        messages
      }
    }
  }

  ${consentOrderFieldsFragment}
`

interface UpdateSelfManagedSuperannuationData {
  updateSelfManagedSuperannuation: {
    consentOrder: ConsentOrder
    validationErrors: ValidationError[]
  }
}

interface UpdateSelfManagedSuperannuationVariables {
  input: {
    slug: string
    fundId: string
    name: string
    nameOfSelfManagedFund: string
    members: string
    whoKeepingFund: string
    paymentToExitingMember: string
    transferPeriodQuantity: string
    transferPeriod: string
    exitingMemberBalance: string
    keepingMemberBalance: string
    exitingMemberKeeping: string
    exitingMemberAdditionalPortion: string
    fundTrustees: string
    nameOfCorporateTrust: string
  }
}

const useUpdateSelfManagedSuperannuationMutation = (): MutationTuple<UpdateSelfManagedSuperannuationData, UpdateSelfManagedSuperannuationVariables> => {
  return useMutation(UPDATE_SELF_MANAGED_SUPERANNUATION_MUTATION)
}

export default useUpdateSelfManagedSuperannuationMutation
