import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const consentOrderQuery = gql`
  query ConsentOrderQuery($slug: String!) {
    consentOrder(slug: $slug) {
      ...consentOrderFields
    }
  }

  ${consentOrderFieldsFragment}
`

export default consentOrderQuery
