import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const updateBusinessAssetQuery = gql`
  mutation UpdateBusinessAssetQuery($input: UpdateBusinessAssetInput!) {
    updateBusinessAsset(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
      validationErrors {
        name
        messages
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default updateBusinessAssetQuery
