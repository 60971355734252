import React from 'react'

import { TextInput } from '.'
import { ValidationError } from '../models'

interface FixedSumSplitProps {
  display: boolean
  name: string
  value: string
  fixedSumSplitAmountLabel: string
  fixedSumSplitBalanceLabel: string
  setValue: (value: string) => void
  validationName: string
  validationErrors: ValidationError[]
}

const FixedSumSplit = ({
  name, value, display, fixedSumSplitAmountLabel, fixedSumSplitBalanceLabel, setValue,
  validationErrors, validationName
}: FixedSumSplitProps): JSX.Element => {
  if (!display) {
    return (<></>)
  }

  return (
    <>
      <div className='flex space-x-8 items-center'>
        <label className='pt-2 text-sm font-medium text-gray-700'>
          {fixedSumSplitAmountLabel}
        </label>
        <div className='flex'>
          <label className='pt-2 mr-1'>$</label>
          <TextInput
            displayLabel={false}
            name={name}
            value={value}
            onChange={setValue}
            numberic
            validationErrorName={validationName}
            validationErrors={validationErrors}
          />
        </div>
      </div>
      <div className='space-x-4'>
        <label className='text-sm font-medium text-gray-700'>
          {fixedSumSplitBalanceLabel}
        </label>
        <label className='text-sm font-medium text-gray-700'>Balance</label>
      </div>
    </>
  )
}

FixedSumSplit.defaultProps = {
  validationName: 'saleProceedsFixedSum',
  fixedSumSplitAmountLabel: 'Me',
  fixedSumSplitBalanceLabel: 'My Ex',
  validationErrors: []
}

export default FixedSumSplit
