import React from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

import { ValidationError } from '../models'
import { ValidationMessages } from './'

interface InputProps {
  title: string
  name: string
  validationErrorName: string
  displayLabel: boolean
  type: string
  value: string
  placeholder: string
  onChange: (value: string) => void
  validationErrors: ValidationError[]
  disabled: boolean
}

const Input = ({
  title, name, displayLabel, type, placeholder, value, onChange, validationErrors,
  validationErrorName, disabled
}: InputProps): JSX.Element => {
  const valueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    if (onChange !== null) {
      onChange(value)
    }
  }

  const validationName = validationErrorName != null ? validationErrorName : name
  const validationError = validationErrors.find((v) => v.name === validationName)

  const nameAsId = name.toLocaleLowerCase().replace(' ', '-')

  return (
    <>
      <div>
        <label
          htmlFor={nameAsId}
          className={`block text-sm text-gray-700 ${displayLabel ? '' : 'sr-only'}`}
        >
          {title != null ? title : name}
        </label>
        <div className='mt-1 relative'>
          <input
            id={nameAsId}
            name={nameAsId}
            type={type}
            placeholder={placeholder}
            onChange={valueChanged}
            value={value}
            disabled={disabled}
            className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm \
                      placeholder-gray-400 focus:outline-none sm:text-sm \
                       ${validationError != null
                ? 'border-red-300 text-red-900 placeholder-red-300 ' +
                'focus:ring-red-500 focus:border-red-500'
                : 'border-gray-300 focus:ring-brand-secondary focus:border-brand-secondary'}`}
          />
          {validationError != null && (
            <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
              <ExclamationCircleIcon className='h-5 w-5 text-red-500' />
            </div>
          )}
        </div>
        <ValidationMessages
          name={validationName}
          validationErrors={validationErrors}
        />
      </div>
    </>
  )
}
Input.defaultProps = {
  title: null,
  displayLabel: true,
  validationErrorName: null,
  validationErrors: [],
  placeholder: '',
  value: '',
  onChange: null,
  disabled: false
}

export default Input
