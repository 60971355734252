import React from 'react'

interface CheckboxProps {
  id: string
  name: string
  title: string
  info: string
  checked: boolean
  onChange: (checked: boolean) => void
}

const Checkbox = ({ id, name, title, info, checked, onChange }: CheckboxProps): JSX.Element => {
  return (
    <div className='relative flex items-start'>
      <div className='flex items-center h-5'>
        <input
          id={id}
          name={name}
          type='checkbox'
          className='focus:ring-brand-secondary h-4 w-4 text-brand border-gray-300 rounded'
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
      </div>
      <div className='ml-3 text-sm'>
        <label
          htmlFor={id}
          className='text-sm font-medium text-gray-700'
        >
          {title}
        </label>
        <p className='text-gray-500'>
          {info}
        </p>
      </div>
    </div>
  )
}
Checkbox.defaultProps = {
  info: ''
}

export default Checkbox
