import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Button, TextInput, CommercialSuper, SelfManagedSuper
} from '../components'
import { useConsentOrder } from './consent-order-route'
import { useUpdateCommercialSuperannuationMutation, useUpdateSelfManagedSuperannuationMutation } from '../graphql/mutations'
import { ValidationError } from '../models'

const EditSuperannuationRoute = (): JSX.Element => {
  const { fundId } = useParams()
  const navigate = useNavigate()
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const { consentOrder, setConsentOrder } = useConsentOrder()
  const [superannuation] = useState(consentOrder.superannuations.find((a) => a.id == fundId))
  const [name, setName] = useState(superannuation != null ? superannuation.name : '')

  const [commercialFund, setCommercialFund] = useState(superannuation?.commercialSuperannuation)
  const [selfManagedFund, setSelfManagedFund] = useState(superannuation?.selfManagedSuperannuation)

  const [updateSelfManagedFund, { loading: savingSelfManagedFund }] = useUpdateSelfManagedSuperannuationMutation()
  const [updateCommercialFund, { loading: savingCommercialFund }] = useUpdateCommercialSuperannuationMutation()

  const saving = savingSelfManagedFund || savingCommercialFund

  const save = async (): Promise<void> => {
    if (superannuation == null) return

    setValidationErrors([])

    let result = null

    if (commercialFund != null) {
      const { __typename, ...input } = commercialFund

      const response = await updateCommercialFund({
        variables: {
          input: {
            slug: consentOrder.slug,
            fundId: superannuation.id,
            name: name,
            ...input
          }
        }
      })

      if (response == null || response.data == null) return
      result = response.data.updateCommercialSuperannuation
    }

    if (selfManagedFund != null) {
      const { __typename, ...input } = selfManagedFund

      const response = await updateSelfManagedFund({
        variables: {
          input: {
            slug: consentOrder.slug,
            fundId: superannuation.id,
            name: name,
            ...input
          }
        }
      })

      if (response == null || response.data == null) return
      result = response.data.updateSelfManagedSuperannuation
    }

    if (result == null) return

    if (result.validationErrors.length === 0) {
      setConsentOrder(result.consentOrder)
      navigate(`/consent-orders/${consentOrder.slug}/superannuation`)
    } else {
      setValidationErrors(result.validationErrors)
    }
  }

  return (
    <div
      className='fixed z-10 inset-0 overflow-y-auto'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div
        className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block \
                   sm:p-0'
      >
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
        />
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'
        />

        <div
          className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden \
                     shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl \
                     sm:w-full'
        >
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className='space-y-4'>
              <div className='mt-3 text-center sm:mt-0 sm:text-left'>
                <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-title'>
                  Edit Superannuation
                </h3>
                <hr className='mt-3' />
              </div>

              <form className='space-y-6'>
                <TextInput
                  title='Nickname'
                  name='name'
                  value={name}
                  onChange={setName}
                  validationErrorName='superannuation.name'
                  validationErrors={validationErrors}
                />

                {commercialFund != null && (
                  <CommercialSuper
                    consentOrder={consentOrder}
                    fund={commercialFund}
                    setFund={setCommercialFund}
                    validationErrors={validationErrors}
                  />
                )}

                {selfManagedFund != null && (
                  <SelfManagedSuper
                    fund={selfManagedFund}
                    setFund={setSelfManagedFund}
                    validationErrors={validationErrors}
                  />
                )}
              </form>
            </div>
          </div>

          <div className='bg-gray-50 px-4 py-3 sm:px-6 flex justify-end space-x-2'>
            <Button
              type='white'
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              onClick={save}
              loading={saving}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditSuperannuationRoute
