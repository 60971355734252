import React from 'react'

import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { ValidationError } from '../models'

interface SelectInputProps {
  title: string
  name: string
  displayLabel: boolean
  value: string | number | null
  onChange: (value: string) => void
  validationErrorName: string
  validationErrors: ValidationError[]
  options: any[] | null
  idProperty: string
  displayProperty: string
  disabled: boolean
}

const SelectInput = ({
  title, name, displayLabel, value, onChange, validationErrors, options, idProperty, displayProperty,
  validationErrorName, disabled
}: SelectInputProps): JSX.Element => {
  const valueChanged = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const value = event.target.value
    if (onChange !== null) {
      onChange(value)
    }
  }

  if (options === null) { options = [] }

  const validationName = validationErrorName != null ? validationErrorName : name
  const validationError = validationErrors.find((v) => v.name === validationName)

  const nameAsId = name.toLocaleLowerCase().replace(' ', '-')

  return (
    <>
      <div>
        <label
          htmlFor={nameAsId}
          className={`block text-sm font-medium text-gray-700 ${displayLabel ? '' : 'sr-only'}`}
        >
          {title != null ? title : name}
        </label>
        <div className='mt-1 relative'>
          <select
            id={nameAsId}
            name={nameAsId}
            autoComplete='on'
            onChange={valueChanged}
            value={value === null ? '' : value}
            disabled={disabled}
            // className='focus:border-indigo-500 focus:outline-none focus:ring-indigo-500'
            className={
              'mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-16 text-base ' +
              'focus:outline-none sm:text-sm placeholder-gray-400' +
              `${validationError != null
                ? 'border-red-300 text-red-900 placeholder-red-300 ' +
                'focus:ring-red-500 focus:border-red-500'
                : 'border-gray-300 focus:ring-brand-secondary focus:border-brand-secondary'}
              `.trim()}
          >
            <option value=''>Select {title}</option>
            {options.map((option) => (
              <option
                key={option[idProperty]}
                value={option[idProperty]}
              >
                {option[displayProperty]}
              </option>
            ))}
          </select>
          {validationError != null && (
            <div className='absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none'>
              <ExclamationCircleIcon className='h-5 w-5 text-red-500' />
            </div>
          )}
        </div>
        {validationError != null && (
          <p className='mt-2 text-sm text-red-600'>
            {validationError.messages.join(', ')}
          </p>
        )}
      </div>
    </>
  )
}
SelectInput.defaultProps = {
  title: null,
  displayLabel: true,
  validationErrorName: null,
  validationErrors: [],
  value: '',
  onChange: null,
  options: [],
  disabled: false
}

export default SelectInput
