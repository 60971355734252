import { gql, QueryResult, QueryHookOptions, useQuery } from '@apollo/client'

import { consentOrderFieldsFragment } from '../../queries/fragments'
import { ConsentOrder } from '../../models'

const QUERY = gql`
  query ConsentOrders($limit: Int, $page: Int) {
    consentOrders(all: true, limit: $limit, page: $page) {
      ...consentOrderFields
    }
  }

  ${consentOrderFieldsFragment}
`

interface Data {
  consentOrders: ConsentOrder[]
}

interface Variables {
  limit: number,
  page: number
}

const useConsentOrdersListQuery = (
  options?: QueryHookOptions<Data, Variables>
): QueryResult<Data, Variables> => {
  return useQuery<Data, Variables>(QUERY, options)
}

export default useConsentOrdersListQuery
