import React from 'react'

import {
  Input, RadioButton, FundsDivided, TransferPeriod, CapitalGainsTax, ValidationMessages
} from '../components'
import { SharesAsset, ValidationError } from '../models'

interface SharesProps {
  asset: SharesAsset
  setSharesAsset: (asset: SharesAsset) => void
  validationErrors: ValidationError[]
}

const Shares = ({ asset, setSharesAsset, validationErrors }: SharesProps): JSX.Element => {
  return (
    <>
      <div className='sm:col-span-4'>
        <Input
          title='Name of Shareholding'
          name='name-of-shareholding'
          type='text'
          value={asset.nameOfShareHolding}
          onChange={(value) => setSharesAsset({ ...asset, nameOfShareHolding: value })}
          validationErrorName='nameOfShareHolding'
          validationErrors={validationErrors}
        />
      </div>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>In whose name is this held?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='shares-held-me'
            name='shares-held'
            title='Me'
            value='me'
            checked={asset.nameSharesHeldBy === 'me'}
            onChange={(value) => setSharesAsset({ ...asset, nameSharesHeldBy: value })}
          />
          <RadioButton
            id='shares-held-ex'
            name='shares-held'
            title='My Ex'
            value='ex'
            checked={asset.nameSharesHeldBy === 'ex'}
            onChange={(value) => setSharesAsset({ ...asset, nameSharesHeldBy: value })}
          />
          <RadioButton
            id='shares-held-both'
            name='shares-held'
            title='Both Me and My Ex'
            value='both'
            checked={asset.nameSharesHeldBy === 'both'}
            onChange={(value) => setSharesAsset({ ...asset, nameSharesHeldBy: value })}
          />
        </div>
        <ValidationMessages
          name='nameSharesHeldBy'
          validationErrors={validationErrors}
        />
      </fieldset>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>Who is keeping this?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='shares-kept-by-me'
            name='shares-kept-by'
            title='Me'
            value='me'
            checked={asset.whoKeepingShares === 'me'}
            onChange={(value) => setSharesAsset({ ...asset, whoKeepingShares: value })}
          />
          <RadioButton
            id='shares-kept-by-ex'
            name='shares-kept-by'
            title='My Ex'
            value='ex'
            checked={asset.whoKeepingShares === 'ex'}
            onChange={(value) => setSharesAsset({ ...asset, whoKeepingShares: value })}
          />
          <RadioButton
            id='shares-kept-by-both'
            name='shares-kept-by'
            title='Sale'
            value='sale'
            checked={asset.whoKeepingShares === 'sale'}
            onChange={(value) => setSharesAsset({ ...asset, whoKeepingShares: value })}
          />
        </div>
        <ValidationMessages
          name='whoKeepingShares'
          validationErrors={validationErrors}
        />
      </fieldset>

      <TransferPeriod
        display={
          asset.nameSharesHeldBy !== asset.whoKeepingShares && asset.whoKeepingShares !== 'sale' &&
          asset.whoKeepingShares !== ''
        }
        name='shares-transfer'
        title='What timeframe do you need to do the transfer?'
        transferPeriodQuantity={asset.transferPeriodQuantity}
        setTransferPeriodQuantity={(value) => setSharesAsset(
          { ...asset, transferPeriodQuantity: value }
        )}
        transferPeriod={asset.transferPeriod}
        setTransferPeriod={(value) => setSharesAsset({ ...asset, transferPeriod: value })}
        validationErrors={validationErrors}
      />

      {asset.whoKeepingShares === 'sale' && (
        <>
          <FundsDivided
            display={asset.whoKeepingShares === 'sale'}
            name='how-sale-proceeds-divided'
            title='How are the net sale proceeds being divided?'
            splitTypeValue={asset.saleProceeds}
            setSplitType={(value) => setSharesAsset({ ...asset, saleProceeds: value })}
            percentageSplitValue={asset.saleProceedsPercentageSplit}
            setPercentageSplitValue={(value) => setSharesAsset(
              { ...asset, saleProceedsPercentageSplit: value }
            )}
            fixedSumValue={asset.saleProceedsFixedSum}
            setFixedSumValue={(value) => setSharesAsset({ ...asset, saleProceedsFixedSum: value })}
            validationErrors={validationErrors}
          />

          <CapitalGainsTax
            name='property-capital-gains-tax'
            title='Will there be any Capital Gains Tax?'
            willThereBeCapitalGains={asset.willThereBeCapitalGains}
            setWillThereBeCapitalGains={(value) => setSharesAsset(
              { ...asset, willThereBeCapitalGains: value }
            )}
            capitalGainsTaxResponsibility={asset.capitalGainsTaxResponsibility}
            setCapitalGainsTaxResponsibility={(value) => setSharesAsset(
              { ...asset, capitalGainsTaxResponsibility: value }
            )}
            percentageSplitValue={asset.capitalGainsTaxSplit}
            setPercentageSplitValue={(value) => setSharesAsset(
              { ...asset, capitalGainsTaxSplit: value }
            )}
            validationErrors={validationErrors}
          />
        </>
      )}
    </>
  )
}

export default Shares
