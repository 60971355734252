import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const updateCompanyAssetQuery = gql`
  mutation UpdateCompanyAssetQuery($input: UpdateCompanyAssetInput!) {
    updateCompanyAsset(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
      validationErrors {
        name
        messages
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default updateCompanyAssetQuery
