import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/solid'
import { CashIcon } from '@heroicons/react/outline'

import {
  Button, RadioButton, TextInput, TransferPeriod, SelectInput, ConfirmationModal
} from '../components'
import { useConsentOrder } from './consent-order-route'
import { savePayout, deletePayout, completedPayouts } from '../models/consent-order'
import ValidationError from '../models/validation-error'
import Asset from '../models/asset'

const PayoutRoute = (): JSX.Element => {
  const navigate = useNavigate()
  const { consentOrder, setConsentOrder } = useConsentOrder()
  const [payout, setPayout] = useState(consentOrder.payout)
  const [saving, setSaving] = useState(false)
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const [assets, setAssets] = useState<Asset[]>([])
  const [hasMajorAssets, setHasMajorAssets] = useState(false)

  useEffect(() => {
    const majorAssets = consentOrder.assets
      .filter(
        (a) => a.assetType === 'real-estate' || a.assetType === 'business' ||
          a.assetType === 'trust' || a.assetType === 'company'
      )

    if (majorAssets.length > 0) {
      setAssets(majorAssets)
      setHasMajorAssets(true)
      return
    }

    setAssets(consentOrder.assets.filter((a) => a.toBeTransfered))
  }, [])

  const addPayout = (): void => {
    let payoutTimeframe = ''

    if (assets.length === 0) {
      payoutTimeframe = 'specified'
    } else if (hasMajorAssets) {
      payoutTimeframe = 'with-asset'
    }

    setPayout({
      whoReceivingPayout: '',
      payoutAmount: '',
      payoutTimeframe: payoutTimeframe,
      transferPeriodQuantity: '30',
      transferPeriod: 'days',
      assetId: null
    })
  }

  const save = async (): Promise<void> => {
    setSaving(true)

    if (payout === null) {
      const result = await completedPayouts(consentOrder)
      setConsentOrder(result)
      setSaving(false)
      navigate(`/consent-orders/${consentOrder.slug}/superannuation`)
      return
    }

    const result = await savePayout(consentOrder, payout)

    setSaving(false)

    if (result.validationErrors.length === 0) {
      setConsentOrder(result.consentOrder)
      navigate(`/consent-orders/${consentOrder.slug}/superannuation`)
    } else {
      setValidationErrors(result.validationErrors)
    }
  }

  const deleteConfirmed = async (): Promise<void> => {
    setDeleting(true)
    const updatedConsentOrder = await deletePayout(consentOrder)

    setDeleting(false)
    setConfirmingDelete(false)
    setConsentOrder(updatedConsentOrder)
    setPayout(null)

    setDeleting(false)
  }

  return (
    <>
      <ConfirmationModal
        title='Confirm Delete'
        message='Are you sure you want to delete the payout?'
        processing={deleting}
        open={confirmingDelete}
        onClose={() => setConfirmingDelete(false)}
        confirmAction={deleteConfirmed}
        actionButtonTitle='Delete'
      />
      <div className='rounded-lg bg-white p-4'>
        <form className='space-y-8'>
          <div>
            <div>
              <h3 className='text-lg leading-6 font-medium text-gray-900'>
                Payout
              </h3>
              <p className='mt-1 text-sm text-gray-500'>
                Add any agreed payment between you and your ex. A payout is
                a cash payment made to you or your ex from the other.
              </p>
            </div>

            <div className='flex justify-end'>
              {payout !== null && !consentOrder.purchased && (
                <Button
                  onClick={() => setConfirmingDelete(true)}
                >
                  Remove
                </Button>
              )}
            </div>
          </div>

          <hr />

          {payout === null && (
            <div className='text-center'>
              <CashIcon className='h-12 w-12 text-gray-400 mx-auto' />
              <h3 className='mt-2 text-sm font-medium text-gray-900'>No Payout</h3>
              <p className='mt-1 text-sm text-gray-500'>
                A payout is a cash payment made to you or your ex from the other.
              </p>
              <div className='mt-6'>
                {!consentOrder.purchased && (
                  <Button
                    className='inline-flex items-center'
                    onClick={addPayout}
                  >
                    <>
                      <PlusIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
                      Add Payout
                    </>
                  </Button>
                )}
              </div>
            </div>
          )}

          {payout !== null && (
            <>
              <fieldset className='mt-6'>
                <div>
                  <p className='text-sm text-gray-900'>Who is receiving the Payout?</p>
                </div>
                <div className='mt-4 flex flex-wrap space-x-4'>
                  <RadioButton
                    id='receiving-payout-me'
                    name='receiving-payout'
                    title='Me'
                    value='me'
                    checked={payout.whoReceivingPayout === 'me'}
                    onChange={(value) => setPayout({ ...payout, whoReceivingPayout: value })}
                    disabled={consentOrder.purchased}
                  />
                  <RadioButton
                    id='receiving-payout-ex'
                    name='receiving-payout'
                    title='My Ex'
                    value='ex'
                    checked={payout.whoReceivingPayout === 'ex'}
                    onChange={(value) => setPayout({ ...payout, whoReceivingPayout: value })}
                    disabled={consentOrder.purchased}
                  />
                </div>
                {validationErrors.some((error) => error.name.startsWith('whoReceivingPayout')) && (
                  <p className='mt-2 text-sm text-red-600'>must be selected</p>
                )}
              </fieldset>

              <div>
                <label className='pt-2 text-sm'>How much is the Payout?</label>
                <div className='flex'>
                  <label className='pt-2 mr-1'>$</label>
                  <TextInput
                    displayLabel={false}
                    name='payoutAmount'
                    value={payout.payoutAmount}
                    validationErrors={validationErrors}
                    onChange={(value) => setPayout({ ...payout, payoutAmount: value })}
                    numberic
                    disabled={consentOrder.purchased}
                  />
                </div>
              </div>

              {!hasMajorAssets && assets.length > 0 && (
                <fieldset className='mt-6'>
                  <div>
                    <p className='text-sm text-gray-900'>
                      What timeframe do you need for the Payout?
                    </p>
                  </div>
                  <div className='mt-4 md:flex md:flex-wrap md:space-x-4'>
                    <RadioButton
                      id='payout-timeframe-specified'
                      name='payout-timeframe'
                      title='Specified Timeframe'
                      value='specified'
                      checked={payout.payoutTimeframe === 'specified'}
                      onChange={(value) => setPayout({ ...payout, payoutTimeframe: value })}
                      disabled={consentOrder.purchased}
                    />
                    <RadioButton
                      id='payout-timeframe-with-asset'
                      name='payout-timeframe'
                      title='Simultaneous with transfer of an Asset'
                      value='with-asset'
                      checked={payout.payoutTimeframe === 'with-asset'}
                      onChange={(value) => setPayout({ ...payout, payoutTimeframe: value })}
                      disabled={consentOrder.purchased}
                    />
                  </div>
                  {validationErrors.some((error) => error.name.startsWith('payoutTimeframe')) && (
                    <p className='mt-2 text-sm text-red-600'>must be selected</p>
                  )}
                </fieldset>
              )}

              {payout.payoutTimeframe === 'with-asset' && (
                <SelectInput
                  title='Which Asset will this Payout attach to?'
                  name='asset'
                  displayProperty='name'
                  idProperty='id'
                  options={assets.sort((a, b) => a.name.localeCompare(b.name))}
                  value={payout.assetId?.toString()}
                  onChange={(value) => setPayout({ ...payout, assetId: value })}
                  validationErrors={validationErrors}
                  disabled={consentOrder.purchased}
                />
              )}

              <TransferPeriod
                display={payout.payoutTimeframe === 'specified' || assets.find((a) => a.id === payout.assetId)?.toBeTransfered === false}
                name='payout-timeframe'
                title={hasMajorAssets || assets.length === 0 ? 'What timeframe do you need for the Payout?' : ''}
                transferPeriodQuantity={payout.transferPeriodQuantity}
                setTransferPeriodQuantity={(value) => setPayout(
                  { ...payout, transferPeriodQuantity: value }
                )}
                transferPeriod={payout.transferPeriod}
                setTransferPeriod={(value) => setPayout({ ...payout, transferPeriod: value })}
                validationErrors={validationErrors}
                disabled={consentOrder.purchased}
              />
            </>
          )}

          <div>
            <hr className='pt-5' />
            <div className='flex justify-between'>
              <Button
                to={`/consent-orders/${consentOrder.slug}/liabilities`}
              >
                Previous
              </Button>
              <Button
                onClick={save}
                loading={saving}
              >
                Next
              </Button>
            </div>
          </div>

        </form>
      </div>
    </>
  )
}

export default PayoutRoute
