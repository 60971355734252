import { gql, MutationTuple, useMutation } from '@apollo/client'

const CONFIRM_PAYMENT = gql`
  mutation confirmPayment($input: ConfirmPaymentInput!) {
    confirmPayment (input: $input) {
      confirmed
    }
  }
`

interface ConfirmPaymentData {
  confirmPayment: {
    confirmed: Boolean
  }
}

interface ConfirmPaymentVariables {
  input: {
    slug: string
  }
}

const useConfirmPayment = (): MutationTuple<ConfirmPaymentData, ConfirmPaymentVariables> => {
  return useMutation(CONFIRM_PAYMENT)
}

export default useConfirmPayment
