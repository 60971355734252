import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import logo from '../images/logo.png'
import { XCircleIcon } from '@heroicons/react/solid'

import { Button, TextInput } from '../components'
import { useCurrentUserLazyQuery } from '../graphql/queries'
import { useResetPasswordMutation } from '../graphql/mutations'

const ResetPasswordRoute = (): JSX.Element => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [getCurrentUser] = useCurrentUserLazyQuery()
  const [resetPassword, { loading }] = useResetPasswordMutation()

  useEffect(() => {
    checkIfLoggedIn().catch((e) => console.error(e))
  }, [])

  const checkIfLoggedIn = async (): Promise<void> => {
    const result = await getCurrentUser()

    if (result.data == null) return

    if (result.data.currentUser != null) {
      navigate('/login')
    }
  }

  const login = async (): Promise<void> => {
    setSuccess(false)

    const result = await resetPassword({
      variables: {
        input: {
          email
        }
      }
    })

    if (result.data == null) return

    if (result.data.resetPassword.success === true) {
      setSuccess(true)
      setEmail('')
    }
  }

  return (
    <div className='h-screen flex items-center'>
      <div className='flex flex-col items-center max-w-2xl mx-auto'>
        <img
          className='w-3/4'
          src={logo}
          alt='Legal Aspirations'
        />
        <div className='w-full'>
          <div className='py-12 px-4 sm:px-6 lg:py-16 lg:px-8 bg-white rounded space-y-8'>
            <h2 className='text-2xl font-extrabold tracking-tight text-gray-900 mb-3 text-center'>
              Forgot Password
            </h2>
            <p>
              Enter your email and we will send you a password reset link to reset your password.
            </p>
            <TextInput
              title='Email Address'
              name='email'
              value={email}
              onChange={setEmail}
            />
            {success && (
              <div className='rounded-md bg-green-50 p-4'>
                <div className='flex'>
                  <div className='flex-shrink-0'>
                    <XCircleIcon className='h-5 w-5 text-green-400' aria-hidden='true' />
                  </div>
                  <div className='ml-3'>
                    <h3 className='text-sm font-medium text-green-800'>
                      A reset password email has been sent. Please check your email and follow
                      the instructions.
                    </h3>
                  </div>
                </div>
              </div>
            )}
            <Button
              className='text-lg w-full'
              onClick={login}
              loading={loading}
            >
              Forgot Password
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordRoute
