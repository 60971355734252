import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import {
  useElements, useStripe, Elements, CardNumberElement, CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js'
import { XCircleIcon, LockClosedIcon } from '@heroicons/react/solid'

import { TextInput, Button } from '../components'
import { useConsentOrder } from './consent-order-route'
import ConsentOrder, { createPaymentIntent, getConsentOrder } from '../models/consent-order'
import { useConfirmPayment } from '../graphql/mutations'
import ValidationError from '../models/validation-error'

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
const stripePromise = loadStripe(stripePublicKey != null ? stripePublicKey : '')

const PurchaseRoute = (): JSX.Element => {
  const { consentOrder, setConsentOrder } = useConsentOrder()
  const navigate = useNavigate()
  const [confirmPayment] = useConfirmPayment()

  useEffect(() => {
    if (!consentOrder.agreedToDisclaimer) {
      navigate(`/consent-orders/${consentOrder.slug}/disclaimer`)
    }

    if (consentOrder.purchased) {
      navigate(`/consent-orders/${consentOrder.slug}/view`)
    }
  }, [consentOrder])

  const purchaseSuccess = async (): Promise<void> => {
    const refreshedConsentOrder = await getConsentOrder(consentOrder.slug)

    if (refreshedConsentOrder?.purchased === true) {
      setConsentOrder(refreshedConsentOrder)
      await confirmPayment(
        {
          variables: { input: { slug: consentOrder.slug } }
        }
      )

      navigate(`/consent-orders/${consentOrder.slug}/view`)
    }
  }

  return (
    <Elements stripe={stripePromise}>
      <PurchaseForm consentOrder={consentOrder} purchaseSuccess={purchaseSuccess} />
    </Elements>
  )
}

interface PurchaseFormProps {
  consentOrder: ConsentOrder
  purchaseSuccess: () => void
}

const PurchaseForm = ({ consentOrder, purchaseSuccess }: PurchaseFormProps): JSX.Element => {
  const elements = useElements()
  const stripe = useStripe()
  const [name, setName] = useState('')
  const [email, setEmail] = useState(consentOrder.email)
  const [processingPayment, setProcessingPayment] = useState(false)
  const [paymentError, setPaymentError] = useState<string | null | undefined>(null)
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])

  const processPayment = async (clientSecret: string): Promise<void> => {
    if (stripe == null || elements == null) return

    const element = elements.getElement(CardNumberElement)
    if (element == null) return

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: element
      }
    })

    if (result.error != null) {
      setPaymentError(result.error.message)
      setProcessingPayment(false)
    } else {
      setPaymentError(null)
      purchaseSuccess()
    }
  }

  const purchase = async (): Promise<void> => {
    if (stripe == null || elements == null) return

    setValidationErrors([])

    const errors = []

    if (name === '') {
      errors.push({
        name: 'name',
        messages: ['Your name must be provided']
      })
    }

    const validEmailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if (!validEmailRegEx.test(email)) {
      errors.push({
        name: 'emailAddress',
        messages: ['Your valid email must be provided']
      })
    }

    setValidationErrors(errors)

    if (errors.length !== 0) {
      return
    }

    setProcessingPayment(true)

    const clientSecret = await createPaymentIntent(consentOrder, name, email)

    await processPayment(clientSecret)
  }

  return (
    <div className='rounded-lg bg-white p-4'>
      <form className='space-y-8'>
        <div>
          <div>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              Purchase Consent Order
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Complete your payment details to download your personalised PDF Minute of
              Consent Orders.
            </p>
          </div>
        </div>
        <hr />

        <div className='lg:grid lg:grid-cols-2 lg:grid-x-12 xl:gap-x-16'>
          <div className='space-y-8 mb-6'>
            <div>
              <h3 className='text-md leading-6 font-medium text-gray-900 mb-2'>
                Contact Information
              </h3>
              <hr />
            </div>

            <div
              className='mt-6 space-y-6'
            >
              <TextInput
                title='Your name'
                name='name'
                value={name}
                onChange={setName}
                validationErrors={validationErrors}
              />

              <TextInput
                title='Email address'
                name='emailAddress'
                value={email}
                onChange={setEmail}
                validationErrors={validationErrors}
              />
            </div>

            <div>
              <h3 className='text-md leading-6 font-medium text-gray-900 mb-2'>
                Payment Details
              </h3>
              <hr />
            </div>

            <div
              className='mt-6 space-y-6'
            >
              <div>
                <label className='block text-sm text-gray-700'>Card Number</label>
                <CardNumberElement
                  className='appearance-none block w-full px-3 py-2 border rounded-md shadow-sm
                             placeholder-gray-400 focus:outline-none sm:text-sm border-gray-300
                             focus:ring-brand-secondary focus:border-brand-secondary'
                  options={{ disabled: processingPayment }}
                />
              </div>

              <div className='grid grid-cols-4 gap-x-4'>
                <div className='col-span-3'>
                  <label className='block text-sm text-gray-700'>Exipry Date (MM/YY)</label>
                  <CardExpiryElement
                    className='appearance-none block w-full px-3 py-2 border rounded-md shadow-sm
                              placeholder-gray-400 focus:outline-none sm:text-sm border-gray-300
                              focus:ring-brand-secondary focus:border-brand-secondary'
                    options={{ disabled: processingPayment }}
                  />
                </div>

                <div>
                  <label className='block text-sm text-gray-700'>CVC</label>
                  <CardCvcElement
                    className='appearance-none block w-full px-3 py-2 border rounded-md shadow-sm
                              placeholder-gray-400 focus:outline-none sm:text-sm border-gray-300
                              focus:ring-brand-secondary focus:border-brand-secondary'
                    options={{ disabled: processingPayment }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='space-y-8'>
            <div>
              <h3 className='text-md leading-6 font-medium text-gray-900 mb-2'>
                Order Summary
              </h3>
              <hr />
            </div>

            <div className='mt-4 bg-white border-gray-200 rounded-lg shadow-sm'>
              <div className='mx-6 my-6'>
                <h4 className='text-sm'>
                  Minute of Consent Orders.
                </h4>
                <p className='mt-1 text-sm text-gray-500'>
                  A personalised Minute of Consent Orders for family law property settlement.
                </p>
              </div>
              {paymentError != null && (
                <div className='rounded-md bg-red-50 p-4'>
                  <div className='flex'>
                    <div className='flex-shrink-0'>
                      <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
                    </div>
                    <div className='ml-3'>
                      <h3 className='text-sm font-medium text-red-800'>
                        There was an error processing your payment
                      </h3>
                      <div className='mt-2 text-sm text-red-700'>
                        <ul role='list' className='list-disc pl-5 space-y-1'>
                          <li>{paymentError}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className='border-t border-gray-200 py-6 px-4 sm:px-6'>
                <p className='flex justify-center text-sm text-gray-500 mb-2'>
                  <LockClosedIcon className='w-5 h-5 text-gray-400' />
                  Guaranteed safe and secure checkout
                </p>
                <Button
                  className='w-full font-medium text-base py-3 px-4'
                  onClick={purchase}
                  loading={processingPayment}
                >
                  {`Confirm & Pay AUD $${consentOrder.price.toString()}`}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <hr className='pt-5' />
          <div className='flex justify-between'>
            <Button
              to={`/consent-orders/${consentOrder.slug}/superannuation`}
            >
              Previous
            </Button>
          </div>
        </div>
      </form >
    </div >
  )
}

export default PurchaseRoute
