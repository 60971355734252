import React, { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'

import Button from './button'

interface ConfirmationModelProps {
  title: string
  message: string
  open: boolean
  processing: boolean
  actionButtonTitle: string
  cancelButtonTitle: string
  onClose: () => void
  confirmAction: () => void
}

const ConfirmationModal = ({
  title, message, open, processing, onClose, confirmAction, actionButtonTitle, cancelButtonTitle
}: ConfirmationModelProps): JSX.Element => {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='fixed z-10 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <div
          className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center \
                     sm:block sm:p-0'
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay
              className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
            />
          </Transition.Child>

          <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left \
                         overflow-hidden shadow-xl transform transition-all sm:my-8 \
                         sm:align-middle sm:max-w-lg sm:w-full sm:p-6'
            >
              <div className='sm:flex sm:items-start'>
                <div
                  className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12
                             rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'
                >
                  <ExclamationIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
                </div>
                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                  <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                    {title}
                  </Dialog.Title>
                  <div className='mt-2'>
                    <p className='text-sm text-gray-500'>
                      {message}
                    </p>
                  </div>
                </div>
              </div>
              <div className='mt-5 sm:mt-4 flex flex-row justify-end'>
                <Button
                  type='white'
                  onClick={onClose}
                >
                  {cancelButtonTitle}
                </Button>
                <Button
                  className='ml-2'
                  type='danger'
                  loading={processing}
                  onClick={confirmAction}
                >
                  {actionButtonTitle}
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
ConfirmationModal.defaultProps = {
  processing: false,
  cancelButtonTitle: 'Cancel'
}

export default ConfirmationModal
