import React, { useState } from 'react'

import {
  Input, SelectInput, RadioButton, PercentageSplit, ValidationMessages
} from '../components'
import { ConsentOrder, MortgageLiability, ValidationError } from '../models'

interface MortgageProps {
  consentOrder: ConsentOrder
  liability: MortgageLiability
  setMortgageLiability: (liability: MortgageLiability) => void
  validationErrors: ValidationError[]
}

const Mortgage = ({
  consentOrder, liability, setMortgageLiability, validationErrors
}: MortgageProps): JSX.Element => {
  const [realEstateAssets] = useState(
    consentOrder.assets.filter((a) => a.assetType === 'real-estate')
  )
  const selectedAsset = realEstateAssets.find((a) => a.id === liability.assetId)

  return (
    <>
      <Input
        title='Name of Bank, Financial Institution or Lender'
        name='bank-name'
        type='text'
        value={liability.nameOfBankOrFinancialInstitution}
        onChange={(value) => setMortgageLiability(
          { ...liability, nameOfBankOrFinancialInstitution: value }
        )}
        validationErrorName='nameOfBankOrFinancialInstitution'
        validationErrors={validationErrors}
      />

      <Input
        title='What are the last four digits of this account?'
        name='account-last-four-digits'
        type='text'
        value={liability.lastFourDigitsOfAccount}
        onChange={(value) => setMortgageLiability({ ...liability, lastFourDigitsOfAccount: value })}
        validationErrorName='lastFourDigitsOfAccount'
        validationErrors={validationErrors}
      />

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>In whose name is this held?</p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='mortgage-held-me'
            name='mortgage-held'
            title='Me'
            value='me'
            checked={liability.nameAccountHeldBy === 'me'}
            onChange={(value) => setMortgageLiability({ ...liability, nameAccountHeldBy: value })}
          />
          <RadioButton
            id='mortgage-held-ex'
            name='mortgage-held'
            title='My Ex'
            value='ex'
            checked={liability.nameAccountHeldBy === 'ex'}
            onChange={(value) => setMortgageLiability({ ...liability, nameAccountHeldBy: value })}
          />
          <RadioButton
            id='mortgage-held-both'
            name='mortgage-held'
            title='Both Me and My Ex'
            value='both'
            checked={liability.nameAccountHeldBy === 'both'}
            onChange={(value) => setMortgageLiability({ ...liability, nameAccountHeldBy: value })}
          />
        </div>
        <ValidationMessages
          name='nameAccountHeldBy'
          validationErrors={validationErrors}
        />
      </fieldset>

      <SelectInput
        title='What asset does this liability attach to?'
        name='real-estate-asset'
        displayProperty='name'
        idProperty='id'
        options={realEstateAssets}
        value={liability.assetId?.toString()}
        onChange={(value) => setMortgageLiability({ ...liability, assetId: value })}
        validationErrorName='asset'
        validationErrors={validationErrors}
      />

      {
        // eslint-disable-next-line  @typescript-eslint/strict-boolean-expressions
        selectedAsset?.changingOwnership && (
          <>
            <fieldset className='mt-6'>
              <div>
                <p className='text-sm text-gray-900'>
                  Who will be responsible for servicing the mortgage repayments pending settlement?
                </p>
              </div>
              <div className='mt-4 flex flex-wrap space-x-4'>
                <RadioButton
                  id='mortgage-repayments-responsibility-me'
                  name='mortgage-repayments-responsibility'
                  title='Me'
                  value='me'
                  checked={liability.paymentsPendingSettlementResponsibility === 'me'}
                  onChange={(value) => setMortgageLiability(
                    { ...liability, paymentsPendingSettlementResponsibility: value }
                  )}
                />
                <RadioButton
                  id='mortgage-repayments-responsibility-ex'
                  name='mortgage-repayments-responsibility'
                  title='My Ex'
                  value='ex'
                  checked={liability.paymentsPendingSettlementResponsibility === 'ex'}
                  onChange={(value) => setMortgageLiability(
                    { ...liability, paymentsPendingSettlementResponsibility: value }
                  )}
                />
                <RadioButton
                  id='mortgage-repayments-responsibility-both'
                  name='mortgage-repayments-responsibility'
                  title='Both Me and My Ex'
                  value='both'
                  checked={liability.paymentsPendingSettlementResponsibility === 'both'}
                  onChange={(value) => setMortgageLiability(
                    { ...liability, paymentsPendingSettlementResponsibility: value }
                  )}
                />
              </div>
              <ValidationMessages
                name='paymentsPendingSettlementResponsibility'
                validationErrors={validationErrors}
              />
            </fieldset>

            <PercentageSplit
              display={liability.paymentsPendingSettlementResponsibility === 'both'}
              name='mortgage-percentage-split'
              value={liability.paymentsPendingSettlementSplit}
              setValue={(value) => setMortgageLiability(
                { ...liability, paymentsPendingSettlementSplit: value }
              )}
              validationName='paymentsPendingSettlementSplit'
              validationErrors={validationErrors}
            />
          </>
        )
      }
    </>
  )
}

export default Mortgage
