import { gql, QueryResult, useQuery } from '@apollo/client'

const ADMIN_QUERY = gql`
  query Admin {
    admin
  }
`

interface AdminData {
  admin: boolean
}

const useAdminQuery = (): QueryResult<AdminData> => {
  return useQuery(ADMIN_QUERY)
}

export default useAdminQuery
