import React from 'react'

interface RadioButtonProps {
  id: string
  name: string
  title: string
  value: string
  checked: boolean
  containerClassName: string
  onChange: (value: string) => void
  disabled: boolean
}

const RadioButton = (
  { id, name, title, value, checked, onChange, containerClassName, disabled }: RadioButtonProps
): JSX.Element => {
  return (
    <div className={containerClassName}>
      <input
        id={id}
        name={name}
        type='radio'
        className='focus:ring-brand-secondary h-4 w-4 text-brand border-gray-300'
        value={value}
        checked={checked}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      />
      <label htmlFor={id} className='ml-3 text-sm font-medium text-gray-700'>
        {title}
      </label>
    </div>
  )
}
RadioButton.defaultProps = {
  containerClassName: '',
  disabled: false
}

export default RadioButton
