import { gql } from '@apollo/client'

import { consentOrderFieldsFragment } from './fragments'

const updatePersonalPossessionAssetQuery = gql`
  mutation UpdatePersonalPossessionAssetQuery($input: UpdatePersonalPossessionAssetInput!) {
    updatePersonalPossessionAsset(input: $input) {
      consentOrder {
        ...consentOrderFields
      }
      validationErrors {
        name
        messages
      }
    }
  }

  ${consentOrderFieldsFragment}
`

export default updatePersonalPossessionAssetQuery
