import React from 'react'

import {
  Input, RadioButton, PercentageSplit, TransferPeriod, ValidationMessages
} from '../components'
import { TrustAsset, ValidationError } from '../models'

interface TrustProps {
  asset: TrustAsset
  setTrustAsset: (asset: TrustAsset) => void
  validationErrors: ValidationError[]
}

const Trust = ({ asset, setTrustAsset, validationErrors }: TrustProps): JSX.Element => {
  const trustResettlement = ((asset.whoAreTheBeneficiaries === 'me' && asset.whoWillRetainTheTrust === 'ex') ||
    (asset.whoAreTheBeneficiaries === 'ex' && asset.whoWillRetainTheTrust === 'me'))

  return (
    <>
      <div className='sm:col-span-4'>
        <Input
          title='Name of Trust'
          name='trust-name'
          type='text'
          value={asset.nameOfTrust}
          onChange={(value) => setTrustAsset({ ...asset, nameOfTrust: value })}
          validationErrorName='nameOfTrust'
          validationErrors={validationErrors}
        />
      </div>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>
            Who are the beneficiaries of the Trust?
          </p>
        </div>
        <div className='mt-4 flex flex-wrap space-x-4'>
          <RadioButton
            id='trust-beneficiaries-me'
            name='trust-beneficiaries'
            title='Me'
            value='me'
            checked={asset.whoAreTheBeneficiaries === 'me'}
            onChange={(value) => setTrustAsset({ ...asset, whoAreTheBeneficiaries: value })}
          />
          <RadioButton
            id='trust-beneficiaries-ex'
            name='trust-beneficiaries'
            title='My Ex'
            value='ex'
            checked={asset.whoAreTheBeneficiaries === 'ex'}
            onChange={(value) => setTrustAsset({ ...asset, whoAreTheBeneficiaries: value })}
          />
          <RadioButton
            id='trust-beneficiaries-both'
            name='trust-beneficiaries'
            title='Both Me and My Ex'
            value='both'
            checked={asset.whoAreTheBeneficiaries === 'both'}
            onChange={(value) => setTrustAsset({ ...asset, whoAreTheBeneficiaries: value })}
          />
        </div>
        <ValidationMessages
          name='whoAreTheBeneficiaries'
          validationErrors={validationErrors}
        />
      </fieldset>

      <fieldset className='mt-6'>
        <div>
          <p className='text-sm text-gray-900'>
            Who will retain the Trust?
          </p>
        </div>
        <div className='mt-2 md:mt-4 md:flex md:flex-wrap md:space-x-4'>
          <RadioButton
            id='trust-retain-me'
            name='trust-retain'
            title='Me'
            value='me'
            checked={asset.whoWillRetainTheTrust === 'me'}
            onChange={(value) => setTrustAsset({ ...asset, whoWillRetainTheTrust: value })}
          />
          <RadioButton
            id='trust-retain-ex'
            name='trust-retain'
            title='My Ex'
            value='ex'
            checked={asset.whoWillRetainTheTrust === 'ex'}
            onChange={(value) => setTrustAsset({ ...asset, whoWillRetainTheTrust: value })}
          />
          <RadioButton
            id='trust-retain-both'
            name='trust-retain'
            title='Would Up (i.e. Close and Shut Down the Trust)'
            value='wound-up'
            checked={asset.whoWillRetainTheTrust === 'wound-up'}
            onChange={(value) => setTrustAsset({ ...asset, whoWillRetainTheTrust: value })}
          />
        </div>
        <ValidationMessages
          name='whoWillRetainTheTrust'
          validationErrors={validationErrors}
        />
      </fieldset>

      {trustResettlement && (
        <p className='font-medium text-sm'>
          This system does not support a trust resettlement.
        </p>
      )}

      <TransferPeriod
        display={
          asset.whoAreTheBeneficiaries !== asset.whoWillRetainTheTrust &&
          asset.whoWillRetainTheTrust !== 'wound-up' && asset.whoWillRetainTheTrust !== '' &&
          !trustResettlement
        }
        name='trust-transfer'
        title='What timeframe do you need to change the Trust ownership?'
        transferPeriodQuantity={asset.transferPeriodQuantity}
        setTransferPeriodQuantity={(value) => setTrustAsset(
          { ...asset, transferPeriodQuantity: value }
        )}
        transferPeriod={asset.transferPeriod}
        setTransferPeriod={(value) => setTrustAsset({ ...asset, transferPeriod: value })}
        validationErrors={validationErrors}
      />

      <PercentageSplit
        display={asset.whoWillRetainTheTrust === 'wound-up'}
        name='how-trust-assets-divided'
        title='In what proportion are the assets of the Trust (if any) being divided?'
        value={asset.woundUpAssetsSplit}
        setValue={(value) => setTrustAsset({ ...asset, woundUpAssetsSplit: value })}
        validationName='woundUpAssetsSplit'
        validationErrors={validationErrors}
      />
    </>
  )
}

export default Trust
