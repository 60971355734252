import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, TextInput, CreditCard, Mortgage, Loan, Other } from '../components'
import {
  updateCreditCardLiability, updateMortgageLiability, updateLoanLiability, updateOtherLiability
} from '../models/consent-order'
import { useConsentOrder } from './consent-order-route'
import { ValidationError } from '../models'

const EditLiabilityRoute = (): JSX.Element => {
  const { liabilityId } = useParams()
  const navigate = useNavigate()
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const [saving, setSaving] = useState(false)
  const { consentOrder, setConsentOrder } = useConsentOrder()
  const [liability] = useState(consentOrder.liabilities.find((a) => a.id == liabilityId))
  const [name, setName] = useState(liability != null ? liability.name : '')

  const [loanLiability, setLoanLiability] = useState(liability?.loanLiability)
  const [creditCardLiability, setCreditCardLiability] = useState(liability?.creditCardLiability)
  const [mortgageLiability, setMortgageLiability] = useState(liability?.mortgageLiability)
  const [otherLiability, setOtherLiability] = useState(liability?.otherLiability)

  const save = async (): Promise<void> => {
    if (liability == null) return

    setSaving(true)
    setValidationErrors([])

    setSaving(false)

    let result = null

    if (creditCardLiability != null) {
      result = await updateCreditCardLiability(
        consentOrder,
        liability,
        name,
        creditCardLiability
      )
    }

    if (mortgageLiability != null) {
      result = await updateMortgageLiability(
        consentOrder,
        liability,
        name,
        mortgageLiability
      )
    }

    if (loanLiability != null) {
      result = await updateLoanLiability(
        consentOrder,
        liability,
        name,
        loanLiability
      )
    }

    if (otherLiability != null) {
      result = await updateOtherLiability(
        consentOrder,
        liability,
        name,
        otherLiability
      )
    }

    if (result == null) {
      return
    }

    if (result.validationErrors.length === 0) {
      setConsentOrder(result.consentOrder)
      navigate(`/consent-orders/${consentOrder.slug}/liabilities`)
    } else {
      setValidationErrors(result.validationErrors)
    }
  }

  return (
    <div
      className='fixed z-10 inset-0 overflow-y-auto'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div
        className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block \
                   sm:p-0'
      >
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
        />
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'
        />

        <div
          className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden \
                     shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl \
                     sm:w-full'
        >
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className='space-y-4'>
              <div className='mt-3 text-center sm:mt-0 sm:text-left'>
                <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-title'>
                  Edit Liability
                </h3>
                <hr className='mt-3' />
              </div>

              <form className='space-y-6'>
                <TextInput
                  title='Nickname'
                  name='name'
                  value={name}
                  onChange={setName}
                  validationErrorName='liability.name'
                  validationErrors={validationErrors}
                />

                {creditCardLiability != null && (
                  <CreditCard
                    liability={creditCardLiability}
                    setCreditCardLiability={setCreditCardLiability}
                    validationErrors={validationErrors}
                  />
                )}

                {mortgageLiability != null && (
                  <Mortgage
                    consentOrder={consentOrder}
                    liability={mortgageLiability}
                    setMortgageLiability={setMortgageLiability}
                    validationErrors={validationErrors}
                  />
                )}

                {loanLiability != null && (
                  <Loan
                    consentOrder={consentOrder}
                    liability={loanLiability}
                    setLoanLiability={setLoanLiability}
                    validationErrors={validationErrors}
                  />
                )}

                {otherLiability != null && (
                  <Other
                    consentOrder={consentOrder}
                    liability={otherLiability}
                    setOtherLiability={setOtherLiability}
                    validationErrors={validationErrors}
                  />
                )}

              </form>
            </div>
          </div>

          <div className='bg-gray-50 px-4 py-3 sm:px-6 flex justify-end space-x-2'>
            <Button
              type='white'
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              onClick={save}
              loading={saving}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditLiabilityRoute
