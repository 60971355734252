import { useState, useEffect, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useParams, useNavigate, Outlet, useOutletContext } from 'react-router-dom'
import { XIcon, MenuIcon } from '@heroicons/react/outline'
import { XCircleIcon, InformationCircleIcon } from '@heroicons/react/solid'

import logo from '../images/logo.png'
import { NavItems, Button, TextInput, PasswordInput } from '../components'
import ConsentOrder, { getConsentOrder } from '../models/consent-order'
import { useRegisterUserMutation, useLoginMutation, useSaveProgressMutation } from '../graphql/mutations'
import { useCurrentUserLazyQuery } from '../graphql/queries'
import { ValidationError } from '../models'

const ConsentOrderRoute = (): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { slug } = useParams()
  const navigate = useNavigate()
  const [consentOrder, setConsentOrder] = useState<ConsentOrder | null>(null)
  const [loginPrompt, setLoginPrompt] = useState(false)
  const [loginMode, setLoginMode] = useState<'register' | 'login' | 'reset-password'>('register')
  const [viewHelp, setViewHelp] = useState(false)
  const [getCurrentUser, { loading: loadingCurrentUser }] = useCurrentUserLazyQuery()
  const [saveProgressMutation, { loading: savingProgress }] = useSaveProgressMutation()

  useEffect(() => {
    if (slug === undefined) {
      navigate('/')
      return
    }

    (async () => {
      const consentOrder = await getConsentOrder(slug)

      if (consentOrder != null) {
        setConsentOrder(consentOrder)
        setViewHelp(!consentOrder.completedPersonalInformation)
      } else {
        navigate(`/login`)
      }
    })()
      .catch((e) => console.error('Error loading Consent Order Route', e))
  }, [slug, navigate])

  const onSaveProgress = async (): Promise<void> => {
    const result = await getCurrentUser()

    if (result.data == null) return

    if (result.data.currentUser == null) {
      setLoginPrompt(true)
    } else {
      await saveProgress()
    }
  }

  const saveProgress = async (): Promise<void> => {
    if (consentOrder == null) return

    await saveProgressMutation({
      variables: {
        input: {
          slug: consentOrder.slug
        }
      }
    })
  }

  const switchToLogin = (): void => {
    setLoginMode('login')
  }

  const switchToRegister = (): void => {
    setLoginMode('register')
  }

  const switchToForgotPassword = (): void => {
    setLoginMode('reset-password')
  }

  if (consentOrder === null) {
    return <></>
  }

  return (
    <>
      <div>
        <div
          className={
            `fixed inset-0 flex z-40 md:hidden ${menuOpen ? '' : 'hidden'}`
          }
          role='dialog'
          aria-modal='true'
        >
          <div
            className='fixed inset-0 bg-gray-600 bg-opacity-75'
            aria-hidden='true'
          />

          <div
            className='relative flex-1 flex flex-col max-w-xs w-full bg-brand'
          >
            <div className='absolute top-0 right-0 -mr-12 pt-2'>
              <button
                type='button'
                className='ml-1 flex items-center justify-center h-10 w-10
                          rounded-full focus:outline-none focus:ring-2
                          focus:ring-inset focus:ring-white'
                onClick={() => setMenuOpen(false)}
              >
                <span className='sr-only'>Close sidebar</span>
                <XIcon className='h-6 w-6 text-white' />
              </button>
            </div>

            <div className='flex-1 h-0 pb-4 overflow-y-auto'>
              <div className='flex-shrink-0 flex items-center px-4 bg-white'>
                <img
                  className='w-auto'
                  src={logo}
                  alt='Legal Aspirations'
                />
              </div>

              <NavItems consentOrder={consentOrder} />
            </div>

            <div className='flex-shrink-0 flex bg-gray-700 p-4 hidden'>
              <a href='/' className='flex-shrink-0 group block'>
                <div className='flex items-center'>
                  <div>
                    <img
                      className='inline-block h-10 w-10 rounded-full'
                      src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=\
                          rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&\
                          facepad=2&w=256&h=256&q=80'
                      alt=''
                    />
                  </div>
                  <div className='ml-3'>
                    <p className='text-base font-medium text-white'>
                      Tim Cook
                    </p>
                    <p className='text-sm font-medium text-gray-400
                                group-hover:text-gray-300'
                    >
                      View Profile
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className='flex-shrink-0 w-14' />

        </div>

        <div className='hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0'>
          <div className='flex-1 flex flex-col min-h-0 bg-brand'>
            <div className='flex-1 flex flex-col pb-4 overflow-y-auto'>
              <div className='flex items-center flex-shrink-0 px-4 bg-white'>
                <img className='w-auto' src={logo} alt='Legal Aspirations' />
              </div>
              <NavItems consentOrder={consentOrder} />
            </div>
          </div>

          <div className='flex-shrink-0 flex bg-gray-700 p-4 hidden'>
            <a href='/' className='flex-shrink-0 w-full group block'>
              <div className='flex items-center'>
                <div>
                  <img
                    className='inline-block h-9 w-9 rounded-full'
                    src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=\
                        rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&\
                        facepad=2&w=256&h=256&q=80'
                    alt=''
                  />
                </div>
                <div className='ml-3'>
                  <p className='text-sm font-medium text-white'>
                    Tim Cook
                  </p>
                  <p className='text-xs font-medium text-gray-300
                              group-hover:text-gray-200'
                  >
                    View Profile
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div className='md:pl-64 flex flex-col flex-1'>
          <div className='sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3
                        bg-gray-100'
          >
            <button
              type='button'
              className='-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center
                        justify-center rounded-md text-gray-500
                        hover:text-gray-900 focus:outline-none focus:ring-2
                        focus:ring-inset focus:ring-indigo-500'
              onClick={() => setMenuOpen(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <MenuIcon className='h-6 w-6' />
            </button>
          </div>
          <main className='flex-1'>
            <div className='py-6'>
              <div className='flex justify-between px-6'>
                <Button onClick={() => setViewHelp(true)}>
                  Help
                </Button>
                <Button
                  onClick={onSaveProgress}
                  loading={loadingCurrentUser || savingProgress}
                >
                  Save Progress
                </Button>
              </div>
              <div className='max-w-7xl mx-auto px-4 py-4 sm:px-6 md:px-8'>

                {consentOrder.purchased && (
                  <div className='border-l-4 border-blue-400 bg-blue-50 p-4 rounded-r-lg mb-4'>
                    <div className='flex'>
                      <div className='flex-shrink-0'>
                        <InformationCircleIcon className='h-5 w-5 text-blue-400' aria-hidden='true' />
                      </div>
                      <div className='ml-3 flex-1 md:flex md:justify-between'>
                        <p className='text-sm text-blue-700'>
                          Questionnaire cannot be edited once they have been purchased
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <Outlet context={{ consentOrder, setConsentOrder }} />
              </div>
            </div>
          </main>
        </div>
      </div>
      <Transition.Root show={loginPrompt} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={() => setLoginPrompt(false)}
        >
          <div
            className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center \
                       sm:block sm:p-0'
          >
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay
                className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
              />
            </Transition.Child>

            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div
                className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left
                           overflow-hidden shadow-xl transform transition-all sm:my-8
                           sm:align-middle sm:max-w-xl sm:w-full sm:p-6'
              >
                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                  {loginMode === 'register' && (
                    <RegisterUser
                      close={() => setLoginPrompt(false)}
                      login={switchToLogin}
                      forgotPassword={switchToRegister}
                      saveProgress={saveProgress}
                    />
                  )}
                  {loginMode === 'login' && (
                    <LoginUser
                      close={() => setLoginPrompt(false)}
                      register={switchToRegister}
                      forgotPassword={switchToForgotPassword}
                      saveProgress={saveProgress}
                    />
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={viewHelp} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={() => setViewHelp(false)}
        >
          <div
            className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center \
                       sm:block sm:p-0'
          >
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay
                className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
              />
            </Transition.Child>

            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div
                className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left
                           overflow-hidden shadow-xl transform transition-all sm:my-8
                           sm:align-middle sm:max-w-4xl sm:w-full sm:p-6'
              >
                <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-3'>
                  Instructions
                </h2>
                <p className='text-gray-900 mb-3'>
                  One of the main objectives of family law property settlement is to create a financial
                  severance between you and your ex. Once orders are made by the Family Court and given
                  effect, there should not be any asset or liability in joint names. A Minute that is unique
                  to your circumstances will be generated once you have completed the questionnaire. The
                  basic structure of the Minute is as follows:
                  <ol className='list-decimal list-inside ml-8 mt-3'>
                    <li>Sever all jointly held assets and liabilities.</li>
                    <li>Transfer any assets or liabilities that are switching ownership.</li>
                    <li>Regulate a payout or adjusting payment to one of the parties.</li>
                    <li>Split superannuation if applicable.</li>
                    <li>Cover all clauses.</li>
                  </ol>
                </p>
                <p className='mb-3'>
                  To complete the questionnaire, start by adding all jointly held assets. You will be asked
                  who is keeping the asset or if it is being sold. Once you have added all jointly held
                  assets, move on to adding assets where the ownership is being changed. For example, if you
                  are keeping a motor vehicle registered in your ex's name, add the motor vehicle as an
                  asset, select your ex when asked who is the owner and select yourself when asked who is
                  keeping the motor vehicle. Follow the same approach with liabilities. Make sure to include
                  all jointly held liabilities and any liabilities that are being transferred between you
                  and your ex.
                </p>
                <p className='mb-3'>
                  The questionnaire allows you to determine what assets and liabilities to include.
                  The level of detail is up to you.
                </p>
                <p className='mb-3'>
                  An important part of any agreement is often the payout or adjusting payment to one of the
                  parties. In our example of John and Mary, the payout from John to Mary is $86,500. You
                  will be asked how much the payout is and who is receiving it.
                </p>
                <p>
                  You will be asked about any superannuation splitting order. You should make sure to obtain
                  legal advice to ensure that you are eligible to split superannuation in your
                  circumstances.
                </p>
                <p>
                  A cover all clause is the term used to describe the position whereby everything else
                  already in your name remains yours and everything else already in your ex's
                  name remains your ex's. Assets and liabilities that are neither joint nor being
                  transferred or sold can generally fall into the cover all clauses. The Minute will
                  generate this for you.
                </p>
                <div className='mt-8 flex justify-center'>
                  <Button onClick={() => setViewHelp(false)}>
                    Close
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

interface RegisterUserProps {
  close: () => void
  login: () => void
  forgotPassword: () => void
  saveProgress: () => void
}

const RegisterUser = ({ close, login, forgotPassword, saveProgress }: RegisterUserProps): JSX.Element => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const [registerUser, { loading: saving }] = useRegisterUserMutation()

  const register = async (): Promise<void> => {
    setValidationErrors([])

    const result = await registerUser({
      variables: {
        input: {
          email,
          password,
          passwordConfirmation
        }
      }
    })

    if (result.data == null) return

    if (result.data.registerUser.validationErrors.length > 0) {
      setValidationErrors(result.data.registerUser.validationErrors)
    } else {
      saveProgress()
      close()
    }
  }

  return (
    <form className='space-y-6'>
      <h2 className='text-center text-3xl font-bold tracking-tight text-gray-900'>
        Register To Save Your Progress
      </h2>

      <hr />

      <TextInput
        title='Email Address'
        name='email'
        value={email}
        onChange={setEmail}
        validationErrors={validationErrors}
      />
      <PasswordInput
        title='Password'
        name='password'
        value={password}
        onChange={setPassword}
        validationErrors={validationErrors}
      />
      <PasswordInput
        title='Password Confirmation'
        name='passwordConfirmation'
        value={passwordConfirmation}
        onChange={setPasswordConfirmation}
        validationErrors={validationErrors}
      />

      <div className='flex justify-between'>
        <div className='text-sm'>
          <a
            className='font-medium text-brand hover:text-brand-secondary cursor-pointer'
            onClick={login}
          >
            Already have an account?
          </a>
        </div>

        <div className='text-sm'>
          <a
            className='font-medium text-brand hover:text-brand-secondary cursor-pointer hidden'
            onClick={forgotPassword}
          >
            Forgot your password?
          </a>
        </div>
      </div>

      <Button
        className='w-full'
        onClick={register}
        loading={saving}
      >
        Register
      </Button>
    </form>
  )
}

interface LoginProps {
  close: () => void
  register: () => void
  forgotPassword: () => void
  saveProgress: () => void
}

const LoginUser = ({ close, register, forgotPassword, saveProgress }: LoginProps): JSX.Element => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [invalidLoginDetails, setInvalidLoginDetails] = useState(false)
  const [loginUser, { loading: saving }] = useLoginMutation()

  const login = async (): Promise<void> => {
    setInvalidLoginDetails(false)

    const result = await loginUser({
      variables: {
        input: {
          email,
          password
        }
      }
    })

    if (result.data == null) return

    if (result.data.login.success === true) {
      saveProgress()
      close()
    } else {
      setInvalidLoginDetails(true)
    }
  }

  return (
    <form className='space-y-6'>
      <h2 className='text-center text-3xl font-bold tracking-tight text-gray-900'>
        Login To Save Your Progress
      </h2>

      <hr />

      <TextInput
        title='Email Address'
        name='email'
        value={email}
        onChange={setEmail}
      />
      <PasswordInput
        title='Password'
        name='password'
        value={password}
        onChange={setPassword}
      />

      <div className='flex justify-between'>
        <div className='text-sm'>
          <a
            className='font-medium text-brand hover:text-brand-secondary cursor-pointer'
            onClick={register}
          >
            Need an account?
          </a>
        </div>

        <div className='text-sm'>
          <a
            className='font-medium text-brand hover:text-brand-secondary cursor-pointer hidden'
            onClick={forgotPassword}
          >
            Forgot your password?
          </a>
        </div>
      </div>

      {invalidLoginDetails && (
        <div className='rounded-md bg-red-50 p-4'>
          <div className='flex'>
            <div className='flex-shrink-0'>
              <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
            </div>
            <div className='ml-3'>
              <h3 className='text-sm font-medium text-red-800'>
                Invalid email address or password, please try again.
              </h3>
            </div>
          </div>
        </div>
      )}

      <Button
        className='w-full'
        onClick={login}
        loading={saving}
      >
        Login
      </Button>
    </form>
  )
}

const useConsentOrder = (): {
  consentOrder: ConsentOrder
  setConsentOrder: (consentOrder: ConsentOrder) => void
} => {
  return useOutletContext<{
    consentOrder: ConsentOrder
    setConsentOrder: (consentOrder: ConsentOrder) => void
  }>()
}

export default ConsentOrderRoute
export { useConsentOrder }
