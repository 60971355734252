import { DateTime } from 'luxon'

interface FormatDateProps {
  datetime: DateTime | null
}

const FormatDate = ({ datetime }: FormatDateProps): JSX.Element => {
  if (datetime == null) return (<></>)

  return (
    <>
      {DateTime.fromISO(datetime.toString()).toFormat('dd LLL yyyy hh:mm a')}
    </>
  )
}

export default FormatDate
